import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKTable from 'components/MaterialKit/MKTable';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { getSignals, deleteSignal } from 'api/signals';
import { getHandlerSignals } from 'api/handler_signals';
import { getLocaleMap } from 'utils/locales';
import DataTable from 'components/DataTable';

const HandlerSignalListViewSection = (props) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_signal_id', 'labels_signal_name', 'labels_sequence', 'labels_signal_type', 'labels_signal', 'labels_ingoing', 'labels_outgoing', 'labels_signals'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const inputSignalTableColumns = useMemo(() => [
    {
      name: 'selected',
      label: ' ',
      align: 'left',
    },
    {
      name: 'signal_id',
      label: lm.labels_signal_id,
      align: 'left',
    },
    {
      name: 'signal_name',
      align: 'center',
      label: lm.labels_signal_name,
    },
  ], [lm.labels_signal_id, lm.labels_signal_name]);

  const outputSignalTableColumns = useMemo(() => [
    {
      field: 'signal_id',
      label: lm.labels_signal_id,
    },
    { field: 'signal_name', label: lm.labels_signal_name },
    { field: 'sequence', label: lm.labels_sequence },
  ], [lm.labels_sequence, lm.labels_signal_id, lm.labels_signal_name]);

  const { signalType, selectedSignals, handleCheckbox, setFieldValue, isDecisionHandler } = props;
  const [signals, setSignals] = useState([]);
  const [handlerSignals, setHandlerSignals] = useState([]);
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const businessLogicId = searchParams.get('business_logic_id');
  const handlerId = searchParams.get('handler_id');

  const fetchSignalsFromApi = useCallback(() => {
    const params = {
      business_logic: businessLogicId,
      $orderby: 'sequence',
    };
    return businessLogicId && getSignals(params)
      .then(({ data }) => {
        setSignals(data.filter((signal) => signal.handler === null || signal.handler !== handlerId) || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, handlerId, setAuth]);

  const fetchHandlerSignalsFromApi = useCallback(() => {
    const params = {
      signal_type: signalType,
      $expand: 'signal',
      handler: handlerId,
    };
    return handlerId && getHandlerSignals(params)
      .then(({ data }) => {
        setHandlerSignals(data.map((handlerSignal) => handlerSignal.signal));
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [handlerId, setAuth, signalType]);

  const onPressAdd = useCallback(() => {
    navigate(`/create_signal?business_logic_id=${businessLogicId}&handler_id=${handlerId}&is_decision_handler=${isDecisionHandler}`);
  }, [navigate, businessLogicId, handlerId, isDecisionHandler]);

  const onPressEdit = useCallback((signalId) => {
    navigate(`/signal?signal_id=${signalId}&edit=true&is_decision_handler=${isDecisionHandler}`);
  }, [isDecisionHandler, navigate]);

  const onPressDelete = useCallback((signalId) => {
    return deleteSignal(signalId)
      .then(() => {
        return fetchHandlerSignalsFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchHandlerSignalsFromApi, setAuth]);

  useEffect(() => {
    if (signalType === 'in') {
      fetchSignalsFromApi();
    } else {
      fetchHandlerSignalsFromApi();
    }
  }, [fetchSignalsFromApi, fetchHandlerSignalsFromApi, signalType]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">{`${signalType === 'in' ? lm.labels_ingoing : lm.labels_outgoing} ${lm.labels_signals}`}</MKTypography>
      </MKBox>
      {signalType === 'in' ? (
        <MKTable
          columns={inputSignalTableColumns}
          rows={signals?.map((signal) => {
            const { signal_id, signal_name } = signal;
            return {
              selected: (
                <Checkbox
                  checked={selectedSignals.includes(signal_id)}
                  value={signal_id}
                  onClick={(e) => handleCheckbox(e, setFieldValue)}
                  color="secondary"
                />
              ),
              signal_id,
              signal_name,
            };
          })}
        />
      )
        : (
          <DataTable
            data={handlerSignals}
            columns={outputSignalTableColumns}
            idField="signal_id"
            onPressAdd={onPressAdd}
            onPressEdit={onPressEdit}
            onPressDelete={onPressDelete}
            selectable
          />
        )}
    </MKBox>
  );
};

HandlerSignalListViewSection.propTypes = {
  signalType: PropTypes.string,
  selectedSignals: PropTypes.array,
  handleCheckbox: PropTypes.func,
  setFieldValue: PropTypes.func,
  isDecisionHandler: PropTypes.bool,
};

HandlerSignalListViewSection.defaultProps = {
  signalType: null,
  selectedSignals: [],
  handleCheckbox: null,
  setFieldValue: null,
  isDecisionHandler: false,
};

export default HandlerSignalListViewSection;
