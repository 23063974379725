import api from 'api';

export const getHandlerParameters = (params) => {
  return api.get('/handler_parameters', params, 'bearer');
};

export const getHandlerParameter = (id, params) => {
  return api.get(`/handler_parameters/${id}`, params, 'bearer');
};

export const updateHandlerParameter = (id, body) => {
  return api.put(`/handler_parameters/${id}`, body, 'bearer');
};

export const createHandlerParameter = (body) => {
  return api.post('/handler_parameters', body, 'bearer');
};

export const deleteHandlerParameter = (id) => {
  return api.delete(`/handler_parameters/${id}`, 'bearer');
};
