import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getBusinessLogics, deleteBusinessLogic } from 'api/business_logics';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import { getLocaleMap } from 'utils/locales';

const BusinessLogicListViewSection = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_business_logics', 'labels_business_id', 'labels_business_logic_name'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const businessLogicTableColumns = useMemo(() => [
    {
      field: 'business_logic_id',
      label: lm.labels_business_id,
    },
    { field: 'business_logic_name', label: lm.labels_business_logic_name },
  ], [lm.labels_business_id, lm.labels_business_logic_name]);

  const [businessLogics, setBusinessLogics] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchBusinessLogicsFromApi = useCallback(() => {
    return getBusinessLogics()
      .then(({ data }) => {
        setBusinessLogics(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const onPressAdd = useCallback(() => {
    navigate('/create_business_logic');
  }, [navigate]);

  const onPressEdit = useCallback((businessLogicId) => {
    navigate(`/business_logic?business_logic_id=${businessLogicId}&edit=true`);
  }, [navigate]);

  const onPressDelete = useCallback((businessLogicId) => {
    return deleteBusinessLogic(businessLogicId)
      .then(() => {
        return fetchBusinessLogicsFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchBusinessLogicsFromApi, setAuth]);

  useEffect(() => {
    fetchBusinessLogicsFromApi();
  }, [fetchBusinessLogicsFromApi]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">{lm.labels_business_logics}</MKTypography>
      </MKBox>
      <DataTable
        data={businessLogics}
        columns={businessLogicTableColumns}
        idField="business_logic_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        selectable
      />
    </MKBox>
  );
};

export default BusinessLogicListViewSection;
