import React from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';

const SimpleFooter = ({ copyright, ...props }) => {
  return (
    <MKBox
      component="footer"
      justifyContent="center"
      textAlign="center"
      py={4}
      {...props}
    >
      <MKTypography variant="body2" color="secondary">
        {copyright}
      </MKTypography>
    </MKBox>
  );
};

SimpleFooter.propTypes = {
  copyright: PropTypes.string,
};

SimpleFooter.defaultProps = {
  copyright: '',
};

export default SimpleFooter;
