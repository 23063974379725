import { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import EditAttributesForm from 'components/EditAttributesForm';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getDynamicTableRow, updateDynamicTableRow } from 'api/sections';
import { handleErrorResponse, isUuid, parseJSON } from 'utils/general';
import { useAuth } from 'contexts/auth';

const DynamicTableDetailViewSection = ({ collection_definition_id, dynamic_table_row_id, success_message, ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [dynamicTableRowData, setDynamicTableRowData] = useState(null);
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const fetchSectionDataFromApi = useCallback(() => {
    return ((collectionDefinition?.collection_definition_id && isUuid(dynamic_table_row_id)) ? (
      getDynamicTableRow(collectionDefinition.collection_definition_id, dynamic_table_row_id, {})
    ) : Promise.resolve({ data: null }))
      .then(({ data }) => {
        const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
        const jsonValue = parseJSON(jsonStrValue, {});
        setDynamicTableRowData(jsonValue);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition, dynamic_table_row_id, setAuth]);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {
        $expand: 'attributes',
      };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  const onClickSave = useCallback((values) => {
    const updateBody = {
      json_short_data: JSON.stringify(values),
    };
    if (!isUuid(collection_definition_id) || !dynamic_table_row_id) {
      return Promise.resolve();
    }
    return updateDynamicTableRow(collection_definition_id, dynamic_table_row_id, updateBody)
      .then(({ data }) => {
        const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
        const jsonValue = parseJSON(jsonStrValue, {});
        setDynamicTableRowData(jsonValue);
        alert(success_message);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collection_definition_id, dynamic_table_row_id, success_message, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  useEffect(() => {
    if (collectionDefinition) {
      fetchSectionDataFromApi();
    }
  }, [collectionDefinition, fetchSectionDataFromApi]);

  return (
    <MKBox p={2}>
      {!isUuid(collection_definition_id) && (
        <MKTypography color="error">Invalid Collection Definition</MKTypography>
      )}
      <EditAttributesForm
        attributes={collectionDefinition?.attributes}
        config={dynamicTableRowData}
        onCancel={onClickCancel}
        onSave={onClickSave}
      />
    </MKBox>
  );
};

DynamicTableDetailViewSection.propTypes = {
  collection_definition_id: PropTypes.string,
  dynamic_table_row_id: PropTypes.string,
  success_message: PropTypes.string,
};

export default DynamicTableDetailViewSection;
