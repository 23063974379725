import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import React, { useEffect, useState } from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { useWards } from 'contexts/wards';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { getLocaleMap } from 'utils/locales';
import { generateContrastingColor } from '../AVHome/helperFunctions';

const AVHistorySection = (props) => {
  const navigate = useNavigate();
  const { wards } = useWards();
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['history', 'patients'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const handleNavigate = (ward) => {
    navigate(`/history/reports/ward-reports?ward_id=${ward.ward_id}`);
  };

  if (!wards) {
    return null;
  }

  return (
    <MKBox {...props}>
      <AVBreadcrumb startingNode={localeMapping.history} />
      <MKBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={4}>
        <Grid container spacing={1} mt={2}>
          {wards?.map((ward) => {
            const bgColor = generateContrastingColor(ward.center_id.center_id);
            return (
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <MKBox sx={{ border: '1.5px solid', backgroundColor: 'gray', borderRadius: '8px', cursor: 'pointer' }} onClick={() => handleNavigate(ward)}>
                  <MKBox px={2} py={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ borderRadius: '8px 8px 0px 0px', backgroundColor: bgColor }}>
                    <MKBox color="#fff" display="flex" flexDirection="column" alignItems="left">
                      <MKTypography color="inherit" fontWeight="bold">{ward?.center_id.service_provider_id.name}</MKTypography>
                      <MKTypography color="inherit" fontWeight="bold">{ward?.center_id.name}</MKTypography>
                      <MKTypography color="inherit" fontWeight="bold">{ward?.name}</MKTypography>
                      <MKTypography color="inherit" fontWeight="bold">{`${localeMapping.patients}: ${ward?.patients?.length}`}</MKTypography>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
            );
          })}
        </Grid>
      </MKBox>
    </MKBox>
  );
};

export default AVHistorySection;
