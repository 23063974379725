import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { getDevices, deleteDevice, upgradeDevicesSoftwares, rebootDevices, updateCamerasIP, updateDevicesUrl } from 'api/alpha/devices';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate, Link } from 'react-router-dom';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { datetimeFormatter } from 'utils/datetime';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

const DeviceListViewSection = () => {
  const [sortBy, setSortBy] = useState('device_physical_id');
  const [sortDirection, setSortDirection] = useState('asc');

  const deviceTableColumns = useMemo(() => [
    { field: 'device_physical_id', label: 'Device Identifier', sortable: true },
    { field: 'cameras', label: 'Cameras', sortable: false },
    { field: 'device_number', label: 'Device Number', sortable: true },
    { field: 'service_provider', label: 'Service Provider' },
    { field: 'center', label: 'Center' },
    { field: 'active', label: 'Online', sortable: true },
    { field: 'fall_detection_script', label: 'AI Script Version', sortable: true },
    { field: 'watchdog_script', label: 'Watchdog Script Version', sortable: true },
    { field: 'lastmoddate', label: 'Last Modified Date', sortable: true },
    { field: 'view', width: '10px' },
  ], []);

  const [devices, setDevices] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchDevicesFromApi = useCallback(() => {
    const requestParams = {
      $expand: 'ward_id/center_id/service_provider_id,fall_detection_script,watchdog_script,cameras',
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}) };
    return getDevices(requestParams)
      .then(({ data }) => {
        setDevices(data.map((datum) => {
          return {
            ...datum,
            cameras: datum?.cameras.map((camera) => camera.camera_identifier).join(),
          };
        }) || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, sortBy, sortDirection]);

  const onPressAdd = useCallback(() => {
    navigate('/devices/create?from=devices_list');
  }, [navigate]);

  const onPressEdit = useCallback((itemId) => {
    navigate(`/devices/edit?device_id=${itemId}&edit=true`);
  }, [navigate]);

  const onPressUpdateIP = useCallback((deviceIds) => {
    if (!deviceIds.length) return console.log('No devices selected');
    const body = {
      device_id: deviceIds,
    };
    return updateCamerasIP(body)
      .then((response) => {
        console.log('Updated devices', body, response);
        return fetchDevicesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchDevicesFromApi, setAuth]);

  const onPressUpgradeAI = useCallback((deviceIds) => {
    if (!deviceIds.length) return console.log('No devices selected');
    const body = {
      device_id: deviceIds,
      type: 'download_fall_detection_script',
    };
    return upgradeDevicesSoftwares(body)
      .then((response) => {
        console.log('Upgraded devices', body, response);
        return fetchDevicesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchDevicesFromApi, setAuth]);

  const onPressUpgradeWatchdog = useCallback((deviceIds) => {
    if (!deviceIds.length) return console.log('No devices selected');
    const body = {
      device_id: deviceIds,
      type: 'download_watchdog_script',
    };
    return upgradeDevicesSoftwares(body)
      .then((response) => {
        console.log('Upgraded devices', body, response);
        return fetchDevicesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchDevicesFromApi, setAuth]);

  const onPressReboot = useCallback((deviceIds) => {
    if (!deviceIds.length) return console.log('No devices selected');
    const body = {
      device_id: deviceIds,
    };
    return rebootDevices(body)
      .then((response) => {
        console.log('Rebooted devices', body, response);
        return fetchDevicesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchDevicesFromApi, setAuth]);

  const onPressDelete = useCallback((deviceId) => {
    return deleteDevice(deviceId)
      .then(() => {
        return fetchDevicesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchDevicesFromApi, setAuth]);

  const onPressUploadScript = useCallback(() => {
    navigate('/devices/scripts');
  }, [navigate]);

  const onPressUpdateUrl = useCallback((deviceIds) => {
    if (!deviceIds.length) return console.log('No devices selected');
    const body = {
      device_id: deviceIds,
    };
    return updateDevicesUrl(body)
      .then((response) => {
        console.log('Updated devices url', body, response);
        return fetchDevicesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchDevicesFromApi, setAuth]);

  useEffect(() => {
    fetchDevicesFromApi();
  }, [fetchDevicesFromApi]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = deviceTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [deviceTableColumns]);

  const rowData = useMemo(() => {
    if (!devices) return [];
    return devices.map((device) => {
      return {
        ...device,
        active: device?.active ? (<HexagonIcon sx={{ color: '#67FF49' }} />) : (<HexagonIcon sx={{ color: '#FF5549' }} />),
        fall_detection_script: device?.fall_detection_script?.name,
        watchdog_script: device?.watchdog_script?.name,
        center: device?.ward_id?.center_id?.name,
        service_provider: device?.ward_id?.center_id?.service_provider_id?.name,
        lastmoddate: datetimeFormatter(device?.lastmoddate, 'YYYY-MM-DD HH:mm:ss'),
        view: (<Link to={`/devices/list/view?device_id=${device.av_device_id}`} state={{ device }}><ArrowForwardIos sx={{ color: '#3583C5' }} /></Link>),
      };
    }).filter(Boolean);
  }, [devices]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={deviceTableColumns}
        idField="av_device_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        onPressReboot={onPressReboot}
        onPressUpgradeAI={onPressUpgradeAI}
        onPressUpgradeWatchdog={onPressUpgradeWatchdog}
        onPressUpdateUrl={onPressUpdateUrl}
        // onPressUpdateIP={onPressUpdateIP}
        onPressUploadScript={onPressUploadScript}
        selectable
        multirowSelection
        clickable
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
      />
    </MKBox>
  );
};

DeviceListViewSection.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default DeviceListViewSection;
