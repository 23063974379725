import PropTypes from 'prop-types';
import Banner from 'components/Banner';

const BannerSection = ({ src, items, top_offset, height, editable, onPressEdit, ...props }) => {
  return (
    <Banner
      src={src}
      items={items}
      offsetTop={top_offset}
      height={height}
      editable={editable}
      onPressEdit={onPressEdit}
      {...props}
    />
  );
};

BannerSection.propTypes = {
  src: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  top_offset: PropTypes.string,
  justify_content: PropTypes.string,
  align_items: PropTypes.string,
  height: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

BannerSection.defaultProps = {
  src: null,
  items: [{}],
  justify_content: null,
  align_items: null,
  height: null,
  top_offset: null,
};

export default BannerSection;
