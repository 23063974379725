import PropTypes from 'prop-types';
import Map from 'components/Map';

const MapSection = ({ longitude, latitude, api_key, map_idx, editable, onPressEdit, ...props }) => {
  return (
    <Map
      longitude={longitude}
      latitude={latitude}
      google_map_api_key={api_key}
      google_map_id={map_idx}
      {...props}
    />
  );
};

MapSection.propTypes = {
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  api_key: PropTypes.string,
  map_idx: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

MapSection.defaultProps = {
  longitude: '114.1694',
  latitude: '22.3193',
  api_key: null,
  map_idx: null,
};

export default MapSection;
