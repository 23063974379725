import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { updateDynamicTableRow } from 'api/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { CircularProgress } from '@mui/material';

const EditInputsFormSection = ({ update_success_message, section, ...props }) => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = useCallback((values) => {
    const { dyn_t, section_definition } = section;
    const { collection_definition } = section_definition;
    const updateBody = {
      json_short_data: JSON.stringify(values),
    };
    return updateDynamicTableRow(collection_definition?.collection_definition_id, dyn_t, updateBody)
      .then(({ data }) => {
        alert(update_success_message);
        navigate(-1);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [section, update_success_message, setAuth, navigate]);

  const formAttributes = useMemo(() => {
    const { section_definition } = section;
    const { collection_definition } = section_definition;
    const { attributes } = collection_definition;
    return (attributes || []).filter(({ name }) => (name || '').match('^value_([0-9]+)$'));
  }, [section]);

  const initialValues = useMemo(() => {
    return formAttributes.reduce((values, attribute) => {
      const newValues = { ...values };
      const attrNum = attribute.name.replace('value_', '');
      newValues[attribute.attribute_id] = props[`value_${attrNum}`];
      return newValues;
    }, {});
  }, [formAttributes, props]);

  const getColumnWidth = (isSubmitting, dirty, isField) => {
    if (isSubmitting || !dirty) {
      return 12;
    }
    if (isField) {
      return 10;
    }
    return 2;
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, setFieldValue, handleBlur, handleSubmit, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            {formAttributes.sort(
              (a1, a2) => a1.name.localeCompare(a2.name),
            ).map((attribute) => {
              const attrNum = attribute.name.replace('value_', '');
              return (
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                  {!!attribute && (
                  <Grid item xs={getColumnWidth(isSubmitting, dirty, true)}>
                    <InputField
                      name={attribute.attribute_id}
                      label={props[`label_${attrNum}`]}
                      value={values[attribute.attribute_id]}
                      type={props[`input_type_${attrNum}`]}
                      button_text="Upload"
                      max_file_size={props[`max_file_size_${attrNum}`]}
                      accept_file_types={props[`accept_file_type_${attrNum}`]}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  )}
                  <Grid item xs={getColumnWidth(isSubmitting, dirty)}>
                    {!(isSubmitting || !dirty) ? (
                      <Button
                        type="submit"
                        size="large"
                        variant="gradient"
                        color="info"
                        fullWidth
                        disabled={isSubmitting || !dirty}
                      >
                        {isSubmitting ? (
                          <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                        ) : 'Rename'}
                      </Button>
                    ) : null }
                  </Grid>
                </Grid>
              );
            })}
            {errors.form && (
              <Grid container justifyContent="flex-end">
                <Grid item xs={12} md={6} xl={4}>
                  <MKTypography variant="caption" color="error">
                    {errors.form}
                    &nbsp;
                  </MKTypography>
                </Grid>
              </Grid>
            )}
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditInputsFormSection.propTypes = {
  // label_1: PropTypes.string,
  // label_2: PropTypes.string,
  // label_3: PropTypes.string,
  // value_1: PropTypes.string,
  // value_2: PropTypes.string,
  // value_3: PropTypes.string,
  // input_type_1: PropTypes.bool,
  // input_type_2: PropTypes.bool,
  // input_type_3: PropTypes.bool,
  // ...etc
  update_success_message: PropTypes.string,
  section: PropTypes.shape({
    dyn_t: PropTypes.string,
    section_definition: PropTypes.shape({
      collection_definition: PropTypes.shape({
        collection_definition_id: PropTypes.string,
        attributes: PropTypes.array,
      }),
    }),
  }),
};

export default EditInputsFormSection;
