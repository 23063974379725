import { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getLocaleMap } from 'utils/locales';

const EditSolutionForm = ({ solution, onSave, ...props }) => {
  const initialValues = useMemo(() => ({
    display_name: solution?.display_name || '',
    type: solution?.type || '',
  }), [solution]);

  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_display_name', 'labels_type', 'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="display_name"
                  label={lm.labels_display_name}
                  value={values.display_name || ''}
                  onChange={handleChange('display_name')}
                  onBlur={handleBlur('display_name')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="type"
                  label={lm.labels_type}
                  value={values.type || ''}
                  onChange={handleChange('type')}
                  onBlur={handleBlur('type')}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : lm.save_button}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditSolutionForm.propTypes = {
  solution: PropTypes.shape({
    display_name: PropTypes.string,
    type: PropTypes.number,
  }),
  onSave: PropTypes.func,
};

EditSolutionForm.defaultProps = {
  solution: {},
  onSave: () => {},
};

export default EditSolutionForm;
