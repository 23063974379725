import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';

export const withProps = (Component, extraProps) => {
  return (props) => {
    return (
      <Component {...props} {...extraProps} />
    );
  };
};

export const withLoading = (Component) => ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  return (
    <MKBox sx={{ position: 'relative' }}>
      <Component
        {...props}
        loading={loading}
        setLoading={setLoading}
      />
      {loading && (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, background: 'radial-gradient(closest-side, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.3)' }}
        >
          <CircularProgress color="secondary" thickness={6} />
        </MKBox>
      )}
    </MKBox>
  );
};
