import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MenuItemList from 'pages/EditMenu/MenuItemList';
import EditMenuItemDependencyForm from 'pages/EditMenuItemDependencies/EditMenuItemDependencyForm';
import { getMenu, updateMenuItem, getMenuItemDependency, createMenuItemDependency, updateMenuItemDependency } from 'api/menus';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const EditMenuItemDependenciesPage = () => {
  const { menuId, menuItemDependencyId, action } = useParams();
  const [menuItemDependency, setMenuItemDependency] = useState(null);
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const fetchMenuItemDependencyFromApi = useCallback(() => {
    if (!menuItemDependencyId) {
      if (!menuId) {
        return Promise.resolve();
      }
      const menuParams = {};
      return getMenu(menuId, menuParams)
        .then(({ data }) => {
          const { menu_node } = data;
          setMenuItemDependency({ menu_node });
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }

    const menuItemDependencyParams = {
      $expand: 'menu_item',
    };
    return getMenuItemDependency(menuItemDependencyId, menuItemDependencyParams)
      .then(({ data }) => {
        if (action === 'add') {
          const { menu_item } = data;
          setMenuItemDependency({ menu_node: menu_item?.menu_item_id });
        } else {
          setMenuItemDependency(data);
        }
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [menuItemDependencyId, menuId, action, setAuth]);

  const onClickCancel = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  const onClickSaveToApi = useCallback((values, { setFieldError }) => {
    const isCreating = !menuItemDependencyId || action === 'add';
    const menuItemBody = {
      label: values.label,
      type: values.type,
      img_url: values.img_url,
      active_img_url: values.active_img_url,
      img_props: values.img_props,
      active_img_props: values.active_img_props,
      label_props: values.label_props,
      active_label_props: values.active_label_props,
      container_props: values.container_props,
      active_container_props: values.active_container_props,
      new_tab: values.new_tab,
      path: values.type !== 1 ? values.path : null,
      disabled: values.disabled,
    };
    const menuItemDependencyBody = {
      sequence: values.sequence,
      ...(isCreating ? {
        menu_node: menuItemDependency?.menu_node,
        menu_item: menuItemBody,
      } : {}),
    };
    return (!isCreating ? Promise.all([
      updateMenuItem(menuItemDependency?.menu_item?.menu_item_id, menuItemBody),
      updateMenuItemDependency(menuItemDependencyId, menuItemDependencyBody),
    ]) : createMenuItemDependency(menuItemDependencyBody))
      .then(() => {
        if (!isCreating) {
          return fetchMenuItemDependencyFromApi();
        }
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [menuItemDependencyId, menuItemDependency, action, fetchMenuItemDependencyFromApi, navigate]);

  useEffect(() => {
    fetchMenuItemDependencyFromApi();
  }, [fetchMenuItemDependencyFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditMenuItemDependencyForm
          menuItemDependency={menuItemDependency}
          onSave={onClickSaveToApi}
          onCancel={onClickCancel}
        />
        {menuItemDependency?.menu_item?.type === 1 && (
          <MenuItemList menuNodeId={menuItemDependency?.menu_item?.menu_item_id} />
        )}
      </Container>
    </MKBox>
  );
};

export default EditMenuItemDependenciesPage;
