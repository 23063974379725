import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';

const SimpleHeader = ({ title, logo, bgImage, bgColor, light, sticky, absolute, ...props }) => {
  return (
    <MKBox
      bgColor={bgColor}
      p={{ xs: 0, md: 1 }}
      position={absolute ? 'absolute' : 'relative'}
      {...(absolute ? {
        m: 2,
        left: 0,
        right: 0,
        borderRadius: 'xl',
      } : {})}
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      {...props}
    >
      <Container>
        <MKBox
          py={1}
          borderRadius="xl"
          color={light ? 'white' : 'dark'}
          left={0}
        >
          <MKBox display="flex" justifyContent="space-between" alignItems="center">
            <MKBox
              component={Link}
              lineHeight={1}
              to="/"
            >
              <MKBox mr="1rem">
                {logo}
              </MKBox>
            </MKBox>
            <MKTypography fontWeight="bold" color={light ? 'white' : 'dark'}>
              {title}
            </MKTypography>
          </MKBox>
        </MKBox>
      </Container>
    </MKBox>
  );
};

SimpleHeader.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.node,
  bgImage: PropTypes.string,
  bgColor: PropTypes.string,
  light: PropTypes.bool,
  sticky: PropTypes.bool,
  absolute: PropTypes.bool,
};

SimpleHeader.defaultProps = {
  title: '',
  logo: null,
  bgImage: null,
  bgColor: null,
  light: true,
  sticky: false,
  absolute: false,
};

export default SimpleHeader;
