import PropTypes from 'prop-types';
import Image from 'components/Image';
import { useMemo } from 'react';

const ImageSection = ({ src, width, height, alt, border_radius, object_fit, object_position, circle, editable, onPressEdit, ...props }) => {
  const imageProps = useMemo(() => {
    return { ...props };
  }, [props]);

  const imageUrlifFromCollection = useMemo(() => {
    if (imageProps['src.is_from_collection']) {
      return imageProps['src.file_url'];
    }
    return null;
  }, [imageProps]);

  return (
    <Image
      src={imageUrlifFromCollection || src || ''}
      width={width}
      height={height}
      alt={alt}
      borderRadius={border_radius}
      objectFit={object_fit}
      objectPosition={object_position}
      circle={circle}
      {...props}
    />
  );
};

ImageSection.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alt: PropTypes.string,
  border_radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  object_fit: PropTypes.string,
  object_position: PropTypes.string,
  circle: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default ImageSection;
