import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { createHandlerParameter } from 'api/handler_parameters';
import { getLocaleMap } from 'utils/locales';
import EditHandlerParameterForm from 'sections/HandlerParameterEdit/EditHandlerParameterForm';

const HandlerParameterCreateSection = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_create_parameter'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const handlerId = searchParams.get('handler_id');

  const createHandlerParameterToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      handler_parameter_name: values.handler_parameter_name,
      parameter: values.parameter,
      handler: handlerId,
    };
    return createHandlerParameter(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [handlerId, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: '10px' }}>
          {lm.labels_create_parameter}
        </h4>
        <EditHandlerParameterForm
          onSave={createHandlerParameterToApi}
        />
      </Container>
    </MKBox>
  );
};

export default HandlerParameterCreateSection;
