import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { getLocaleMap } from 'utils/locales';

const LayoutEditBarSection = ({ layout, ...props }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['editable_wrappers_layout_author_mode'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const { name } = layout || {};
  return (
    <Container sx={{ p: 2 }}>
      {lm.editable_wrappers_layout_author_mode}
      {' '}
      {name}
    </Container>
  );
};

LayoutEditBarSection.propTypes = {
  layout: PropTypes.shape({
    name: PropTypes.string,
  }),
};

LayoutEditBarSection.defaultProps = {
  layout: {},
};

export default LayoutEditBarSection;
