import PropTypes from 'prop-types';
import MKTypography from 'components/MaterialKit/MKTypography';

const RichTextSection = ({ text, variant, align, color, font_size, font_weight, editable, onPressEdit, ...props }) => {
  const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
  return (
    <MKTypography
      variant={variant}
      textAlign={align}
      color={color}
      fontSize={formattedFontSize}
      fontWeight={font_weight}
      {...({
        dangerouslySetInnerHTML: {
          __html: text || '',
        },
      })}
      {...props}
    />
  );
};

RichTextSection.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  font_weight: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default RichTextSection;
