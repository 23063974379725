import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import EventTriggerListViewSection from 'sections/EventTriggerListView';
import HandlerListViewSection from 'sections/HandlerListView';
// import SignalListViewSection from 'sections/SignalListView';
import ParameterListViewSection from 'sections/ParameterListView';
import SignalListViewSection from 'sections/SignalListView';
import { getLocaleMap } from 'utils/locales';

const EditBusinessLogicForm = ({ businessLogic, isEdit, onSave, ...props }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_business_logic_name', 'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const initialValues = useMemo(() => ({
    business_logic_name: businessLogic?.business_logic_name || '',
  }), [businessLogic]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="business_logic_name"
                  label={lm.labels_business_logic_name}
                  value={values.business_logic_name || ''}
                  onChange={handleChange('business_logic_name')}
                  onBlur={handleBlur('business_logic_name')}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : lm.save_button}
                </Button>
              </Grid>
            </Grid>
            {isEdit && (
              <Grid item xs={12}>
                <EventTriggerListViewSection />
                <HandlerListViewSection />
                <SignalListViewSection />
                <ParameterListViewSection />
              </Grid>
            )}
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditBusinessLogicForm.propTypes = {
  businessLogic: PropTypes.shape({
    business_logic_name: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

EditBusinessLogicForm.defaultProps = {
  businessLogic: {},
  isEdit: false,
  onSave: () => {},
};

export default EditBusinessLogicForm;
