import api from 'api';

export const getDevices = (params) => {
  return api.get('/av_devices', params, 'bearer');
};

export const getDevice = (id, params) => {
  return api.get(`/av_devices/${id}`, params, 'bearer');
};

export const updateDevice = (id, body) => {
  return api.put(`/av_devices/${id}`, body, 'bearer');
};

export const createDevice = (body) => {
  return api.post('/av_devices', body, 'bearer');
};

export const deleteDevice = (id) => {
  return api.delete(`/av_devices/${id}`, 'bearer');
};

export const updateCamerasIP = (params) => {
  return api.post('/update_video_stream_ip', params, 'bearer');
};

export const updateCamerasFloorPolygon = (params) => {
  return api.post('/update_floor_polygon', params, 'bearer');
};

export const upgradeDevicesSoftwares = (params) => {
  return api.post('/update_software', params, 'bearer');
};

export const rebootDevices = (params) => {
  return api.post('/reboot_devices', params, 'bearer');
};

export const updateDevicesUrl = (params) => {
  return api.post('/update_url', params, 'bearer');
};
