import { getLocales } from 'api/locales';

const enUSLanguageObjectId = '72efaf6c-9f91-479d-85d0-9d2f158d5ee0';
const zhHKLanguageObjectId = 'b73402eb-89ee-4171-bc5f-a1d9940f61e3';

export const tryGetLocalizedText = (parseableText, languageKey) => {
  try {
    const isNumber = Number(parseableText);
    const textObject = JSON.parse(parseableText);
    if (isNumber) {
      return parseableText;
    }
    return textObject[languageKey];
  } catch (e) {
    return parseableText;
  }
};

export const getLocaleMap = (keys) => {
  return new Promise((resolve, reject) => {
    const currentLanguage = localStorage.getItem('i18nextLng');
    let languageId;
    switch (currentLanguage) {
      case 'en-US':
        languageId = enUSLanguageObjectId;
        break;
      case 'zh-HK':
        languageId = zhHKLanguageObjectId;
        break;
      default:
        languageId = enUSLanguageObjectId;
        break;
    }
    const request_params = {
      'string[in]': keys.join(','),
      '$expand': 'language_strings',
      'language_strings/language_id': languageId,
    };
    getLocales(request_params)
      .then(({ data }) => {
        const resultMap = data && data.reduce((acc, item) => {
          const translated_text = item.language_strings.find((ls) => ls.language_id === languageId).translated_string;
          acc[item.string] = translated_text;
          return acc;
        }, {});
        // eslint-disable-next-line no-console
        resolve(resultMap);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
