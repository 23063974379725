import MKBox from 'components/MaterialKit/MKBox';
import React, { useEffect, useState } from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import CenterDistributionSection from './centerDistributionPage';

const AVDevicesManagementSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb startingNode={localeMapping.devices} />
      <MKBox>
        <CenterDistributionSection />
      </MKBox>
    </MKBox>
  );
};

export default AVDevicesManagementSection;
