import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from 'contexts/auth';
import { sendApiRequest } from 'api/general';
import { handleErrorResponse } from 'utils/general';
import TextSection from 'sections/Text';

const TextFromApiSection = ({ url, params, method, target, variant, align, color, font_size, font_weight, is_rich_text, is_unix_timestamp, editable, onPressEdit, ...props }) => {
  const [text, setText] = useState('Empty');
  const { setAuth } = useAuth();

  const fetchTextByTargetField = useCallback(() => {
    const parameteres = { ...params };
    if (!url) {
      return Promise.resolve();
    }
    return sendApiRequest(url, method, parameteres, 'bearer')
      .then(({ data }) => {
        setText(data[target] || 'Error: no data found');
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [url, method, params, setAuth, target]);

  useEffect(() => {
    fetchTextByTargetField();
  }, [fetchTextByTargetField]);

  if (!text) {
    return null;
  }
  return (
    <TextSection
      text={text}
      variant={variant}
      align={align}
      color={color}
      font_size={font_size}
      font_weight={font_weight}
      is_rich_text={is_rich_text}
      {...props}
    />
  );
};

TextFromApiSection.propTypes = {
  url: PropTypes.string,
  params: PropTypes.object,
  target: PropTypes.string,
  method: PropTypes.string,
  variant: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  font_weight: PropTypes.string,
  is_rich_text: PropTypes.bool,
  is_unix_timestamp: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default TextFromApiSection;
