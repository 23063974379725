import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MKBox from 'components/MaterialKit/MKBox';
import Breadcrumbs from 'components/Breadcrumbs';

const BreadcrumbsSection = ({ routes, color, variant, separator_size, ...props }) => {
  return (
    <MKBox py={1} px={2}>
      <Breadcrumbs
        routes={routes}
        separator={(
          <ArrowForwardIosIcon
            fontSize={separator_size}
            color={color}
          />
        )}
        labelProps={{
          variant,
          color,
        }}
        {...props}
      />
    </MKBox>
  );
};

BreadcrumbsSection.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
  color: PropTypes.string,
  variant: PropTypes.string,
  separator_size: PropTypes.string,
};

export default BreadcrumbsSection;
