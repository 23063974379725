import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/Accordion';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { getPublicSections } from 'api/sections';
import { useAuth } from 'contexts/auth';

const AccordionSection = ({ visible, hidden, allow_multiple_open, item_props, editable, onPressEdit, ...props }) => {
  const [visibleSections, setVisibleSections] = useState([]);
  const [hiddenSections, setHiddenSections] = useState([]);
  const [sectionsProps, setSectionsProps] = useState({});

  const { auth, setAuth } = useAuth();

  const fetchSectionsFromApi = useCallback((sectionIds) => {
    const sectionParams = {
      'section_id[in]': sectionIds.filter((id) => !!id).join(','),
      '$expand': 'section_definition/collection_definition/attributes',
    };
    return getPublicSections(sectionParams)
      .then(({ data }) => {
        // need to re-order the sections to match the original sectionIds order
        const sections = (sectionIds || []).map(
          (sectionId) => data.find(({ section_id }) => section_id === sectionId),
        );
        return sections;
      });
  }, []);

  const fetchVisibleSectionsFromApi = useCallback((sectionIds) => {
    return fetchSectionsFromApi(sectionIds)
      .then((sections) => {
        setVisibleSections(sections);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchSectionsFromApi, setAuth]);

  const fetchHiddenSectionsFromApi = useCallback((sectionIds) => {
    return fetchSectionsFromApi(sectionIds)
      .then((sections) => {
        setHiddenSections(sections);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchSectionsFromApi, setAuth]);

  const renderNode = useCallback((section) => {
    if (!section) {
      return null;
    }
    const sectionProps = sectionsProps ? sectionsProps[section.section_id] : {};
    return renderSectionComponent(section, sectionProps, false, null);
  }, [sectionsProps]);

  useEffect(() => {
    ([...visibleSections, ...hiddenSections]).forEach((section) => {
      if (!section) {
        return;
      }
      const { section_id } = section;
      return fetchSectionProps(section, !auth.user)
        .then((sectionProps) => {
          setSectionsProps((oriSectionProps) => {
            const updatedSectionProps = { ...oriSectionProps };
            updatedSectionProps[section_id] = sectionProps;
            return updatedSectionProps;
          });
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    });
  }, [visibleSections, hiddenSections, auth.user, setAuth]);

  useEffect(() => {
    if (visible?.length > 0) {
      fetchVisibleSectionsFromApi(visible);
    }
  }, [visible, fetchVisibleSectionsFromApi]);

  useEffect(() => {
    if (hidden?.length > 0) {
      fetchHiddenSectionsFromApi(hidden);
    }
  }, [hidden, fetchHiddenSectionsFromApi]);

  const visibleNodes = useMemo(() => visibleSections.map(renderNode), [visibleSections, renderNode]);
  const hiddenNodes = useMemo(() => hiddenSections.map(renderNode), [hiddenSections, renderNode]);

  return (
    <Accordion
      visibleNodes={visibleNodes}
      hiddenNodes={hiddenNodes}
      allowMultipleOpen={allow_multiple_open}
      itemProps={item_props}
      {...props}
    />
  );
};

AccordionSection.propTypes = {
  visible: PropTypes.arrayOf(PropTypes.string),
  hidden: PropTypes.arrayOf(PropTypes.string),
  allow_multiple_open: PropTypes.bool,
  item_props: PropTypes.object,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default AccordionSection;
