import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { updateCamera, getCamera } from 'api/alpha/cameras';
import { getPatients } from 'api/alpha/patients';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getDevices } from 'api/alpha/devices';
import { getLocaleMap } from 'utils/locales';
import CameraForm from './cameraForm';

const CameraEditSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'cameras', 'edit'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cameraId = searchParams.get('camera_id');
  const from = searchParams.get('from');

  const [camera, setCamera] = useState(null);
  const [devices, setDevices] = useState([]);
  const [patients, setPatients] = useState([]);

  const fetchCameraFromApi = useCallback(() => {
    return getCamera(cameraId, { $expand: 'device,patient' })
      .then(({ data }) => {
        return data;
      });
  }, [cameraId]);

  const fetchDevicesFromApi = useCallback(() => {
    const requestParams = { $orderBy: 'device_physical_id asc' };
    return getDevices(requestParams)
      .then(({ data }) => {
        return data;
      });
  }, []);

  const fetchPatientsFromApi = useCallback(() => {
    const requestParams = { $orderBy: 'name asc' };
    return getPatients(requestParams)
      .then(({ data }) => {
        return data;
      });
  }, []);

  useEffect(() => {
    if (!cameraId) return;
    Promise.all([
      fetchDevicesFromApi(),
      fetchCameraFromApi(),
      fetchPatientsFromApi(),
    ]).then(([devicesData, cameraData, patientsData]) => {
      setDevices(devicesData);
      setCamera(cameraData);
      setPatients(patientsData);
    });
  }, [cameraId, fetchCameraFromApi, fetchDevicesFromApi, fetchPatientsFromApi]);

  const deviceOptions = devices.map((device) => ({
    value: device.av_device_id,
    label: device.device_physical_id,
  }));

  const patientsOptions = patients.map((patient) => ({
    value: patient.patient_id,
    label: patient.name,
  }));

  const updateCameraToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      ...values,
    };
    if (!payload.floor_polygon_file) {
      payload.floor_polygon_file = null;
    }

    delete payload.service_provider;
    delete payload.center;
    delete payload.patient_id;

    return updateCamera(camera?.camera_id, payload)
      .then((resp) => {
        if (resp.status === 200) {
          if (from === 'center_mgt') {
            navigate(-1);
          }
          navigate('/devices/cameras/list');
        }
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [camera?.camera_id, from, navigate]);

  const bb = useMemo(() => {
    if (from === 'center_mgt') return -1;
    return '/devices/cameras/list';
  }, [from]);

  if (!camera) return null;
  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.cameras, camera?.camera_identifier, localeMapping.edit]}
        showBackButton
        backButtonPath={bb}
      />
      <CameraForm camera={camera} onSave={updateCameraToApi} devices={deviceOptions} patients={patientsOptions} />
    </MKBox>
  );
};

export default CameraEditSection;
