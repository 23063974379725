import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { getMenu, updateMenu, updateMenuItem } from 'api/menus';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import EditMenuForm from './EditMenuForm';
import MenuItemList from './MenuItemList';

const EditMenuPage = () => {
  const [menu, setMenu] = useState(null);
  const { menuId } = useParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const fetchMenuFromApi = useCallback(() => {
    const menuParams = {
      $expand: 'menu_node',
    };
    return getMenu(menuId, menuParams)
      .then(({ data }) => {
        setMenu(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [menuId, setAuth]);

  const onClickCancel = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  const onClickSaveToApi = useCallback((values, { setFieldError }) => {
    const menuBody = {
      display_name: values.display_name,
      primary_color: values.primary_color,
      secondary_color: values.secondary_color,
      font_color: values.font_color,
      container_props: values.container_props,
      item_container_props: values.item_container_props,
      active_item_container_props: values.active_item_container_props,
      item_img_props: values.item_img_props,
      active_item_img_props: values.active_item_img_props,
      item_label_props: values.item_label_props,
      active_item_label_props: values.active_item_label_props,
    };
    const menuItemBody = {
      label: values.header_label,
      img_url: values.header_img_url,
      container_props: values.header_container_props,
      img_props: values.header_img_props,
      label_props: values.header_label_props,
    };
    return Promise.all([
      updateMenu(menu?.menu_id, menuBody),
      updateMenuItem(menu?.menu_node?.menu_item_id, menuItemBody),
    ])
      .then(() => {
        return fetchMenuFromApi();
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [menu, fetchMenuFromApi]);

  useEffect(() => {
    fetchMenuFromApi();
  }, [fetchMenuFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditMenuForm
          menu={menu}
          onSave={onClickSaveToApi}
          onCancel={onClickCancel}
        />
        <MenuItemList menuNodeId={menu?.menu_node?.menu_item_id} />
      </Container>
    </MKBox>
  );
};

export default EditMenuPage;
