import React from 'react';
import { PropTypes } from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Image from 'components/Image';
import { useNavigate } from 'react-router-dom';

export const ImageWithLink = ({ src, width, height, to, href, alt, border_radius, object_fit, object_position, circle, editable, onPressEdit, ...props }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Check if a navigation link is provided
    if (to) {
      navigate(to);
    } else if (href) {
      // If href is provided, open the link in a new tab
      window.open(href, '_blank');
    }
    // Add any additional logic for handling clicks on the image
  };

  return (
    <MKBox>
      {to ? ( 
        // Use React Router navigation if "to" prop is provided
        <MKBox>
          <Image
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
            src={src}
            width={width}
            height={height}
            alt={alt}
            borderRadius={border_radius}
            objectFit={object_fit}
            objectPosition={object_position}
            circle={circle}
            {...props}
          />
        </MKBox>
      ) : (
      // Use a regular anchor tag if "href" prop is provided
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Image
            src={src}
            width={width}
            height={height}
            alt={alt}
            borderRadius={border_radius}
            objectFit={object_fit}
            objectPosition={object_position}
            circle={circle}
            {...props}
          />
        </a>
      )}
    </MKBox>
  );
};

ImageWithLink.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alt: PropTypes.string,
  border_radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  object_fit: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  object_position: PropTypes.string,
  circle: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default ImageWithLink;
