import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { updateHandler, getHandler } from 'api/handlers';
import { createHandlerSignal, deleteHandlerSignal } from 'api/handler_signals';
import EditHandlerForm from './EditHandlerForm';

const HandlerEditSection = () => {
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('edit') === 'true';
  const handlerId = searchParams.get('handler_id');
  const [handler, setHandler] = useState(null);
  const { setAuth } = useAuth();

  const fetchHandlerFromApi = useCallback(() => {
    const params = {
      $expand: 'handler_signals',
    };
    return handlerId && getHandler(handlerId, params)
      .then(({ data }) => {
        setHandler(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [handlerId, setAuth]);

  const updateHandlerToApi = (values, { setFieldError }) => {
    const newSignalIds = values.selected_signals;
    const oldHandlerSignalIdsToDelete = [];
    handler.handler_signals.forEach((handlerSignal) => {
      if (newSignalIds.includes(handlerSignal.signal)) {
        const indexToRemove = newSignalIds.indexOf(handlerSignal.signal);
        newSignalIds.splice(indexToRemove, 1);
      } else {
        oldHandlerSignalIdsToDelete.push(handlerSignal.handler_signal_id);
      }
    });

    const updateBody = {
      handler_name: values.handler_name,
      handler_type: values.handler_type,
      handler_sub_type: values.handler_sub_type ? values.handler_sub_type : null,
      sync: values.sync,
      delay_time: values.delay_time ? values.delay_time : 0,
      function: values.function ? values.function : null,
      data: values.data ? values.data : null,
      expression: values.expression ? values.expression : null,
    };
    return updateHandler(handlerId, updateBody)
      .then(({ data }) => {
        setHandler(data);
        const createHandlerSignalPromises = newSignalIds.map((signalId) => {
          const body = {
            signal: signalId,
            handler: handlerId,
            signal_type: 'in',
          };
          return createHandlerSignal(body);
        });
        const removeHandlerSignalPromises = oldHandlerSignalIdsToDelete.map((handlerSignalId) => deleteHandlerSignal(handlerSignalId));

        Promise.all([...createHandlerSignalPromises, ...removeHandlerSignalPromises]).then((promises) => {
          if (promises.every((promise) => promise.status === 204 || promise.status === 201)) {
            fetchHandlerFromApi();
          } else {
            setFieldError('form', 'Fail to update handler_signals');
          }
        });
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  };

  useEffect(() => {
    fetchHandlerFromApi();
  }, [fetchHandlerFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditHandlerForm
          isEdit={isEdit}
          handler={handler}
          onSave={updateHandlerToApi}
        />
      </Container>
    </MKBox>
  );
};

export default HandlerEditSection;
