import React, { useContext } from 'react';

const AppContext = React.createContext();
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

export const withApp = (Component) => {
  return (props) => (
    <AppConsumer>
      {({ app, setApp }) => (
        <Component
          app={app}
          setApp={setApp}
          {...props}
        />
      )}
    </AppConsumer>
  );
};

export const useApp = () => useContext(AppContext);

export default AppContext;
