import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';
import { getLocaleMap } from 'utils/locales';

const EditPageForm = ({ page, layouts, onSubmit, onCancel, ...props }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_path', 'labels_layout', 'labels_background_color', 'labels_background_image_url', 'labels_refresh_rate', 'errors_required', 'cancel_button', 'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const formValidation = (values) => {
    const { path, layout } = values;
    const errors = {};
    if (!path) {
      errors.path = lm.errors_required;
    }
    if (!layout) {
      errors.layout = lm.errors_required;
    }
    return errors;
  };

  const initialValues = useMemo(() => ({
    path: page?.path || '',
    layout: page?.layout || null,
    background_color: page?.background_color || '',
    background_image_url: page?.background_image_url || '',
    refresh_rate: page?.refresh_rate || 0,
  }), [page]);

  const layoutOptions = useMemo(() => (layouts || []).map((layout) => ({
    label: layout.name,
    value: layout.layout_id,
  })), [layouts]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={formValidation}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, setFieldValue, handleSubmit, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={12}>
                <MKInput
                  name="path"
                  label={lm.labels_path}
                  value={values.path || ''}
                  onChange={handleChange('path')}
                  onBlur={handleBlur('path')}
                  error={touched.path && !!errors.path}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label={lm.labels_layout}
                  value={values.layout}
                  onChange={(v) => setFieldValue('layout', v)}
                  options={layoutOptions}
                  invalid={touched.layout && !!errors.layout}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="background_color"
                  label={lm.labels_background_color}
                  value={values.background_color || ''}
                  onChange={handleChange('background_color')}
                  onBlur={handleBlur('background_color')}
                  error={touched.background_color && !!errors.background_color}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="background_image_url"
                  label={lm.labels_background_image_url}
                  value={values.background_image_url || ''}
                  onChange={handleChange('background_image_url')}
                  onBlur={handleBlur('background_image_url')}
                  error={touched.background_image_url && !!errors.background_image_url}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="refresh_rate"
                  label={lm.labels_refresh_rate}
                  type="number"
                  value={values.refresh_rate || ''}
                  onChange={handleChange('refresh_rate')}
                  onBlur={handleBlur('refresh_rate')}
                  error={touched.refresh_rate && !!errors.refresh_rate}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      {lm.cancel_button}
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting || !dirty}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                      ) : lm.save_button }
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditPageForm.propTypes = {
  page: PropTypes.shape({
    path: PropTypes.string,
    background_color: PropTypes.string,
    background_image_url: PropTypes.string,
    refresh_rate: PropTypes.number,
    layout: PropTypes.string,
  }),
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      layout_id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

EditPageForm.defaultProps = {
  page: {},
  onSubmit: () => {},
  onCancel: () => {},
};

export default EditPageForm;
