import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SignalsContext = React.createContext();
const { Provider, Consumer: SignalsConsumer } = SignalsContext;

export const SignalsProvider = ({ children }) => {
  const [signal, setSignal] = useState(null);

  return (
    <Provider value={{
      signal,
      setSignal,
    }}
    >
      {children}
    </Provider>
  );
};

SignalsProvider.propTypes = {
  children: PropTypes.node,
};

export const withSignals = (Component) => {
  return (props) => (
    <SignalsConsumer>
      {({ signal, setSignal }) => (
        <Component
          signale={signal}
          setSignal={setSignal}
          {...props}
        />
      )}
    </SignalsConsumer>
  );
};

export const useSignals = () => useContext(SignalsContext);

export default SignalsContext;
