import React, { useState } from 'react';
import qs from 'qs';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import Button from 'components/Button';
import Image from 'components/Image';
import { useMenu } from 'contexts/menu';
import MobileMenu from './MobileMenu';

const MenuHeaderSection = ({ logo_url, background_color, display_searchbox, editable, onPressEdit, ...props }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { menu } = useMenu();
  const { menu_node } = menu || {};
  const { menu_items } = menu_node || {};
  const location = useLocation();
  const queryParams = qs.parse((location.search || '').slice(1));
  return (
    <MKBox
      px={{ xs: 0, md: 1 }}
      py={{ xs: 1, md: 2 }}
      bgColor={background_color}
      {...props}
    >
      <Container>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox display="flex" alignItems="center">
            <Image
              src={logo_url}
              height="2rem"
              mr={2}
            />
            {display_searchbox ? (
              <MKInput
                hiddenLabel
                placeholder="Search"
                variant="filled"
                size="small"
                fullWidth
                InputProps={{
                  sx: { borderRadius: '4px', px: 2 },
                  disableUnderline: true,
                }}
              />
            ) : null}
          </MKBox>
          <MKBox display={{ xs: 'none', lg: 'flex' }}>
            <MKBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
            >
              {(menu_items || []).map((menuItem, idx) => {
                const { label: menuItemLabel, path, disabled } = menuItem;
                return !disabled ? (
                  <Button
                    key={`${idx}-${path}`}
                    to={`${path}?${qs.stringify(pick(queryParams, 'mode'))}`}
                    variant="text"
                    color={location.pathname === path ? 'dark' : 'secondary'}
                    fontSize={16}
                    size="small"
                  >
                    {menuItemLabel}
                  </Button>
                ) : null;
              })}
            </MKBox>
          </MKBox>
          <MKBox
            display={{ xs: 'flex', lg: 'none' }}
            lineHeight={0}
            pl={1.5}
            sx={{ cursor: 'pointer' }}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <Icon fontSize="default" color="dark">
              {mobileMenuOpen ? 'close' : 'menu'}
            </Icon>
          </MKBox>
        </MKBox>
        <MKBox
          shadow="lg"
          borderRadius="xl"
          display={{ xs: 'flex', lg: 'none' }}
          px={2}
        >
          <MobileMenu
            menuItems={menu_items}
            open={mobileMenuOpen}
            setOpen={setMobileMenuOpen}
          />
        </MKBox>
      </Container>
    </MKBox>
  );
};

MenuHeaderSection.propTypes = {
  logo_url: PropTypes.string,
  background_color: PropTypes.string,
  display_searchbox: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default MenuHeaderSection;
