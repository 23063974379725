import { useCallback, useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getWards, deleteWard } from 'api/alpha/wards';
import { useSearchParams, useNavigate } from 'react-router-dom';
import DataTable from 'components/DataTable';
import { datetimeFormatter } from 'utils/datetime';

const WardsListViewSection = ({ center }) => {
  const [searchParams] = useSearchParams();
  const centerId = searchParams.get('center_id');
  const centerName = searchParams.get('centerName') || 'Unknown Center';
  const providerName = searchParams.get('providerName') || 'Unknown Provider';

  const wardTableColumns = useMemo(() => [
    { field: 'name', sortable: true },
    { field: 'patients_number', label: 'Patients number' },
    { field: 'center' },
    { field: 'lastmoddate', label: 'Last Modified', sortable: true },
    {
      field: 'ward_id',
      label: 'Ward ID',
      width: '10%',
      sortable: true,
    },
  ], []);

  const [wards, setWards] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchWardsFromApi = useCallback(() => {
    const requestParams = {
      $expand: 'center_id',
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}),
    };
    if (centerId) {
      requestParams.center_id = centerId;
    }
    return getWards(requestParams)
      .then(({ data }) => {
        setWards(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [centerId, setAuth, sortBy, sortDirection]);

  const onPressAdd = useCallback(() => {
    navigate(`/providers/create_ward?&center_id=${center?.center_id}`);
  }, [center?.center_id, navigate]);

  const onPressEdit = useCallback((wardId) => {
    navigate(`/providers/wards?ward_id=${wardId}&centerName=${centerName}&providerName=${providerName}&edit=true`);
  }, [centerName, navigate, providerName]);

  const onPressDelete = useCallback((wardId) => {
    return deleteWard(wardId)
      .then(() => {
        return fetchWardsFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchWardsFromApi, setAuth]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = wardTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [wardTableColumns]);

  useEffect(() => {
    fetchWardsFromApi();
  }, [fetchWardsFromApi]);

  const rowData = useMemo(() => {
    if (!wards) return [];
    return wards.map((row) => {
      return {
        ...row,
        patients_number: row.patients?.length || 0,
        center: row.center_id?.name,
        lastmoddate: row.lastmoddate && datetimeFormatter(row.lastmoddate, 'YYYY-MM-DD HH:mm:ss'),
      };
    });
  }, [wards]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={wardTableColumns}
        idField="ward_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        onSort={onClickSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        selectable
      />
    </MKBox>
  );
};

WardsListViewSection.propTypes = {
  center: PropTypes.shape({
    center_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default WardsListViewSection;
