import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { useAuth } from 'contexts/auth';
import AssetList from './assetList';

const AssetsPage = () => {
  const { auth, setAuth } = useAuth();

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <MKTypography variant="h3" sx={{ mb: 4 }}>DIY Portal Assets Management System</MKTypography>
        <AssetList />
      </Container>
    </MKBox>
  );
};

export default AssetsPage;
