import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import { IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'components/Button';
import { getLocaleMap } from 'utils/locales';

const ConfirmDialog = ({ open, onDecline, onAccept }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['confirm_form_submission', 'cancel', 'confirm'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const handleClose = () => {
    onDecline();
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <MKBox px={2} py={2} sx={{ backgroundColor: 'white' }}>
        <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <MKTypography variant="body1" fontSize="20px" fontWeight="bold" color="black">
            {localeMapping.confirm_form_submission}
          </MKTypography>
          <IconButton onClick={handleClose} sx={{ color: '#C5010A' }}>
            <CloseIcon />
          </IconButton>
        </MKBox>
        <MKBox display="flex" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
          <Button variant="contained" fullWidth color="gray" size="medium" onClick={handleClose}>
            <MKTypography variant="body1" fontSize="20px" fontWeight="bold" color="white">
              {localeMapping.cancel}
            </MKTypography>
          </Button>
          <Button variant="contained" fullWidth color="#2C84EB" size="medium" onClick={() => { onAccept(true); }}>
            <MKTypography variant="body1" fontSize="20px" fontWeight="bold" color="white">
              {localeMapping.confirm}
            </MKTypography>
          </Button>
        </MKBox>

      </MKBox>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onDecline: PropTypes.func,
  onAccept: PropTypes.func,
};

export default ConfirmDialog;
