import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton } from '@mui/material';

const hiddenStyles = {
  maxHeight: 0,
  width: '100%',
  overflow: 'hidden',
  transition: 'all 0.2s cubic-bezier(0, 1, 0, 1)',
};

const showStyles = {
  height: 'auto',
  width: '100%',
  maxHeight: 9999,
  transition: 'all 0.2s cubic-bezier(1, 0, 1, 0)',
};

const buttonOverlayStyles = {
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
};

const AccordionItem = ({ index, isOpen, visibleNode, hiddenNode, itemProps, onToggle, ...props }) => {
  const toggle = () => {
    onToggle(index);
  };

  return (
    <MKBox
      position="relative"
      {...itemProps}
    >
      <MKBox
        position="relative"
        onClick={toggle}
        sx={{ cursor: 'pointer' }}
      >
        {visibleNode}
        {!!hiddenNode && (
          <IconButton
            onClick={toggle}
            sx={{
              ...buttonOverlayStyles,
              fontSize: '2rem',
              color: '#344767',
            }}
          >
            {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        )}
      </MKBox>
      <MKBox
        sx={isOpen ? ({ ...showStyles }) : ({ ...hiddenStyles })}
      >
        {hiddenNode}
      </MKBox>
    </MKBox>
  );
};

AccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  visibleNode: PropTypes.node,
  hiddenNode: PropTypes.node,
  itemProps: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
};

AccordionItem.defaultProps = {
  visibleNode: null,
  hiddenNode: null,
  itemProps: {},
};

export default AccordionItem;
