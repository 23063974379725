export const dynamicSort = (property, direction) => {
  const sortOrder = direction === 'desc' ? -1 : 1;
  return (a, b) => {
    let result;
    if (typeof a[property] === 'string' && typeof b[property] === 'string') {
      result = a[property].localeCompare(b[property]);
    } else if (a[property] < b[property]) {
      result = -1;
    } else if (a[property] > b[property]) {
      result = 1;
    } else {
      result = 0;
    }
    return result * sortOrder;
  };
};
