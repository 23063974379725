import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getParameters } from 'api/parameters';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getLocaleMap } from 'utils/locales';

const EditHandlerParameterForm = ({ handlerParameter, isEdit, onSave, ...props }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap([
      'labels_alias',
      'labels_parameter',
      'save_button',
    ])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const initialValues = useMemo(() => ({
    handler_parameter_name: handlerParameter?.handler_parameter_name || '',
    parameter: handlerParameter?.parameter || '',
  }), [handlerParameter]);

  const [parameters, setParameters] = useState([]);
  const [parameter, setParameter] = useState('');
  const setAuth = useAuth();

  const fetchParametersFromApi = useCallback(() => {
    const params = {
      $orderby: 'parameter_name',
    };
    return getParameters(params)
      .then(({ data }) => {
        setParameters(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    fetchParametersFromApi();
  }, [fetchParametersFromApi]);

  useEffect(() => {
    setParameter(handlerParameter?.parameter);
  }, [handlerParameter]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, setFieldValue }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="handler_parameter_name"
                  label={lm.labels_alias}
                  value={values.handler_parameter_name || ''}
                  onChange={handleChange('handler_parameter_name')}
                  onBlur={handleBlur('handler_parameter_name')}
                  fullWidth
                />
              </Grid>

              <Grid container item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="parameter_label">{lm.labels_parameter}</InputLabel>
                  <Select
                    labelId="parameter_label"
                    id="parameter_select"
                    value={parameter}
                    label={lm.labels_parameter}
                    onChange={(e) => {
                      setFieldValue('parameter', e.target.value);
                      setParameter(e.target.value);
                    }}
                    onBlur={handleBlur('handler_type')}
                  >
                    <MenuItem disabled value="">
                      { lm.labels_parameter }
                    </MenuItem>
                    {parameters.map((param) => <MenuItem key={param.parameter_id} value={param.parameter_id}>{param.parameter_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : lm.save_button}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditHandlerParameterForm.propTypes = {
  handlerParameter: PropTypes.shape({
    handler_parameter_name: PropTypes.string,
    parameter: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

EditHandlerParameterForm.defaultProps = {
  handlerParameter: {},
  isEdit: false,
  onSave: () => {},
};

export default EditHandlerParameterForm;
