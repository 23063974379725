import api from 'api';

export const getObjectTypes = (params) => {
  return api.get('/object_types', params, 'bearer');
};

export const getObjectType = (id, params) => {
  return api.get(`/object_types/${id}`, params, 'bearer');
};

export const getPublicObjectTypes = (params) => {
  return api.get('/public/object_types', params, 'bearer');
};

export const getPublicObjectType = (id, params) => {
  return api.get(`/public/object_types/${id}`, params, 'bearer');
};
