import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getServiceProvider, updateServiceProvider } from 'api/alpha/service_providers';
import CentersListViewSection from 'sections/AVCentersManagement/CentersList';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import ProviderForm from './providerForm';

const ProviderEditSection = () => {
  const [searchParams] = useSearchParams();

  const isEdit = searchParams.get('edit') === 'true';
  const serviceProviderId = searchParams.get('service_provider_id');
  const [serviceProvider, setServiceProvider] = useState(null);
  const { setAuth } = useAuth();

  const fetchServiceProviderFromApi = useCallback(() => {
    const providerParams = {};
    return serviceProviderId && getServiceProvider(serviceProviderId, providerParams)
      .then(({ data }) => {
        setServiceProvider(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [serviceProviderId, setAuth]);

  const updateServiceProviderToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      name: values.name,
    };
    return updateServiceProvider(serviceProviderId, updateBody)
      .then(({ data }) => {
        setServiceProvider(data);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [serviceProviderId]);

  useEffect(() => {
    fetchServiceProviderFromApi();
  }, [fetchServiceProviderFromApi]);

  return (
    <MKBox>
      <AVBreadcrumb items={[serviceProvider?.name]} showBackButton backButtonPath="/providers" />
      <ProviderForm
        isEdit={isEdit}
        serviceProvider={serviceProvider}
        onSave={updateServiceProviderToApi}
      />
      <CentersListViewSection
        provider={serviceProvider}
      />
    </MKBox>
  );
};

export default ProviderEditSection;
