import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { getCameras, deleteCamera } from 'api/alpha/cameras';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';

const CameraListViewSection = ({ ward }) => {
  const [sortBy, setSortBy] = useState('camera_identifier');
  const [sortDirection, setSortDirection] = useState('asc');

  const cameraColumns = useMemo(() => [
    { field: 'camera_identifier', label: 'Camera Identifier', sortable: true },
    { field: 'camera_number', label: 'Camera Number', sortable: true },
    { field: 'device_identifier', label: 'Device Identifier' },
    { field: 'patient_name', label: 'Patient' },
    { field: 'lastmoddate', label: 'Last Modified Date', sortable: true },
  ], []);

  const [cameras, setCameras] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');

  const fetchCamerasFromApi = useCallback(() => {
    const requestParams = {
      '$expand': 'patient,device',
      'device/ward_id': wardId,
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}) };
    return getCameras(requestParams)
      .then(({ data }) => {
        setCameras(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, sortBy, sortDirection, wardId]);

  const onPressEdit = useCallback((itemId) => {
    navigate(`/devices/cameras/edit?camera_id=${itemId}&from=center_mgt&edit=true`);
  }, [navigate]);

  const onPressDelete = useCallback((id) => {
    return deleteCamera(id)
      .then(() => {
        return fetchCamerasFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchCamerasFromApi, setAuth]);

  useEffect(() => {
    fetchCamerasFromApi();
  }, [fetchCamerasFromApi]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = cameraColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [cameraColumns]);

  const rowData = useMemo(() => {
    if (!cameras) return [];
    return cameras.map((camera) => {
      return {
        ...camera,
        device_identifier: camera?.device?.device_identifier,
        patient_name: camera?.patient_id?.name,
        lastmoddate: datetimeFormatter(camera?.lastmoddate, 'YYYY-MM-DD HH:mm:ss'),
      };
    }).filter(Boolean);
  }, [cameras]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={cameraColumns}
        idField="camera_id"
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        selectable
        clickable
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
      />
    </MKBox>
  );
};

CameraListViewSection.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default CameraListViewSection;
