import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import BreadcrumbHeader from 'components/BreadcrumbHeader';

const BreadcrumbHeaderSection = ({ routes, separator_size, color, bg_color, variant, ...props }) => {
  return (
    <MKBox>
      <BreadcrumbHeader
        routes={routes}
        bgColor={bg_color}
        separatorSize={separator_size}
        color={color}
        variant={variant}
        props={props}
      />
    </MKBox>
  );
};

BreadcrumbHeaderSection.propTypes = {
  bg_color: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  separator_size: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

BreadcrumbHeaderSection.defaultProps = {
  bg_color: null,
  color: null,
  variant: null,
  separator_size: null,
  routes: [],
};

export default BreadcrumbHeaderSection;
