import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getBusinessLogic, updateBusinessLogic } from 'api/business_logics';
import EditBusinessLogicForm from './EditBusinessLogicForm';

const BusinessLogicEditSection = () => {
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('edit') === 'true';
  const businessLogicId = searchParams.get('business_logic_id');
  const [businessLogic, setBusinessLogic] = useState(null);
  const { setAuth } = useAuth();

  const fetchBusinessLogicFromApi = useCallback(() => {
    const businessLogicParams = {};
    return businessLogicId && getBusinessLogic(businessLogicId, businessLogicParams)
      .then(({ data }) => {
        setBusinessLogic(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, setAuth]);

  const updateBusinessLogicToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      business_logic_name: values.business_logic_name,
    };
    return updateBusinessLogic(businessLogicId, updateBody)
      .then(({ data }) => {
        setBusinessLogic(data);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [businessLogicId]);

  useEffect(() => {
    fetchBusinessLogicFromApi();
  }, [fetchBusinessLogicFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditBusinessLogicForm
          isEdit={isEdit}
          businessLogic={businessLogic}
          onSave={updateBusinessLogicToApi}
        />
      </Container>
    </MKBox>
  );
};

export default BusinessLogicEditSection;
