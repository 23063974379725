import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getEventTrigger, updateEventTrigger } from 'api/event_triggers';
import { updateSignal } from 'api/signals';
import EditEventTriggerForm from './EditEventTriggerForm';

const EventTriggerEditSection = () => {
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('edit') === 'true';
  const eventTriggerId = searchParams.get('event_trigger_id');
  const [eventTrigger, setEventTigger] = useState(null);
  const { setAuth } = useAuth();

  const fetchEventTriggerFromApi = useCallback(() => {
    const eventTriggerParams = {
      $expand: 'event,output_signal',
    };
    return eventTriggerId && getEventTrigger(eventTriggerId, eventTriggerParams)
      .then(({ data }) => {
        setEventTigger(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [eventTriggerId, setAuth]);

  const updateEventTriggerToApi = (values, { setFieldError }) => {
    let updateSignalPromise;
    if (values.output_signal_name !== eventTrigger?.output_signal?.signal_name) {
      const outputSignalId = eventTrigger.output_signal.signal_id;
      const updateSignalBody = {
        signal_name: values.output_signal_name,
      };
      updateSignalPromise = updateSignal(outputSignalId, updateSignalBody);
    }
    return Promise.resolve(updateSignalPromise).then(() => {
      const updateBody = {
        event_trigger_name: values.event_trigger_name,
        event_trigger_type: values.event_trigger_type,
        event: values.event,
      };
      return updateEventTrigger(eventTriggerId, updateBody)
        .then(({ data }) => {
          if (data) {
            fetchEventTriggerFromApi();
          }
        })
        .catch((err) => {
          const res = err.response;
          setFieldError('form', res.data.error);
        });
    });
  };

  useEffect(() => {
    fetchEventTriggerFromApi();
  }, [fetchEventTriggerFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditEventTriggerForm
          isEdit={isEdit}
          eventTrigger={eventTrigger}
          onSave={updateEventTriggerToApi}
        />
      </Container>
    </MKBox>
  );
};

export default EventTriggerEditSection;
