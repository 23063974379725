import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';
import { Switch } from '@mui/material';
import { getLocaleMap } from 'utils/locales';

const DeviceForm = ({ device, hierarchy, aiScriptsOptions, watchdogScriptsOptions, isEdit, onSave, ...props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['device_number', 'ai_script_version', 'watchdog_script_version', 'api_url', 'runtime_websocket_url', 'config_websocket_url', 'add_to', 'active', 'select_center', 'select_ward', 'select_service_provider', 'save'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const initialValues = useMemo(() => {
    if (!device) {
      return ({
        // device_identifier: '',
        device_number: 0,
        active: false,
        patient_id: '',
        fall_detection_script_id: '',
        watchdog_script_id: '',
        ward_id: '',
        service_provider: '',
        center: '',
        api_url: '',
        websocket_url: '',
        runtime_websocket_url: '',
        config_websocket_url: '',
      });
    }
    return ({
      // device_identifier: device?.device_identifier,
      device_number: device?.device_number,
      active: device?.active,
      fall_detection_script_id: device?.fall_detection_script?.fall_detection_script_id,
      watchdog_script_id: device?.watchdog_script?.watchdog_script_id,
      patient_id: device?.patient_id,
      ward_id: device?.ward_id?.ward_id,
      service_provider: device?.ward_id?.center_id?.service_provider_id?.service_provider_id,
      center: device?.ward_id?.center_id?.center_id,
      api_url: device?.api_url,
      websocket_url: device?.websocket_url,
      runtime_websocket_url: device?.runtime_websocket_url,
      config_websocket_url: device?.config_websocket_url,
    });
  }, [device]);

  const serviceProvidersOptions = useMemo(() => {
    return hierarchy?.serviceProviders?.map((element) => {
      return { value: element?.service_provider_id, label: element?.name };
    });
  }, [hierarchy]);

  const handleSave = (values, { setFieldError }) => {
    if (values.service_provider === '' || values.center === '' || values.ward_id === '') {
      setFieldError('form', 'Please select service provider, center and ward');
      return Promise.resolve(-1);
    }
    onSave(values, { setFieldError });
  };

  return (
    <Formik
      onSubmit={handleSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty }) => {
        const coptions = hierarchy?.centers?.filter((element) => element?.service_provider_id === values.service_provider).map((element) => ({ value: element?.center_id, label: element?.name }));
        const woptions = hierarchy?.wards?.filter((element) => element?.center_id === values.center).map((element) => ({ value: element?.ward_id, label: element?.name }));
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 4, ml: 1 }}>
            <Grid container spacing={1}>
              {/* <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    Device ID
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="device_identifier"
                    label="Device ID"
                    value={values.device_identifier || ''}
                    onChange={handleChange('device_identifier')}
                    onBlur={handleBlur('device_identifier')}
                    fullWidth
                  />
                </Grid>
              </Grid> */}
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.device_number}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="device_number"
                    label={localeMapping.device_number}
                    value={values.device_number || ''}
                    onChange={handleChange('device_number')}
                    onBlur={handleBlur('device_number')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.ai_script_version}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    label={localeMapping.ai_script_version}
                    value={values.fall_detection_script_id}
                    onChange={(v) => {
                      setFieldValue('fall_detection_script_id', v);
                    }}
                    options={aiScriptsOptions}
                    multiple={false}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.watchdog_script_version}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    label={localeMapping.watchdog_script_version}
                    value={values.watchdog_script_id}
                    onChange={(v) => {
                      setFieldValue('watchdog_script_id', v);
                    }}
                    options={watchdogScriptsOptions}
                    multiple={false}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.api_url}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="api_url"
                    label={localeMapping.api_url}
                    value={values.api_url || ''}
                    onChange={handleChange('api_url')}
                    onBlur={handleBlur('api_url')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.runtime_websocket_url}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {values.runtime_websocket_url || ''}
                  </MKTypography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.config_websocket_url}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="config_websocket_url"
                    label={localeMapping.config_websocket_url}
                    value={values.config_websocket_url || ''}
                    onChange={handleChange('config_websocket_url')}
                    onBlur={handleBlur('config_websocket_url')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.add_to}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    label={localeMapping.select_service_provider}
                    value={values.service_provider}
                    onChange={(v) => {
                      setFieldValue('service_provider', v);
                      setFieldValue('center', null);
                      setFieldValue('ward_id', null);
                    }}
                    options={serviceProvidersOptions}
                    multiple={false}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <Select
                    label={localeMapping.select_center}
                    value={values.center}
                    onChange={(v) => { setFieldValue('center', v); setFieldValue('ward_id', null); }}
                    options={coptions}
                    multiple={false}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <Select
                    label={localeMapping.select_ward}
                    value={values.ward_id}
                    onChange={(v) => { setFieldValue('ward_id', v); }}
                    options={woptions}
                    multiple={false}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.active}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <Switch checked={values.active} onChange={() => { setFieldValue('active', !(values.active)); }} />
                </Grid>
              </Grid>

              <Grid container item xs={2} justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : localeMapping.save}
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

DeviceForm.propTypes = {
  device: PropTypes.shape({
    // device_identifier: PropTypes.string,
    patient_id: PropTypes.string,
    ward_id: PropTypes.string,
    type: PropTypes.string,
    device_number: PropTypes.number,
    fall_detection_script: PropTypes.object,
    watchdog_script: PropTypes.object,
    active: PropTypes.bool,
    api_url: PropTypes.string,
    websocket_url: PropTypes.string,
    runtime_websocket_url: PropTypes.string,
    config_websocket_url: PropTypes.string,
  }),
  hierarchy: PropTypes.object,
  aiScriptsOptions: PropTypes.array,
  watchdogScriptsOptions: PropTypes.array,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

DeviceForm.defaultProps = {
  device: {},
  isEdit: false,
  onSave: () => {},
};

export default DeviceForm;
