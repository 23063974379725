import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';

const Image = ({ src, width, height, alt, borderRadius, objectFit, objectPosition, circle, ...props }) => {
  return (
    <MKBox
      component="img"
      src={src}
      width={width || 'auto'}
      height={height || 'auto'}
      loading="lazy"
      alt={alt}
      sx={{
        objectFit,
        objectPosition,
        borderRadius: circle ? '50%' : borderRadius,
      }}
      {...props}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alt: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  circle: PropTypes.bool,
};

Image.defaultProps = {
  src: null,
  alt: '',
  borderRadius: 0,
  objectFit: 'contain',
  circle: false,
};

export default Image;
