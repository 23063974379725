import PropTypes from 'prop-types';
import MuiModal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';

const Modal = ({ isOpen, onClose, title, bgColor, showHeader, children, ...props }) => {
  return (
    <MuiModal open={isOpen} onClose={onClose} sx={{ display: 'grid', placeItems: 'center' }}>
      <Slide direction="down" in={isOpen} timeout={500}>
        <MKBox
          position="relative"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor={bgColor}
          shadow="xl"
          maxHeight="100vh"
          maxWidth="100vw"
          {...props}
        >
          {showHeader && (
            <>
              <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">{title}</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: 'pointer' }} onClick={onClose} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
            </>
          )}
          <MKBox flex={1} sx={{ overflow: 'scroll' }}>
            {children}
          </MKBox>
        </MKBox>
      </Slide>
    </MuiModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  bgColor: PropTypes.string,
  showHeader: PropTypes.bool,
  children: PropTypes.node,
};

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  title: null,
  bgColor: 'white',
  showHeader: true,
  children: null,
};

export default Modal;
