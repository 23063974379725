import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getLocaleMap } from 'utils/locales';

const EditAppForm = ({ app, onSave, ...props }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_display_name', 'labels_base_url', 'labels_major_version', 'labels_minor_version', 'labels_type', 'save_button'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const initialValues = useMemo(() => ({
    display_name: app?.display_name || '',
    base_url: app?.base_url || '',
    major_version: app?.major_version || '',
    minor_version: app?.minor_version || '',
    type: app?.type || '',
  }), [app]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="display_name"
                  label={lm.labels_display_name}
                  value={values.display_name || ''}
                  onChange={handleChange('display_name')}
                  onBlur={handleBlur('display_name')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="base_url"
                  label={lm.labels_base_url}
                  value={values.base_url || ''}
                  onChange={handleChange('base_url')}
                  onBlur={handleBlur('base_url')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MKInput
                  name="major_version"
                  label={lm.labels_major_version}
                  value={values.major_version || ''}
                  onChange={handleChange('major_version')}
                  onBlur={handleBlur('major_version')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MKInput
                  name="minor_version"
                  label={lm.labels_minor_version}
                  value={values.minor_version || ''}
                  onChange={handleChange('minor_version')}
                  onBlur={handleBlur('minor_version')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <MKInput
                  name="type"
                  label={lm.labels_type}
                  value={values.type || ''}
                  onChange={handleChange('type')}
                  onBlur={handleBlur('type')}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : lm.save_button }
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditAppForm.propTypes = {
  app: PropTypes.shape({
    display_name: PropTypes.string,
    base_url: PropTypes.string,
    major_version: PropTypes.string,
    minor_version: PropTypes.string,
    type: PropTypes.number,
  }),
  onSave: PropTypes.func,
};

EditAppForm.defaultProps = {
  app: {},
  onSave: () => {},
};

export default EditAppForm;
