import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import ForgetPasswordForm from 'components/ForgetPasswordForm';
import { forgetPassword } from 'api/auth';

const forgetPasswordFormInitialValues = {
  email: '',
};

const ForgetPasswordSection = ({
  submit_button_color,
  submit_button_title,
  submit_text_color,
  back_button_color,
  back_button_title,
  back_text_color,
  reset_password_url,
  email_label,
  email_placeholder,
  ...props }) => {
  const navigate = useNavigate();

  const onForgetPassword = useCallback((values, { setFieldError }) => {
    return forgetPassword(values.email)
      .then(() => {
        navigate(`${reset_password_url}?email=${values.email}` || '/');
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [navigate, reset_password_url]);

  return (
    <Container>
      <MKBox py="2rem">
        <ForgetPasswordForm
          onForgetPassword={onForgetPassword}
          initialValues={forgetPasswordFormInitialValues}
          submitButtonColor={submit_button_color}
          submitButtonTitle={submit_button_title}
          submitTextColor={submit_text_color}
          backButtonColor={back_button_color}
          backButtonTitle={back_button_title}
          backTextColor={back_text_color}
          emailPlaceholder={email_placeholder}
          emailLabel={email_label}
          resetPasswordUrl={reset_password_url}
          {...props}
        />
      </MKBox>
    </Container>
  );
};

ForgetPasswordSection.propTypes = {
  submit_button_color: PropTypes.string,
  submit_button_title: PropTypes.string,
  submit_text_color: PropTypes.string,
  back_button_color: PropTypes.string,
  back_button_title: PropTypes.string,
  back_text_color: PropTypes.string,
  reset_password_url: PropTypes.string,
  email_label: PropTypes.string,
  email_placeholder: PropTypes.string,
};

ForgetPasswordSection.defaultProps = {
  reset_password_url: null,
};

export default ForgetPasswordSection;
