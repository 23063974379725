import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const GlobalDataContext = React.createContext();
const { Provider, Consumer: GlobalDataConsumer } = GlobalDataContext;

export const GlobalDataProvider = ({ children }) => {
  const [objectTypes, setObjectTypes] = useState([]);
  const [copiedDynamicObj, setCopiedDynamicObj] = useState(null);
  return (
    <Provider
      value={{
        objectTypes,
        setObjectTypes,
        copiedDynamicObj,
        setCopiedDynamicObj,
      }}
    >
      {children}
    </Provider>
  );
};

GlobalDataProvider.propTypes = {
  children: PropTypes.node,
};

export const withGlobalData = (Component) => {
  return (props) => (
    <GlobalDataConsumer>
      {({
        objectTypes,
        setObjectTypes,
        copiedDynamicObj,
        setCopiedDynamicObj,
      }) => (
        <Component
          objectTypes={objectTypes}
          setObjectTypes={setObjectTypes}
          copiedDynamicObj={copiedDynamicObj}
          setCopiedDynamicObj={setCopiedDynamicObj}
          {...props}
        />
      )}
    </GlobalDataConsumer>
  );
};

export const useGlobalData = () => useContext(GlobalDataContext);

export default GlobalDataContext;
