import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { getDevices } from 'api/alpha/devices';
import { getCameras } from 'api/alpha/cameras';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';
import { getLocaleMap } from 'utils/locales';

const CenterDistributionSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'cameras', 'softwares'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const deviceTableColumns = useMemo(() => [
    { field: 'name', label: 'Name' },
    { field: 'amount', label: 'Amount' },
    { field: 'active_devices', label: 'Online Device' },
    { field: 'lastmoddate', label: 'Last Modified' },
  ], []);

  const [devices, setDevices] = useState([]);
  const [cameras, setCameras] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchDevicesFromApi = useCallback(() => {
    const requestParams = {};
    return getDevices(requestParams)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchCamerasFromApi = useCallback(() => {
    const requestParams = {};
    return getCameras(requestParams)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const onPressAdd = useCallback(() => {
    navigate('/devices/create?from=center_distribution');
  }, [navigate]);

  const onPressEdit = useCallback((type) => {
    if (type === '1') {
      navigate('/devices/list?edit=true');
    } else if (type === '2') {
      navigate('/devices/cameras/list?edit=true');
    } else if (type === '3') {
      navigate('/devices/softwares/list?edit=true');
    }
  }, [navigate]);

  useEffect(() => {
    Promise.all([
      fetchDevicesFromApi(),
      fetchCamerasFromApi(),
    ]).then(([devicesData, camerasData]) => {
      setDevices(devicesData);
      setCameras(camerasData);
    });
  }, [fetchCamerasFromApi, fetchDevicesFromApi]);

  const rowData = useMemo(() => {
    if (!devices) return [];
    const latestObject = devices.reduce((acc, curr) => {
      if (curr.lastmoddate > acc.lastmoddate) {
        return curr;
      }
      return acc;
    }, devices[0]);

    return [
      { type: '1', name: localeMapping.devices, amount: devices?.length, active_devices: `${devices.filter((val) => val.active === true)?.length}/${devices?.length}`, lastmoddate: datetimeFormatter(latestObject?.lastmoddate, 'YYYY-MM-DD HH:mm:ss') },
      { type: '2', name: localeMapping.cameras, amount: cameras?.length, active_devices: `${cameras.filter((val) => val.active === true)?.length}/${devices?.length}`, lastmoddate: datetimeFormatter(cameras[0]?.lastmoddate, 'YYYY-MM-DD HH:mm:ss') },
      { type: '3', name: localeMapping.softwares, amount: 2, active_devices: null },
    ];
  }, [cameras, devices, localeMapping]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={deviceTableColumns}
        idField="type"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        selectable
        clickable
      />
    </MKBox>
  );
};

CenterDistributionSection.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default CenterDistributionSection;
