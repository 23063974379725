import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { getDynamicTableRows } from 'api/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import DataTable from 'components/DataTable';
import MKBox from 'components/MaterialKit/MKBox';
import { datetimeFormatter } from 'utils/datetime';
import { getWard } from 'api/alpha/wards';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';

const AVWardIssueReportsListViewSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['history'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');

  const [reports, setReports] = useState([]);
  const [ward, setWard] = useState(null);

  const wardIssueReportTableColumns = useMemo(() => [
    { field: 'details', label: 'Details' },
    { field: 'device_physical_id', label: 'Device Number' },
    { field: 'camera_id', label: 'Camera ID' },
    { field: 'guest_id', label: 'Guest ID' },
    { field: 'guest_info', label: 'Guest Info' },
    { field: 'accident_time', label: 'Accident Time' },
    { field: 'false_alarm', label: 'False Alarm' },
    { field: 'createddate', label: 'Submitted at' },
    { field: 'reported_by', label: 'Person Incharge' },
  ], []);

  const fetchReportsFromApi = useCallback((attributes) => {
    getDynamicTableRows('3ffd1633-4903-40f5-8520-f898e57d6c3b')
      .then(({ data }) => {
        const tableRows = data.map((row) => {
          const dynObj = getDynamicData(row, attributes);
          dynObj.createddate = dynObj.createddate ? 'N/A' : row.createddate;
          dynObj.false_alarm = dynObj.false_alarm ? 'Yes' : 'No';
          return dynObj;
        });
        setReports(tableRows);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('err', err);
      });
  }, []);

  const fetchWard = useCallback(() => {
    if (!wardId) return Promise.resolve();
    return getWard(wardId, { $expand: 'center_id/service_provider_id' })
      .then(({ data }) => {
        setWard(data);
      });
  }, [wardId]);

  const fetchCollectionDefinition = useCallback(() => {
    return getCollectionDefinition('3ffd1633-4903-40f5-8520-f898e57d6c3b', { $expand: 'attributes' })
      .then(({ data }) => {
        return data.attributes;
      });
  }, []);

  useEffect(() => {
    fetchCollectionDefinition()
      .then((attributes) => fetchReportsFromApi(attributes))
      .then(() => fetchWard());
  }, [fetchCollectionDefinition, fetchReportsFromApi, fetchWard]);

  const rowData = useMemo(() => {
    // TODO - Implement the logic to map the data to the columns / Filter by WARD ID got from location.state.info.ward_id

    if (!reports) return [];
    return reports.map((row) => {
      const bod = {
        ...row,
      };
      bod.createddate = datetimeFormatter(row.createddate, 'YYYY-MM-DD HH:mm:ss');
      bod.accident_time = datetimeFormatter(Number(row.accident_time), 'YYYY-MM-DD HH:mm:ss');
      return bod;
    }).filter((row) => row.ward_id === wardId);
  }, [reports, wardId]);

  return (
    <MKBox my={2}>
      <AVBreadcrumb startingNode={localeMapping.history} items={[ward?.center_id?.service_provider_id?.name, ward?.center_id?.name, ward?.name]} />
      <DataTable
        data={rowData}
        columns={wardIssueReportTableColumns}
        idField="ward_id"
      />
    </MKBox>
  );
};

export default AVWardIssueReportsListViewSection;
