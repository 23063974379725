import PropTypes from 'prop-types';
import MuiIcon from '@mui/material/Icon';

const getBaseClassName = (type) => {
  switch (type) {
    case 'filled':
      return 'material-icons';
    case 'outlined':
      return 'material-icons-outlined';
    case 'rounded':
      return 'material-icons-rounded';
    case 'two_tone':
      return 'material-icons-two-tone';
    case 'sharp':
      return 'material-icons-sharp';
    default:
      return 'material-icons';
  }
};

const Icon = ({ name, size, color, type, ...props }) => {
  return (
    <MuiIcon
      color={color}
      fontSize={size}
      baseClassName={getBaseClassName(type)}
      sx={{
        color,
        fontSize: size,
      }}
      {...props}
    >
      {name}
    </MuiIcon>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf(['filled', 'outlined', 'rounded', 'two_tone', 'sharp']),
};

Icon.defaultProps = {
  name: 'help',
  size: '2rem',
  color: 'dark',
  type: 'filled',
};

export default Icon;
