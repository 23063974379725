import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getWard } from 'api/alpha/wards';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getLocaleMap } from 'utils/locales';
import WardOverviewDeviceList from './deviceWardOverviewList';

const WardDeviceOverviewWithList = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'devices_count'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');
  const providerName = searchParams.get('providerName') || 'Unknown Provider';
  const centerName = searchParams.get('centerName') || 'Unknown Center';
  const [ward, setWard] = useState(null);
  const { setAuth } = useAuth();

  const fetchWardFromApi = useCallback(() => {
    const requestParams = {};
    return wardId && getWard(wardId, requestParams)
      .then(({ data }) => {
        setWard(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [wardId, setAuth]);

  useEffect(() => {
    fetchWardFromApi();
  }, [fetchWardFromApi]);

  return (
    <MKBox>
      <AVBreadcrumb items={[providerName, centerName, ward?.name, localeMapping.devices]} showBackButton />
      <MKTypography variant="body1" fontSize="16px" color="black">
        {localeMapping.devices_count}
        {' '}
        {ward?.av_devices?.length || 0}
      </MKTypography>
      <WardOverviewDeviceList ward={ward} />
    </MKBox>
  );
};

export default WardDeviceOverviewWithList;
