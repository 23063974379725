import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';
import { getAIScripts, getWatchdogScripts } from 'api/alpha/scripts';
import { getLocaleMap } from 'utils/locales';

const SoftwareCenterDistributionSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['watchdog_scripts', 'ai_scripts'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const softwareTableColumns = useMemo(() => [
    { field: 'name', label: 'Name' },
    { field: 'amount', label: 'Amount' },
    { field: 'lastmoddate', label: 'Last Modified' },
  ], []);

  const [aiSoftwares, setAISoftwares] = useState([]);
  const [watchdogSoftwares, setWatchdogSoftwares] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchAISoftwaresFromApi = useCallback(() => {
    const requestParams = { $orderby: 'lastmoddate desc' };
    return getAIScripts(requestParams)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchWatchdogSoftwaresFromApi = useCallback(() => {
    const requestParams = { $orderby: 'lastmoddate desc' };
    return getWatchdogScripts(requestParams)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const onPressEdit = useCallback((type) => {
    if (type === '1') {
      navigate('/devices/softwares/ai/list?edit=true');
    } else if (type === '2') {
      navigate('/devices/softwares/watchdog/list?edit=true');
    }
  }, [navigate]);

  useEffect(() => {
    Promise.all([
      fetchAISoftwaresFromApi(),
      fetchWatchdogSoftwaresFromApi(),
    ]).then(([aiData, watchdogData]) => {
      setAISoftwares(aiData);
      setWatchdogSoftwares(watchdogData);
    });
  }, [fetchAISoftwaresFromApi, fetchWatchdogSoftwaresFromApi]);

  const rowData = useMemo(() => {
    return [
      { type: '1', name: localeMapping.ai_scripts, amount: aiSoftwares?.length, lastmoddate: datetimeFormatter(aiSoftwares[0]?.lastmoddate, 'YYYY-MM-DD HH:mm:ss') },
      { type: '2', name: localeMapping.watchdog_scripts, amount: watchdogSoftwares?.length, lastmoddate: datetimeFormatter(watchdogSoftwares[0]?.lastmoddate, 'YYYY-MM-DD HH:mm:ss') },
    ];
  }, [watchdogSoftwares, aiSoftwares, localeMapping]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={softwareTableColumns}
        idField="type"
        onPressEdit={onPressEdit}
        selectable
        clickable
      />
    </MKBox>
  );
};

SoftwareCenterDistributionSection.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default SoftwareCenterDistributionSection;
