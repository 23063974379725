import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { createDevice } from 'api/alpha/devices';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getServiceProviders } from 'api/alpha/service_providers';
import { getCenters } from 'api/alpha/centers';
import { getAIScripts, getWatchdogScripts } from 'api/alpha/scripts';
import { getWards } from 'api/alpha/wards';
import { getLocaleMap } from 'utils/locales';
import DeviceForm from './deviceForm';

const DeviceCreateSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'centers'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hierarchy, setHierarchy] = useState({});
  const [aiScripts, setAIScripts] = useState([]);
  const [watchdogScripts, setWatchdogScripts] = useState([]);
  const from = searchParams.get('from');

  const prepareHierarchy = useCallback(() => {
    const promises = [getServiceProviders(), getCenters(), getWards(), getAIScripts(), getWatchdogScripts()];
    return Promise.all(promises);
  }, []);

  useEffect(() => {
    prepareHierarchy()
      .then((data) => {
        const [serviceProviders, centers, wards, aiScriptsData, watchdogScriptsData] = data;
        setHierarchy({
          serviceProviders: serviceProviders.data,
          centers: centers.data,
          wards: wards.data,
        });
        setAIScripts(aiScriptsData.data);
        setWatchdogScripts(watchdogScriptsData.data);
      });
  }, [prepareHierarchy]);

  const createDeviceToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      ...values,
      fall_detection_script: values.fall_detection_script_id,
      watchdog_script: values.watchdog_script_id,
    };

    delete payload.service_provider;
    delete payload.center;
    delete payload.patient_id;

    return createDevice(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate]);

  const aiScriptsOptions = useMemo(() => aiScripts.map((script) => ({
    value: script.fall_detection_script_id,
    label: script.name,
  })), [aiScripts]);

  const watchdogScriptsOptions = useMemo(() => watchdogScripts.map((script) => ({
    value: script.watchdog_script_id,
    label: script.name,
  })), [watchdogScripts]);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.centers]}
        showBackButton
        backButtonPath={from === 'center_distribution' ? '/devices/' : '/devices/list'}
      />
      <DeviceForm onSave={createDeviceToApi} hierarchy={hierarchy} aiScriptsOptions={aiScriptsOptions} watchdogScriptsOptions={watchdogScriptsOptions} />
    </MKBox>
  );
};

export default DeviceCreateSection;
