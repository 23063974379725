import api from 'api';

export const getCollectionDefinitions = (params) => {
  return api.get('/collection_definitions', params, 'bearer');
};

export const getCollectionDefinition = (id, params) => {
  return api.get(`/collection_definitions/${id}`, params, 'bearer');
};

export const updateCollectionDefinition = (id, body) => {
  return api.put(`/collection_definitions/${id}`, body, 'bearer');
};

export const getAttributes = (params) => {
  return api.get('/attributes', params, 'bearer');
};

export const getAttribute = (id, params) => {
  return api.get(`/attributes/${id}`, params, 'bearer');
};

export const updateAttribute = (id, body) => {
  return api.put(`/attributes/${id}`, body, 'bearer');
};

export const createAttribute = (body) => {
  return api.post('/attributes', body, 'bearer');
};

export const deleteAttribute = (id) => {
  return api.delete(`/attributes/${id}`, 'bearer');
};

export const createCollectionRouting = (body) => {
  return api.post('/create_collection_routing', body, 'bearer');
};
