import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import colors from 'assets/theme/base/colors';
import 'react-quill/dist/quill.snow.css';
import './custom-editor.css';

const RichTextEditor = ({ value, onChange, label, readOnly, placeholder, maxLength, ...props }) => {
  const [textLength, setTextLength] = useState(0);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const textContent = (ref.current.editor?.getText() || '').replace(/\n$/, '');
      setTextLength(textContent.length);
    }
  }, [value]);

  return (
    <MKBox sx={{ position: 'relative' }}>
      <ReactQuill
        ref={ref}
        theme="snow"
        value={value}
        defaultValue=""
        onChange={onChange}
        readOnly={readOnly}
        placeholder={placeholder}
        modules={{
          toolbar: [
            [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', { color: [] }, { background: [] }],
            ['blockquote', 'code', { align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link'],
            ['clean'],
          ],
        }}
        formats={[
          'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'color', 'background',
          'blockquote', 'code', 'align',
          'list', 'bullet', 'indent',
          'link',
        ]}
        {...props}
      />
      {maxLength > 0 && maxLength < Infinity && (
        <MKTypography
          variant="caption"
          color={textLength >= maxLength ? 'error' : 'secondary'}
          sx={{ position: 'absolute', bottom: 4, right: 8 }}
        >
          {`${textLength}/${maxLength}`}
        </MKTypography>
      )}
      <fieldset
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: '0 8px',
          inset: '-5px 0 0',
          borderRadius: '0.375rem',
          border: `1px solid ${colors.inputBorderColor}`,
        }}
      >
        {!!label && (
          <legend>
            <MKTypography component="legend" variant="caption" sx={{ px: '5px' }}>{label}</MKTypography>
          </legend>
        )}
      </fieldset>
    </MKBox>
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

RichTextEditor.defaultProps = {
  value: '',
  onChange: () => {},
  label: null,
  readOnly: false,
  placeholder: null,
  maxLength: Infinity,
};

export default RichTextEditor;
