import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useSearchParams } from 'react-router-dom';
import { getSignals } from 'api/signals';
import { getLocaleMap } from 'utils/locales';
import DataTable from 'components/DataTable';

const SignalListViewSection = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_signal_id', 'labels_signal_name', 'labels_sequence', 'labels_signals'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const signalTableColumns = useMemo(() => [
    {
      field: 'signal_id',
      label: lm.labels_signal_id,
    },
    { field: 'signal_name', label: lm.labels_signal_name },
    { field: 'sequence', label: lm.labels_sequence },
  ], [lm.labels_sequence, lm.labels_signal_id, lm.labels_signal_name]);

  const [signals, setSignals] = useState([]);
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const businessLogicId = searchParams.get('business_logic_id');

  const fetchSignalsFromApi = useCallback(() => {
    const params = {
      business_logic: businessLogicId,
      $orderby: 'sequence',
    };
    return businessLogicId && getSignals(params)
      .then(({ data }) => {
        setSignals(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, setAuth]);

  useEffect(() => {
    fetchSignalsFromApi();
  }, [fetchSignalsFromApi]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">{lm.labels_signals}</MKTypography>
      </MKBox>
      <DataTable
        data={signals}
        columns={signalTableColumns}
        idField="signal_id"
      />
    </MKBox>
  );
};

export default SignalListViewSection;
