import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';

import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from '@vis.gl/react-google-maps';

const MapComponent = ({ longitude, latitude, google_map_api_key, google_map_id, ...props }) => {
  const pos = { lng: parseFloat(longitude), lat: parseFloat(latitude) };
  if (pos && google_map_api_key && google_map_id) {
    return (
      <APIProvider apiKey={google_map_api_key}>
        <MKBox style={{ height: '100%', width: '100%' }}>
          <Map zoom={12} center={pos} mapId={google_map_id}>
            <AdvancedMarker position={pos}>
              <Pin />
            </AdvancedMarker>
          </Map>
        </MKBox>
      </APIProvider>
    );
  }
  return (
    <MKBox style={{ height: '100%', width: '100%' }} display="flex" justifyContent="center" alignItems="center" flexDirection="column" p="2">
      <MKBox>
        <MKTypography variant="body1" color="error">
          Error during map load. Check console for more details.
        </MKTypography>
      </MKBox>

      { (!google_map_api_key || !google_map_id || !longitude || !latitude) && (
      <MKBox>
        <MKTypography variant="body2" color="error">
          Please provide all required properties:
        </MKTypography>
        <MKTypography variant="body2" color="error">
          (google_map_api_key, google_map_id, longitude, latitude)
        </MKTypography>
      </MKBox>
      ) }
    </MKBox>
  );
};

MapComponent.propTypes = {
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  google_map_api_key: PropTypes.string,
  google_map_id: PropTypes.string,
};

export default MapComponent;
