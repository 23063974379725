import MKBox from 'components/MaterialKit/MKBox';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import DataTable from 'components/DataTable';
import { getPatients } from 'api/alpha/patients';
import { getWard } from 'api/alpha/wards';
import { datetimeFormatter } from 'utils/datetime';

const AVWardDetailsPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [patients, setPatients] = useState(null);
  const [ward, setWard] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const wardId = searchParams.get('ward_id');

  const fetchPatientsFromApi = useCallback(() => {
    if (!wardId) {
      return Promise.resolve();
    }
    return getPatients({
      'ward[eq]': `${wardId}`,
      '$expand': 'ward/center_id',
      '$orderBy': `${sortBy} ${sortDirection}`,
    })
      .then(({ data }) => {
        setPatients(data);
      });
  }, [sortBy, sortDirection, wardId]);

  const fetchWardfromApi = useCallback(() => {
    return getWard(wardId)
      .then(({ data }) => {
        setWard(data);
      });
  }, [wardId]);

  useEffect(() => {
    fetchPatientsFromApi();
    if (wardId && !ward) {
      fetchWardfromApi();
    }
  }, [fetchPatientsFromApi, fetchWardfromApi, ward, wardId]);

  const linkFormatter = useCallback((row) => {
    return (
      <IconButton
        onClick={() => navigate(row)}
      >
        <ChevronRight sx={{ color: '#0B7EDC' }} />
      </IconButton>
    );
  }, [navigate]);

  const columns = useMemo(() => [
    { field: 'name', label: 'Patient Name', sortable: true },
    { field: 'patient_identifier', label: 'Guest ID', sortable: true },
    { field: 'center', label: 'Center' },
    { field: 'lastmoddate', label: 'Last Updated At', sortable: true, formatter: datetimeFormatter },
    { field: 'link', label: 'Link', formatter: linkFormatter },
  ], [linkFormatter]);

  const onClickSort = useCallback((colName) => {
    const patientTableColumn = columns.find((col) => col.field === colName);
    if (patientTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [columns]);

  const rowData = useMemo(() => {
    if (!patients) {
      return [];
    }
    return patients.map((patient) => {
      const linkTo = `/users/ward-details/patient?wardId=${patient.ward.ward_id}&patient_id=${patient.patient_id}`;
      const body = {
        name: patient.name,
        patient_identifier: patient.patient_identifier,
        patient_id: patient.patient_id,
        ward_id: patient.ward,
        center: patient.ward.center_id.name,
        lastmoddate: patient.lastmoddate,
        link: linkTo,
      };
      return body;
    });
  }, [patients]);

  if (!wardId || !ward) {
    return null;
  }

  return (
    <MKBox>
      <AVBreadcrumb startingNode={`${ward.name}`} showBackButton />
      <DataTable
        columns={columns}
        data={rowData}
        onSort={onClickSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    </MKBox>
  );
};

export default AVWardDetailsPage;
