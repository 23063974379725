import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { datetimeFormatter } from 'utils/datetime';
import { deleteDevice, getDevices } from 'api/alpha/devices';
import { useSearchParams } from 'react-router-dom';

const WardOverviewDeviceList = ({ ward }) => {
  const tableColumns = useMemo(() => [
    { field: 'device_physical_id', label: 'Device ID', sortable: true },
    { field: 'device_number', label: 'Device Number', sortable: true },
    { field: 'patient_id', label: 'Patient ID', sortable: true },
    { field: 'ward', label: 'Ward' },
    { field: 'lastmoddate', label: 'Last Modified', sortable: true },
  ], []);

  const [sortBy, setSortBy] = useState('device_physical_id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');

  const [devices, setDevices] = useState([]);
  const { setAuth } = useAuth();

  const fetchDevicesFromApi = useCallback(() => {
    const requestParams = {};
    if (wardId) {
      requestParams.ward_id = wardId;
      requestParams.$expand = 'ward_id';
      requestParams.$orderBy = sortBy ? `${sortBy} ${sortDirection}` : null;
    }
    return getDevices(requestParams)
      .then(({ data }) => {
        setDevices(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, sortBy, sortDirection, wardId]);

  const onPressDelete = useCallback((id) => {
    return deleteDevice(id)
      .then(() => {
        return fetchDevicesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchDevicesFromApi, setAuth]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = tableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [tableColumns]);

  useEffect(() => {
    fetchDevicesFromApi();
  }, [fetchDevicesFromApi]);

  const rowData = useMemo(() => {
    if (!devices) return [];
    return devices.map((row) => {
      return {
        ...row,
        ward: row?.ward_id?.name,
        lastmoddate: datetimeFormatter(row.lastmoddate),
      };
    });
  }, [devices]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={tableColumns}
        idField="av_device_id"
        onPressDelete={onPressDelete}
        onSort={onClickSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        selectable
        clickable
      />
    </MKBox>
  );
};

WardOverviewDeviceList.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default WardOverviewDeviceList;
