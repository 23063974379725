import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getHandlerParameters, deleteHandlerParameter } from 'api/handler_parameters';
import { getLocaleMap } from 'utils/locales';
import DataTable from 'components/DataTable';

const HandlerParameterListViewSection = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_parameters', 'labels_parameter_id', 'labels_alias', 'labels_parameter_name'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const handlerParameterTableColumns = useMemo(() => [
    {
      field: 'handler_parameter_id',
      label: lm.labels_parameter_id,
    },
    {
      field: 'handler_parameter_name',
      label: lm.labels_alias,
    },
    {
      field: 'parameter_name',
      label: lm.labels_parameter_name,
    },
  ], [lm.labels_alias, lm.labels_parameter_id, lm.labels_parameter_name]);

  const [handlerParameters, setHandlerParameters] = useState([]);
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const businessLogicId = searchParams.get('business_logic_id');
  const handlerId = searchParams.get('handler_id');

  const fetchHandlerParametersFromApi = useCallback(() => {
    const params = {
      'handler/business_logic': businessLogicId,
      '$expand': 'parameter',
    };
    return businessLogicId && getHandlerParameters(params)
      .then(({ data }) => {
        setHandlerParameters(data.map((handlerParam) => {
          const formattedHandlerParam = { ...handlerParam };
          formattedHandlerParam.parameter_name = handlerParam?.parameter?.parameter_name;
          return formattedHandlerParam;
        }) || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, setAuth]);

  const onPressAdd = useCallback(() => {
    navigate(`/create_parameter?handler_id=${handlerId}`);
  }, [navigate, handlerId]);

  const onPressEdit = useCallback((handlerParameterId) => {
    navigate(`/parameter?handler_parameter_id=${handlerParameterId}&edit=true`);
  }, [navigate]);

  const onPressDelete = useCallback((signalId) => {
    return deleteHandlerParameter(signalId)
      .then(() => {
        return fetchHandlerParametersFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchHandlerParametersFromApi, setAuth]);

  useEffect(() => {
    fetchHandlerParametersFromApi();
  }, [fetchHandlerParametersFromApi]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">{lm.labels_parameters}</MKTypography>
      </MKBox>
      <DataTable
        data={handlerParameters}
        columns={handlerParameterTableColumns}
        idField="handler_parameter_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        selectable
      />
    </MKBox>
  );
};

export default HandlerParameterListViewSection;
