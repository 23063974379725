import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import AccordionItem from './accordionItem';

const Accordion = ({ visibleNodes, hiddenNodes, itemProps, allowMultipleOpen, ...props }) => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const handleToggle = (index) => {
    if (allowMultipleOpen) {
      const isOpen = openIndexes.includes(index);
      setOpenIndexes(isOpen ? openIndexes.filter((i) => i !== index) : [...openIndexes, index]);
    } else {
      setOpenIndexes((prevIndexes) => {
        // If the clicked item is already open, close it
        const isOpen = prevIndexes.includes(index);
        return isOpen ? [] : [index];
      });
    }
  };

  return (
    <MKBox
      sx={{ ...props }}
    >
      {(visibleNodes || []).map((visibleNode, i) => {
        return (
          <AccordionItem
            key={i}
            index={i}
            isOpen={openIndexes.includes(i)}
            onToggle={handleToggle}
            visibleNode={visibleNode}
            hiddenNode={hiddenNodes[i] || null}
            itemProps={itemProps}
          />
        );
      })}
    </MKBox>
  );
};

Accordion.propTypes = {
  visibleNodes: PropTypes.arrayOf(PropTypes.string),
  hiddenNodes: PropTypes.arrayOf(PropTypes.string),
  itemProps: PropTypes.object,
  allowMultipleOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  visibleNodes: [],
  hiddenNodes: [],
  itemProps: {},
  allowMultipleOpen: false,
};

export default Accordion;
