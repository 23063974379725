import api from 'api';
import toLower from 'lodash/toLower';

export const getData = (endpoint, params, authType) => {
  return api.get(endpoint, params, authType);
};

export const getDatum = (endpoint, id, params, authType) => {
  return api.get(`${endpoint}/${id}`, params, authType);
};

export const createDatum = (endpoint, body, authType) => {
  return api.post(endpoint, body, authType);
};

export const updateDatum = (endpoint, id, body, authType) => {
  return api.put(`${endpoint}/${id}`, body, authType);
};

export const deleteDatum = (endpoint, id, authType) => {
  return api.delete(`${endpoint}/${id}`, authType);
};

export const sendApiRequest = (endpoint, httpMethod = 'get', paramsOrBody, authType) => {
  switch (toLower(httpMethod)) {
    case 'get':
      return api.get(endpoint, paramsOrBody, authType);
    case 'put':
      return api.put(endpoint, paramsOrBody, authType);
    case 'post':
      return api.post(endpoint, paramsOrBody, authType);
    case 'delete':
      return api.delete(endpoint, authType);
    default:
      return api.get(endpoint, paramsOrBody, authType);
  }
};
