import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { createCamera } from 'api/alpha/cameras';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getDevices } from 'api/alpha/devices';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getPatients } from 'api/alpha/patients';
import { getLocaleMap } from 'utils/locales';
import CameraForm from './cameraForm';

const CameraCreateSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'cameras', 'create'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [patients, setPatients] = useState([]);

  const createCameraToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      ...values,
      active: true,
    };

    return createCamera(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate]);

  const fetchDevicesFromApi = useCallback(() => {
    const requestParams = { $orderBy: 'device_physical_id asc' };
    return getDevices(requestParams)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchPatientsFromApi = useCallback(() => {
    const requestParams = { $orderBy: 'name asc' };
    return getPatients(requestParams)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    Promise.all([
      fetchDevicesFromApi(),
      fetchPatientsFromApi(),
    ]).then(([devicesData, patientsData]) => {
      setDevices(devicesData);
      setPatients(patientsData);
    });
  }, [fetchDevicesFromApi, fetchPatientsFromApi]);

  const deviceOptions = devices.map((device) => ({
    value: device.av_device_id,
    label: device.device_physical_id,
  }));

  const patientOptions = patients.map((patient) => ({
    value: patient.patient_id,
    label: patient.name,
  }));

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.cameras, localeMapping.create]}
        showBackButton
        backButtonPath="/devices/cameras/list"
      />
      <CameraForm onSave={createCameraToApi} devices={deviceOptions} patients={patientOptions} />
    </MKBox>
  );
};

export default CameraCreateSection;
