import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { createHandler } from 'api/handlers';
import EditHandlerForm from 'sections/HandlerEdit/EditHandlerForm';
import { createHandlerSignal } from 'api/handler_signals';
import { getLocaleMap } from 'utils/locales';

const HandlerCreateSection = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_create_handler'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const businessLogicId = searchParams.get('business_logic_id');

  const createHandlerToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      handler_name: values.handler_name,
      handler_type: values.handler_type,
      handler_sub_type: values.handler_sub_type ? values.handler_sub_type : null,
      sync: values.sync,
      delay_time: values.delay_time ? values.delay_time : 0,
      function: values.function ? values.function : null,
      business_logic: businessLogicId,
      data: values.data ? values.data : null,
      expression: values.expression ? values.expression : null,
    };
    return createHandler(payload)
      .then((obj) => {
        if (obj && obj.status === 201) {
          Promise.all(values.selected_signals.map((signalId) => {
            const handlerSignalBody = {
              signal_type: 'in',
              handler: obj.data.handler_id,
              signal: signalId,
            };
            return createHandlerSignal(handlerSignalBody);
          })).then((promises) => {
            if (promises.every((promise) => promise && promise.status === 201)) {
              navigate(-1);
            } else {
              setFieldError('form', 'Fail to create handler_signals');
            }
          });
        }
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [businessLogicId, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: '10px' }}>
          {lm.labels_create_handler}
        </h4>
        <EditHandlerForm
          onSave={createHandlerToApi}
        />
      </Container>
    </MKBox>
  );
};

export default HandlerCreateSection;
