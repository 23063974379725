import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import getStompClient from 'socket/stompClient';
import { useCallback, useEffect, useState } from 'react';
import { getDevices } from 'api/alpha/devices';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getCameras } from 'api/alpha/cameras';
import MKTypography from 'components/MaterialKit/MKTypography';
import moment from 'moment';

const DeviceReportSection = ({ ...props }) => {
  const [devices, setDevices] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [dataCounter1, setDataCounter1] = useState(null);
  const [dataCounter2, setDataCounter2] = useState(null);
  const { setAuth } = useAuth();

  const fetchDevicesFromApi = useCallback(() => {
    return getDevices({ $expand: 'cameras', $orderby: 'device_number' })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchCamerasFromApi = useCallback(() => {
    return getCameras({ $orderby: 'camera_identifier' })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    Promise.all([
      fetchDevicesFromApi(),
      fetchCamerasFromApi(),
    ]).then(([devicesData, camerasData]) => {
      setDevices(devicesData);
      setCameras(camerasData);
    });
  }, [fetchCamerasFromApi, fetchDevicesFromApi]);

  const addDataCounterListener = (stompClient, index) => {
    if (stompClient && stompClient.connected) {
      stompClient.subscribe('/data_counter', (res) => {
        const counterArray = JSON.parse(res.body);
        let setDataCounter;
        if (index === 1) {
          setDataCounter = setDataCounter1;
        } else {
          setDataCounter = setDataCounter2;
        }
        setDataCounter((counterArray || []).map((counter) => {
          const timestamp = Object.keys(counter)[0];
          const count = counter[timestamp];
          return <MKBox>{`${moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}: ${count}`}</MKBox>;
        }));
      });
    }
  };

  const connectWebsocket = useCallback(() => {
    const stompClient1 = getStompClient('https://portal.alphavision.tech/alpha-vision-in-websocket-server/ws');
    stompClient1.onConnect = (frame) => {
      if (stompClient1.connected) {
        console.log('connected debug websocket 1');
        addDataCounterListener(stompClient1, 1);
      }
    };
    stompClient1.onStompError = (err) => {
      console.error('stompClient err', err);
    };

    const stompClient2 = getStompClient('https://alpha-vision2.joyaethercloud.com/alpha-vision-in-websocket-server/ws');
    stompClient2.onConnect = (frame) => {
      if (stompClient2.connected) {
        console.log('connected debug websocket 2');
        addDataCounterListener(stompClient2, 2);
      }
    };
    stompClient2.onStompError = (err) => {
      console.error('stompClient err', err);
    };

    stompClient1.activate();
    stompClient2.activate();
  }, []);

  useEffect(() => {
    connectWebsocket();
  }, [connectWebsocket]);

  const activeHubs = devices.filter((device) => device.active);
  const activeCameras = cameras.filter((camera) => camera.active);

  return (
    <MKBox>
      <MKBox style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <MKTypography>
          {`Hubs: ${activeHubs.length} / ${devices.length}`}
        </MKTypography>
        <MKTypography fontSize={14} ml={1}>
          e.g. online hub count / total hub count
        </MKTypography>
      </MKBox>
      <MKTypography fontSize={14}>e.g. Device Number - Device Physical ID: Camera Identifirer</MKTypography>
      <MKBox>
        {activeHubs.map((hub) => {
          const cameraList = hub?.cameras.map((camera) => {
            return camera.camera_identifier;
          });
          return (
            <MKTypography>
              {`${hub.device_number} - ${hub.device_physical_id}: ${cameraList.join()}`}
            </MKTypography>
          );
        })}
      </MKBox>
      <MKBox style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '3rem' }}>
        <MKTypography>
          {`Cameras: ${activeCameras.length} / ${cameras.length}`}
        </MKTypography>
        <MKTypography fontSize={14} ml={1}>
          e.g. online camera count / total camera count
        </MKTypography>
      </MKBox>
      <MKTypography fontSize={14}>e.g. Camera Identifier</MKTypography>
      <MKBox>
        {activeCameras.map((camera) => {
          return (
            <MKTypography>
              {camera.camera_identifier}
            </MKTypography>
          );
        })}
      </MKBox>
      <MKBox mt={5}>
        <MKTypography>Websocket Server 1:</MKTypography>
        <MKTypography fontSize={14}>e.g. Date Time: Data with Skeleton Count / Total Data Count</MKTypography>
        {dataCounter1}
      </MKBox>
      <MKBox marginY={5}>
        <MKTypography>Websocket Server 2:</MKTypography>
        <MKTypography fontSize={14}>e.g. Date Time: Data with Skeleton Count / Total Data Count</MKTypography>
        {dataCounter2}
      </MKBox>
    </MKBox>
  );
};

DeviceReportSection.propTypes = {
};

export default DeviceReportSection;
