import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_us_portal from './locales/en-us/en.json';
import zh_hk_portal from './locales/zh-hk/zh.json';
import zh_cn_portal from './locales/zh-cn/zh-cn.json';

const storedLang = localStorage.getItem('i18nextLng');

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: storedLang,
    fallbackLng: 'zh-HK',

    resources: {
      'en-US': {
        Portal: en_us_portal,
      },
      'zh-HK': {
        Portal: zh_hk_portal,
      },
      'zh-CN': {
        Portal: zh_cn_portal,
      },
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
