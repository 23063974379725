import PropTypes from 'prop-types';
import { ImageWithLink } from 'components/ImageWithLink';

const ImageWithLinkSection = ({ src, width, height, to, href, alt, border_radius, object_fit, object_position, circle, editable, onPressEdit, ...props }) => {
  return (
    <ImageWithLink
      src={src}
      width={width}
      height={height}
      alt={alt}
      borderRadius={border_radius}
      objectFit={object_fit}
      objectPosition={object_position}
      circle={circle}
      to={to}
      href={href}
      {...props}
    />
  );
};

ImageWithLinkSection.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alt: PropTypes.string,
  border_radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  object_fit: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  object_position: PropTypes.string,
  circle: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default ImageWithLinkSection;
