import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { createBusinessLogic } from 'api/business_logics';
import { getLocaleMap } from 'utils/locales';
import EditBusinessLogicForm from 'sections/BusinessLogicEdit/EditBusinessLogicForm';

const BusinessLogicCreateSection = () => {
  const navigate = useNavigate();
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_create_business_logic'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const createBusinessLogicToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      business_logic_name: values.business_logic_name,
    };

    return createBusinessLogic(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: '10px' }}>
          {lm.labels_create_business_logic}
        </h4>
        <EditBusinessLogicForm
          onSave={createBusinessLogicToApi}
        />
      </Container>
    </MKBox>
  );
};

export default BusinessLogicCreateSection;
