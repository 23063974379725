import api from 'api';

export const getHandlerTypes = (params) => {
  return api.get('/handler_types', params, 'bearer');
};

export const getHandlerType = (id, params) => {
  return api.get(`/handler_types/${id}`, params, 'bearer');
};

export const getHandlerSubTypes = (params) => {
  return api.get('/handler_sub_types', params, 'bearer');
};

export const getHandlerSubType = (id, params) => {
  return api.get(`/handler_sub_types/${id}`, params, 'bearer');
};
