import api from 'api';

export const getLocales = (params) => {
  return api.get('/strings', params, 'bearer');
};

export const getLocale = (id, params) => {
  return api.get(`/strings/${id}`, params, 'bearer');
};

export const createLocale = (body) => {
  return api.post('/strings', body, 'bearer');
};

export const updateLocale = (id, body) => {
  return api.put(`/strings/${id}`, body, 'bearer');
};

export const createLanguageString = (body) => {
  return api.post('/language_strings', body, 'bearer');
};
