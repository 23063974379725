import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { deleteAIScript, getAIScripts } from 'api/alpha/scripts';

const AIScriptsListViewSection = () => {
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();

  const columns = useMemo(() => [
    { field: 'fall_detection_script_id', label: 'ID', sortable: true },
    { field: 'name', label: 'Name', sortable: true },
    { field: 'major_version', label: 'Major', sortable: true },
    { field: 'minor_version', label: 'Minor', sortable: true },
    { field: 'prefix', label: 'Prefix', sortable: true },
    { field: 'description', label: 'Description', sortable: true },
    { field: 'file', label: 'File', sortable: true },
    { field: 'lastmoddate', label: 'Last Modified Date', sortable: true },
  ], []);

  const [scripts, setScripts] = useState([]);
  const fetchScriptsFromApi = useCallback(() => {
    const requestParams = {
      $expand: 'file',
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}) };
    return getAIScripts(requestParams)
      .then(({ data }) => {
        setScripts(data || []);
      });
  }, [sortBy, sortDirection]);

  useEffect(() => {
    fetchScriptsFromApi();
  }, [fetchScriptsFromApi]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = columns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [columns]);

  const onPressDelete = useCallback((selectedId) => {
    deleteAIScript(selectedId)
      .then(() => {
        fetchScriptsFromApi();
      });
  }, [fetchScriptsFromApi]);

  const onPressAdd = useCallback(() => {
    navigate('/devices/softwares/ai/create');
  }, [navigate]);

  const downloadFile = useCallback((fileUrl) => {
    window.open(fileUrl, '_blank');
  }, []);

  const rowData = useMemo(() => {
    if (!scripts) return [];
    return scripts.map((script) => {
      return {
        ...script,
        file: (
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              downloadFile(script?.file?.file_url);
            }}
          >
            {script?.file?.file_name.length > 20
              ? `${script?.file?.file_name.substring(0, 20)}...`
              : script?.file?.file_name}
            <CloudDownloadIcon sx={{ ml: 1 }} />
          </Button>
        ),
        lastmoddate: datetimeFormatter(script?.lastmoddate, 'YYYY-MM-DD HH:mm:ss'),
      };
    }).filter(Boolean);
  }, [downloadFile, scripts]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={columns}
        idField="fall_detection_script_id"
        selectable
        clickable
        onPressDelete={onPressDelete}
        onPressAdd={onPressAdd}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
      />
    </MKBox>
  );
};

AIScriptsListViewSection.propTypes = {
};

export default AIScriptsListViewSection;
