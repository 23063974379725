import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { handleErrorResponse } from 'utils/general';
import { createAIScript } from 'api/alpha/scripts';
import { getLocaleMap } from 'utils/locales';
import FileForm from './fileForm';

const ScriptCreateSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'softwares', 'ai_scripts'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();

  const createScriptToApi = useCallback((values) => {
    const payload = {
      file: values.file,
      description: values.description,
      major_version: values.major_version,
      minor_version: values.minor_version,
      prefix: values.prefix,
    };

    return createAIScript(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  }, [navigate]);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.softwares, localeMapping.ai_scripts, localeMapping.create]}
        showBackButton
        backButtonPath="/devices/softwares/ai/list"
      />
      <FileForm onSave={createScriptToApi} />
    </MKBox>
  );
};

export default ScriptCreateSection;
