import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import DataTable from 'components/DataTable';
import { withLoading } from 'utils/hoc';
import { handleErrorResponse } from 'utils/general';
import { getPages, deletePage } from 'api/pages';
import { useAuth } from 'contexts/auth';
import { getLocaleMap } from 'utils/locales';

const PageSection = ({ setLoading }) => {
  const [pages, setPages] = useState([]);
  const [lm, setLm] = useState({});
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentListViewPage, setCurrentListViewPage] = useState(0);
  const [rowsPerListViewPage, setRowsPerListViewPage] = useState(10);
  const [sortBy, setSortBy] = useState('path');
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();
  const { appId } = useParams();
  const { setAuth } = useAuth();

  useEffect(() => {
    getLocaleMap(['labels_page_id', 'labels_path', 'labels_layout', 'author_button', 'user_button', 'labels_pages'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const fetchPagesFromApi = useCallback(() => {
    const pageParams = {
      app: appId,
      $expand: 'app,layout',
      $top: rowsPerListViewPage,
      $skip: currentListViewPage * rowsPerListViewPage,
      $orderBy: `${sortBy} ${sortDirection}`,
    };
    setLoading(true);
    return getPages(pageParams)
      .then(({ data, headers }) => {
        const contentRange = get(headers, 'content-range');
        const dataCount = Number(contentRange.split('/')[1]);
        setTotalPageCount(dataCount);
        setPages(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [appId, currentListViewPage, sortBy, sortDirection, rowsPerListViewPage, setLoading, setAuth]);

  const onPressAdd = useCallback(() => {
    navigate(`/app/${appId}/page`);
  }, [appId, navigate]);

  const onPressEdit = useCallback((pageId) => {
    navigate(`/app/${appId}/page/${pageId}`);
  }, [appId, navigate]);

  const onPressDelete = useCallback((pageId) => {
    setLoading(true);
    return deletePage(pageId)
      .then(() => {
        return fetchPagesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchPagesFromApi, setLoading, setAuth]);

  useEffect(() => {
    fetchPagesFromApi();
  }, [fetchPagesFromApi]);

  const pageTableColumns = useMemo(() => [
    {
      field: 'page_id',
      label: lm.labels_page_id,
      sortable: true,
    },
    {
      field: 'path',
      label: lm.labels_path,
      sortable: true,
    },
    {
      field: 'layout.name',
      label: lm.labels_layout,
      sortable: false,
    },
    {
      field: 'preview',
      sortable: false,
      renderContent: (page) => {
        const { app, path } = page;
        return (
          <MKBox display="flex" flexDirection="row">
            <MKBox mr={1}>
              <Button
                href={`${app.base_url}${path}?mode=author`}
                target="_self"
                variant="gradient"
                color="secondary"
                size="small"
                fontSize={12}
              >
                {lm.author_button}
              </Button>
            </MKBox>
            <MKBox>
              <Button
                href={`${app.base_url}${path}`}
                target="_self"
                variant="gradient"
                color="info"
                size="small"
                fontSize={12}
              >
                {lm.user_button}
              </Button>
            </MKBox>
          </MKBox>
        );
      },
    },
  ], [lm.author_button, lm.labels_layout, lm.labels_page_id, lm.labels_path, lm.user_button]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = pageTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [pageTableColumns]);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">{lm.labels_pages}</MKTypography>
      </MKBox>
      <DataTable
        data={pages}
        columns={pageTableColumns}
        idField="page_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        totalCount={totalPageCount}
        currentPage={currentListViewPage}
        onPageChange={setCurrentListViewPage}
        rowsPerPage={rowsPerListViewPage}
        onRowsPerPageChange={setRowsPerListViewPage}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
        selectable
      />
    </MKBox>
  );
};

PageSection.propTypes = {
  setLoading: PropTypes.func,
};

export default withLoading(PageSection);
