import api from 'api';

export const getApps = (params) => {
  return api.get('/apps', params, 'bearer');
};

export const getApp = (id, params) => {
  return api.get(`/apps/${id}`, params, 'bearer');
};

export const getPublicApps = (params) => {
  return api.get('/public/apps', params, 'bearer');
};

export const getPublicApp = (id, params) => {
  return api.get(`/public/apps/${id}`, params, 'bearer');
};

export const updateApp = (id, body) => {
  return api.put(`/apps/${id}`, body, 'bearer');
};

export const createApp = (body) => {
  return api.post('/apps', body, 'bearer');
};

export const deleteApp = (id) => {
  return api.delete(`/apps/${id}`, 'bearer');
};
