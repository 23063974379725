import api from 'api';

export const getHandlers = (params) => {
  return api.get('/handlers', params, 'bearer');
};

export const getHandler = (id, params) => {
  return api.get(`/handlers/${id}`, params, 'bearer');
};

export const updateHandler = (id, body) => {
  return api.put(`/handlers/${id}`, body, 'bearer');
};

export const createHandler = (body) => {
  return api.post('/handlers', body, 'bearer');
};

export const deleteHandler = (id) => {
  return api.delete(`/handlers/${id}`, 'bearer');
};
