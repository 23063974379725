import PropTypes from 'prop-types';
import HeaderNavbar from 'components/HeaderNavbar';
import { React, useState, useEffect, useCallback } from 'react';
import { getPublicSection } from 'api/sections';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const HeaderNavbarSection = ({ logo_src, logo_props, items, item_props, menu_font_color, extra_section, editable, onPressEdit, ...props }) => {
  const { auth, setAuth } = useAuth();

  const [visibleSection, setVisibleSection] = useState({});
  const [visibleSectionProps, setVisibleSectionProps] = useState({});

  const fetchSectionFromApi = useCallback((id, setSection) => {
    if (id === null) return;
    const sectionParams = {
      $expand: 'section_definition/collection_definition/attributes',
    };
    return getPublicSection(id, sectionParams)
      .then(({ data }) => {
        setSection(data);
      });
  }, []);

  useEffect(() => {
    if (extra_section) {
      fetchSectionFromApi(extra_section, setVisibleSection, !auth.user)
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [extra_section, fetchSectionFromApi, auth.user, setAuth]);

  useEffect(() => {
    fetchSectionProps(visibleSection, !auth.user)
      .then((sectionProps) => {
        setVisibleSectionProps({ ...sectionProps });
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [visibleSection, auth.user, setAuth]);

  const extraRenderSection = renderSectionComponent(visibleSection, visibleSectionProps, false, null)

  return (
    <HeaderNavbar
      logo_src={logo_src}
      logo_props={logo_props}
      items={items}
      item_props={item_props}
      menu_color={menu_font_color}
      extra_section={extraRenderSection}
      {...props}
    />
  );
};

HeaderNavbarSection.propTypes = {
  logo_src: PropTypes.string,
  logo_props: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  })),
  item_props: PropTypes.object,
  menu_font_color: PropTypes.string,
  extra_section: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

HeaderNavbarSection.defaultProps = {
  logo_src: null,
  logo_props: {},
  items: [],
  item_props: {},
  menu_font_color: null,
  extra_section: null,
};

export default HeaderNavbarSection;
