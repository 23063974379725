import api from 'api';

export const getSolutions = (params) => {
  return api.get('/solutions', params, 'bearer');
};

export const getSolution = (id, params) => {
  return api.get(`/solutions/${id}`, params, 'bearer');
};

export const createSolution = (body) => {
  return api.post('/solutions', body, 'bearer');
};

export const updateSolution = (id, body) => {
  return api.put(`/solutions/${id}`, body, 'bearer');
};
