import PropTypes from 'prop-types';
import SimpleFooter from 'components/SimpleFooter';

const SimpleFooterSection = ({ copyright, editable, onPressEdit, ...props }) => {
  return (
    <SimpleFooter copyright={copyright} {...props} />
  );
};

SimpleFooterSection.propTypes = {
  copyright: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default SimpleFooterSection;
