import Icon from 'components/Icon';
import PropTypes from 'prop-types';

const IconSection = ({ name, size, color, type, ...props }) => {
  return (
    <Icon
      name={name}
      size={size}
      color={color}
      type={type}
      {...props}
    />
  );
};

IconSection.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf(['filled', 'outlined', 'rounded', 'two_tone', 'sharp']),
};

export default IconSection;
