/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import get from 'lodash/get';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import { IconButton, Paper, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { usePython } from 'react-py';
import getStompClient from 'socket/stompClient';
import TwoWayAudioButton from 'sections/TwoWayAudioButton';
import Image from 'components/Image';
import WarningIcon from '@mui/icons-material/Warning';
import moment from 'moment';
import { getLocaleMap } from 'utils/locales';
import LivestreamDialog from './liveStreamDialog';

const presenceIcon = (presence) => {
  return (
    <MKBox display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ borderRadius: '50%', backgroundColor: presence ? '#FFFFFF' : '#000000', width: '10px', height: '10px' }} />
  );
};

const ReportIssuingDialog = ({ info, open, onClose, onIssueSolved, radarData }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['guest_info', 'guest_name', 'detected_camera', 'skeleton_replay', 'live_skeleton_video', 'send_alert', 'response', 'fall',
      'emergency_contact_name', 'emergency_contact_phone',
      'sos', 'heart_rate', 'presence', 'breath_rate'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [liveSkeletonStreamOpen, setLiveSkeletonStreamOpen] = useState(false);
  const [liveStreamOpen, setLiveStreamOpen] = useState(false);
  const [overallCameraInstance, setOverallCameraInstance] = useState(null);
  const { runPython, stdout, stderr, isLoading } = usePython();
  const [imgOutput, setImgOutput] = useState(null);
  const [websocketClient, setWebsocketClient] = useState();
  const [disconnectCameraInstance, setDisconnectCameraInstance] = useState();
  const [disconnectTalk, setDisconnectTalk] = useState(false);
  const [showLiveStream, setShowLiveStream] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => {
    setShowLiveStream(false);
    setDisconnectCameraInstance(true);
    onClose();
  };

  const addCameraMessageListener = (stompClient) => {
    if (stompClient && stompClient.connected) {
      const cameraId = info?.accident?.camera?.camera_id;
      stompClient.subscribe(`/skeleton/${cameraId}`, (res) => {
        const resBody = JSON.parse(res.body);
        const messageStr = get(resBody, 'message');
        const message = JSON.parse(messageStr);
        const skeleton_to_plot = get(message, 'skeleton_to_plot');
        console.log('run python');
        const plot = skeleton_to_plot.length > 0 ? 'True' : 'False';
        const plotTwo = skeleton_to_plot.length > 1 ? 'True' : 'False';
        const pointOne = plot ? skeleton_to_plot[0] : null;
        console.log(runPython(`
import numpy as np
import os
import base64
from io import BytesIO
import cv2
os.environ['MPLBACKEND'] = 'AGG'
import matplotlib.pyplot as plt
import matplotlib.image as mpimg

COCO_PAIR = [(0, 13), (1, 2), (1, 3), (3, 5), (2, 4), (4, 6), (13, 7), (13, 8),  # Body
              (7, 9), (8, 10), (9, 11), (10, 12)]
POINT_COLORS = [(0, 255, 255), (0, 191, 255), (0, 255, 102), (0, 77, 255), (0, 255, 0),  # Nose, LEye, REye, LEar, REar
                (77, 255, 255), (77, 255, 204), (77, 204, 255), (191, 255, 77), (77, 191, 255), (191, 255, 77),  # LShoulder, RShoulder, LElbow, RElbow, LWrist, RWrist
                (204, 77, 255), (77, 255, 204), (191, 77, 255), (77, 255, 191), (127, 77, 255), (77, 255, 127), (0, 255, 255)]  # LHip, RHip, LKnee, Rknee, LAnkle, RAnkle, Neck
LINE_COLORS = [(0, 215, 255), (0, 255, 204), (0, 134, 255), (0, 255, 50), (77, 255, 222),
                (77, 196, 255), (77, 135, 255), (191, 255, 77), (77, 255, 77), (77, 222, 255),
                (255, 156, 127), (0, 127, 255), (255, 127, 77), (0, 77, 255), (255, 77, 36)]

pts = None
frame = None

points_array = ${skeleton_to_plot}
    
# Window name in which image is displayed 
window_name = 'Image'
  
# Start coordinate, here (5, 5) 
# represents the top left corner of rectangle 
start_point = (5, 5) 
  
# Ending coordinate, here (220, 220) 
# represents the bottom right corner of rectangle 
end_point = (220, 220) 
  
# Blue color in BGR 
color = (255, 0, 0) 
  
# Line thickness of 2 px 
thickness = 2
  
# Using cv2.rectangle() method 
# Draw a rectangle with blue line borders of thickness of 2 px 
frame = np.zeros(shape=(384,384, 3), dtype=int)
#frame = cv2.rectangle(frame, start_point, end_point, color, thickness) 

l_pair = COCO_PAIR
p_color = POINT_COLORS
line_color = LINE_COLORS

part_line = {}

if(${plotTwo}):
  for point in points_array:
    if(${plot}):
      pts = np.array(point, dtype=np.float32)
      pts = np.concatenate((pts, np.expand_dims((pts[1, :] + pts[2, :]) / 2, 0)), axis=0)

    if(${plot}):
      for n in range(pts.shape[0]):
          if pts[n, 2] <= 0.05:
              continue
          cor_x, cor_y = int(pts[n, 0]), int(pts[n, 1])
          part_line[n] = (cor_x, cor_y)
          cv2.circle(frame, (cor_x, cor_y), 3, p_color[n], -1)

      for i, (start_p, end_p) in enumerate(l_pair):
          if start_p in part_line and end_p in part_line:
              start_xy = part_line[start_p]
              end_xy = part_line[end_p]
              cv2.line(frame, start_xy, end_xy, line_color[i], int(1*(pts[start_p, 2] + pts[end_p, 2]) + 1))
else:
  if(${plot}):
    pts = np.array(${pointOne}, dtype=np.float32)
    pts = np.concatenate((pts, np.expand_dims((pts[1, :] + pts[2, :]) / 2, 0)), axis=0)

  if(${plot}):
    for n in range(pts.shape[0]):
        if pts[n, 2] <= 0.05:
            continue
        cor_x, cor_y = int(pts[n, 0]), int(pts[n, 1])
        part_line[n] = (cor_x, cor_y)
        cv2.circle(frame, (cor_x, cor_y), 3, p_color[n], -1)

    for i, (start_p, end_p) in enumerate(l_pair):
        if start_p in part_line and end_p in part_line:
            start_xy = part_line[start_p]
            end_xy = part_line[end_p]
            cv2.line(frame, start_xy, end_xy, line_color[i], int(1*(pts[start_p, 2] + pts[end_p, 2]) + 1))

plt.axis('off')
plt.imshow(frame, interpolation='nearest')
buf = BytesIO()
plt.margins(0,0)
plt.savefig(buf, format='png', bbox_inches='tight', pad_inches = 0)
buf.seek(0)
# Encode to a base64 str
img = 'data:image/png;base64,' + \
base64.b64encode(buf.read()).decode('utf-8')
# Write to stdout
print(img)
plt.clf()
            `));
      });
    }
  };

  const connectCamera = () => {
    if (!websocketClient) {
      let runtimeWebsocketUrl = info?.accident?.device?.runtime_websocket_url;
      if (runtimeWebsocketUrl.startsWith('ws:')) {
        runtimeWebsocketUrl = `${runtimeWebsocketUrl.replace('ws:', 'http:')}/ws`;
      } else if (runtimeWebsocketUrl.startsWith('wss:')) {
        runtimeWebsocketUrl = `${runtimeWebsocketUrl.replace('wss:', 'https:')}/ws`;
      }
      const stompClient = getStompClient(runtimeWebsocketUrl);
      stompClient.onConnect = (frame) => {
        setWebsocketClient(stompClient);
        if (stompClient.connected) {
          console.log('connected camera websocket')
          addCameraMessageListener(stompClient);
        }
      };
      stompClient.onStompError = (err) => {
        console.error('stompClient err', err);
      };
      stompClient.activate();
    }
  };

  const handleOpenLiveSkeletonModal = () => {
    setLiveSkeletonStreamOpen(true);
    if (websocketClient && !websocketClient.active) {
      websocketClient.activate();
    }
    connectCamera();
  };

  const handleCloseLiveSkeletonModal = () => {
    setLiveSkeletonStreamOpen(false);
    setImgOutput(null);
    setShowLiveStream(false);
    websocketClient.deactivate();
  };

  const handleOpenLiveModal = () => {
    setLiveStreamOpen(true);
    connectCamera();
  };

  const handleCloseLiveModal = () => {
    setLiveStreamOpen(false);
  };

  useEffect(() => {
    setImgOutput(stdout);
  }, [stdout]);

  useEffect(() => {
    console.log(stderr);
  }, [stderr]);

  const handleDisconnectTalkInCloseSkeletonView = () => {
    setDisconnectTalk(true);
  };

  const handleShowLiveStream = () => {
    setShowLiveStream(!showLiveStream);
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <MKBox px={2} py={2} sx={{ backgroundColor: 'white' }}>
        <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <MKTypography variant="body1" fontSize="20px" fontWeight="bold" color="black">
            {`${info?.service_provider_name}/${info?.center_name}/${info?.ward_name}`}
          </MKTypography>
          <IconButton onClick={handleClose} sx={{ color: '#C5010A' }}>
            <CloseIcon />
          </IconButton>
        </MKBox>
        <MKBox mb={2}>
          {/* Accident Tags */}
          <MKBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <MKBox py={1} px={2} sx={{ backgroundColor: '#C5010A', borderRadius: '8px' }} mr={1}>
              <MKTypography variant="body1" fontSize="18px" fontWeight="bold" color="white">{localeMapping[info?.accident?.tag]}</MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        <Paper elevation={2}>
          <MKBox display="flex" justifyContent="space-between" width="40vw" p={2} sx={{ backgroundColor: '#F2F2F2' }}>
            {/* Ward Info */}
            <MKBox fontSize="16px">
              <MKTypography fontSize="inherit" fontWeight="bold" color="black">
                {`${localeMapping.detected_camera}: ${info?.accident?.camera?.camera_identifier}`}
              </MKTypography>
              <MKTypography fontSize="inherit" fontWeight="bold" color="black">
                {`${localeMapping.guest_name}: ${info?.accident?.patient?.name}`}
              </MKTypography>
              <MKTypography fontSize="inherit" fontWeight="bold" color="black">
                {`${localeMapping.guest_info}: ${info?.accident?.patient?.patient_info}`}
              </MKTypography>
              <MKBox display="flex" flexDirection="row" alignItems="center">
                <MKTypography fontSize="inherit" fontWeight="bold" color="black" sx={{ mr: 1 }}>{`${localeMapping.heart_rate}:`}</MKTypography>
                <MKTypography fontSize="inherit" fontWeight="bold" color="black" sx={{ mr: 1 }}>{radarData[info?.accident?.patient?.radar_id]?.heart_bpm || '-'}</MKTypography>
                {radarData[info?.accident?.patient?.radar_id]?.heart_bpm === 0 && <WarningIcon style={{ color: 'yellow' }} />}
              </MKBox>
              <MKBox display="flex" flexDirection="row" alignItems="center">
                <MKTypography fontSize="inherit" fontWeight="bold" color="black" sx={{ mr: 1 }}>{`${localeMapping.breath_rate}:`}</MKTypography>
                <MKTypography fontSize="inherit" fontWeight="bold" color="black" sx={{ mr: 1 }}>{radarData[info?.accident?.patient?.radar_id]?.breath_rate || '-'}</MKTypography>
                {radarData[info?.accident?.patient?.radar_id]?.breath_rate === 0 && <WarningIcon style={{ color: 'yellow' }} />}
              </MKBox>
              <MKBox display="flex" flexDirection="row" alignItems="center">
                <MKTypography fontSize="inherit" fontWeight="bold" color="black" sx={{ mr: 1 }}>{`${localeMapping.presence}:`}</MKTypography>
                <MKTypography fontSize="inherit" fontWeight="bold" color="black" sx={{ mr: 1 }}>{radarData[info?.accident?.patient?.radar_id]?.heart_bpm > 0 ? 'YES' : 'NO'}</MKTypography>
                {radarData[info?.accident?.patient?.radar_id]?.heart_bpm > 0 ? presenceIcon(true) : presenceIcon(false)}
              </MKBox>
              <MKTypography fontSize="inherit" fontWeight="bold" color="black">
                {`${localeMapping.emergency_contact_name}: ${info?.accident?.patient?.family_username?.display_name}`}
              </MKTypography>
              <MKTypography fontSize="inherit" fontWeight="bold" color="black">
                {`${localeMapping.emergency_contact_phone}: ${info?.accident?.patient?.family_username?.phone}`}
              </MKTypography>
            </MKBox>
            <MKBox color="#FFF" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <Button
                disabled={isLoading}
                variant="contained"
                size="medium"
                color="primary"
                fontSize="18px"
                onClick={handleOpenLiveSkeletonModal}
              >
                <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">{localeMapping.live_skeleton_video}</MKTypography>
              </Button>
              <TwoWayAudioButton
                handleShowLiveStream={handleShowLiveStream}
                camera={info?.accident?.camera}
                showLiveStream={showLiveStream}
                overallCameraInstance={overallCameraInstance}
                setOverallCameraInstance={setOverallCameraInstance}
                setDisconnectCameraInstance={setDisconnectCameraInstance}
              />
              {/* <Button
                variant="contained"
                size="medium"
                color="primary"
                fontSize="18px"
                onClick={handleStartLiveStream}
                sx={{
                  marginTop: '10px',
                }}
              >
                <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">Live Video</MKTypography>
              </Button> */}
            </MKBox>
          </MKBox>
        </Paper>
        <MKBox display="flex" justifyContent="right" mt={2}>
          <Stack spacing={2} direction="row">
            <TwoWayAudioButton
              camera={info?.accident?.camera}
              disconnectCameraInstance={disconnectCameraInstance}
              setDisconnectCameraInstance={setDisconnectCameraInstance}
              disconnectTalk={disconnectTalk}
              setDisconnectTalk={setDisconnectTalk}
              overallCameraInstance={overallCameraInstance}
              setOverallCameraInstance={setOverallCameraInstance}
            />
            <Button
              variant="contained"
              size="small"
              fontSize="18px"
              onClick={() => { navigate(`/users/notifications?ward_id=${info?.ward_id}&username=${info?.accident?.patient?.family_username?.username}`, { state: { info } }); }}
              sx={{ backgroundColor: '#088915' }}
            >
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">{`${localeMapping.send_alert} (WIP)`}</MKTypography>
            </Button>
            <Button
              variant="contained"
              size="small"
              fontSize="18px"
              onClick={onIssueSolved}
              endIcon={(
                <ChevronRightIcon fontSize="inherit" sx={{ color: '#FFF' }} />
            )}
            >
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">{localeMapping.response}</MKTypography>
            </Button>
            <Button
              variant="contained"
              size="small"
              fontSize="18px"
              onClick={() => navigate(`/skeleton_replay?alert_id=${info?.accident?.alert_id}`)}
              endIcon={(
                <ChevronRightIcon fontSize="inherit" sx={{ color: '#FFF' }} />
            )}
            >
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">{localeMapping.skeleton_replay}</MKTypography>
            </Button>
          </Stack>
        </MKBox>
        {liveSkeletonStreamOpen && (
          <LivestreamDialog
            open={liveSkeletonStreamOpen}
            ward={info}
            camera={info?.accident?.camera}
            onClose={() => {
              handleCloseLiveSkeletonModal();
              handleDisconnectTalkInCloseSkeletonView();
            }}
            imgOutput={imgOutput}
            overallCameraInstance={overallCameraInstance}
            setOverallCameraInstance={setOverallCameraInstance}
            setDisconnectCameraInstance={setDisconnectCameraInstance}
          />
        )}
        {/* {liveStreamOpen && (
          <LivestreamDialog
            open={liveStreamOpen}
            ward={info}
            camera={info?.accident?.camera}
            onClose={() => {
              handleCloseLiveModal();
              handleDisconnectTalkInCloseSkeletonView();
            }}
            imgOutput={null}
          />
        )} */}
        <Image id="liveAudio" src="" style={{ visibility: 'hidden', width: 0, height: 0 }} />
        <Image id="liveStream" src="" style={{ visibility: 'hidden', marginTop: 20 }} />
      </MKBox>
    </Dialog>
  );
};

ReportIssuingDialog.propTypes = {
  info: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onIssueSolved: PropTypes.func,
  radarData: PropTypes.object,
};

export default ReportIssuingDialog;
