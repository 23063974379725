import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import { getLocaleMap } from 'utils/locales';
import { useEffect, useState } from 'react';

const AppEditBarSection = ({ app, ...props }) => {
  const { display_name, major_version, minor_version } = app || {};
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['editable_wrappers_app_author_mode'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  return (
    <Container sx={{ p: 2 }}>
      <MKBox display="flex" {...props}>
        <MKBox mr={2}>
          <MKTypography variant="h4" fontWeight="regular">{lm.editable_wrappers_app_author_mode}</MKTypography>
        </MKBox>
        <MKBox>
          <MKTypography variant="h4">{display_name}</MKTypography>
          <MKTypography variant="body2">
            {major_version}
            {' - '}
            {minor_version}
          </MKTypography>
        </MKBox>
      </MKBox>
    </Container>
  );
};

AppEditBarSection.propTypes = {
  app: PropTypes.shape({
    display_name: PropTypes.string,
    major_version: PropTypes.string,
    minor_version: PropTypes.string,
  }),
};

AppEditBarSection.defaultProps = {
  app: {},
};

export default AppEditBarSection;
