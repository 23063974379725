import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getWard, updateWard } from 'api/alpha/wards';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import WardInfoListViewSection from './wardOverviewDetail';
import WardForm from './wardForm';

const WardEditSection = () => {
  const [searchParams] = useSearchParams();
  const providerName = searchParams.get('providerName') || 'Unknown Provider';
  const centerName = searchParams.get('centerName') || 'Unknown Center';
  const isEdit = searchParams.get('edit') === 'true';
  const wardId = searchParams.get('ward_id');

  const [ward, setWard] = useState(null);
  const [centerId, setCenterId] = useState('');
  const { setAuth } = useAuth();

  const fetchWardFromApi = useCallback(() => {
    const requestParams = {};
    return wardId && getWard(wardId, requestParams)
      .then(({ data }) => {
        setWard(data);
        setCenterId(data.center_id);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [wardId, setAuth]);

  const updateWardToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      name: values.name,
    };
    return updateWard(wardId, updateBody)
      .then(({ data }) => {
        setWard(data);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [wardId]);

  useEffect(() => {
    fetchWardFromApi();
  }, [fetchWardFromApi]);

  return (
    <MKBox>
      <AVBreadcrumb
        items={[providerName, centerName, ward?.name]}
        showBackButton
        backButtonPath={`providers/centers?center_id=${centerId}&providerName=${providerName}&centerName=${centerName}&edit=true`}
      />
      <WardForm
        isEdit={isEdit}
        ward={ward}
        onSave={updateWardToApi}
      />
      <WardInfoListViewSection
        ward={ward}
      />
    </MKBox>
  );
};

export default WardEditSection;
