import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import Button from 'components/Button';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getLocaleMap } from 'utils/locales';

const FileForm = ({ prev, onSave }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['script', 'major_version', 'minor_version', 'prefix', 'select_file', 'description', 'save'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const emptyValues = useMemo(() => {
    return {
      file: '',
      major_version: '',
      minor_version: '',
      description: '',
      prefix: '',
    };
  }, []);
  const nonEmptyValues = useMemo(() => {
    return {
      file: prev?.file,
      major_version: prev?.major_version,
      minor_version: prev?.minor_version,
      description: prev?.description,
      prefix: prev?.prefix,
    };
  }, [prev]);

  if (prev === null) {
    return null;
  }

  return (
    <Formik
      initialValues={prev ? nonEmptyValues : emptyValues}
      onSubmit={(values) => {
        onSave(values);
      }}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 4, ml: 1 }}>
            <Grid container spacing={1}>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.script}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <InputField
                    type="file"
                    name="file"
                    label={localeMapping.select_file}
                    value={values.file}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                  />
                  {errors.file && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.file}</MKTypography>}
                </Grid>
              </Grid>
              {/* <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    Name
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange('name')}
                    onBlur={handleBlur('name')}
                    fullWidth
                  />
                  {errors.name && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.name}</MKTypography>}
                </Grid>
              </Grid> */}
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.major_version}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="major_version"
                    label={localeMapping.major_version}
                    value={values.major_version}
                    onChange={handleChange('major_version')}
                    onBlur={handleBlur('major_version')}
                    fullWidth
                  />
                  {errors.major_version && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.major_version}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.minor_version}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="minor_version"
                    label={localeMapping.minor_version}
                    value={values.minor_version}
                    onChange={handleChange('minor_version')}
                    onBlur={handleBlur('minor_version')}
                    fullWidth
                  />
                  {errors.minor_version && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.minor_version}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.prefix}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="prefix"
                    label={localeMapping.prefix}
                    value={values.prefix}
                    onChange={handleChange('prefix')}
                    onBlur={handleBlur('prefix')}
                    fullWidth
                  />
                  {errors.prefix && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.prefix}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.description}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="description"
                    label={localeMapping.description}
                    multiline
                    maxRows={10}
                    minRows={5}
                    value={values.description}
                    onChange={handleChange('description')}
                    onBlur={handleBlur('description')}
                    fullWidth
                  />
                  {errors.description && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.description}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={values.file === ''}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : localeMapping.save}
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

FileForm.propTypes = {
  prev: PropTypes.object,
  onSave: PropTypes.func,
};

export default FileForm;
