import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { createWard } from 'api/alpha/wards';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import WardForm from './wardForm';

const WardCreateSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['create_ward'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const centerId = searchParams.get('center_id');

  const createWardToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      name: values.name,
      type: '1',
      center_id: centerId,
    };

    return createWard(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [centerId, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <AVBreadcrumb startingNode={localeMapping.create_ward} showBackButton />
      <WardForm
        onSave={createWardToApi}
      />
    </MKBox>
  );
};

export default WardCreateSection;
