import api from 'api';

export const getWards = (params) => {
  return api.get('/wards', params, 'bearer');
};

export const getWard = (id, params) => {
  return api.get(`/wards/${id}`, params, 'bearer');
};

export const updateWard = (id, body) => {
  return api.put(`/wards/${id}`, body, 'bearer');
};

export const createWard = (body) => {
  return api.post('/wards', body, 'bearer');
};

export const deleteWard = (id) => {
  return api.delete(`/wards/${id}`, 'bearer');
};
