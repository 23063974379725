import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { getPatients, deletePatient } from 'api/alpha/patients';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';

const PatientsListViewSection = ({ ward }) => {
  const patientTableColumns = useMemo(() => [
    { field: 'name', label: 'Name', sortable: true },
    { field: 'device_id', label: 'Device ID' },
    { field: 'ward', label: 'Ward' },
    { field: 'lastmoddate', label: 'Last Modified', sortable: true },
  ], []);

  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');

  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const { setAuth } = useAuth();

  const fetchPatientsFromApi = useCallback(() => {
    const requestParams = { $expand: 'ward' };
    if (wardId) {
      requestParams.ward = wardId;
      requestParams.$orderBy = sortBy ? `${sortBy} ${sortDirection}` : null;
      return getPatients(requestParams)
        .then(({ data }) => {
          setPatients(data || []);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [setAuth, sortBy, sortDirection, wardId]);

  const onPressAdd = useCallback(() => {
    if (!ward) return;
    if (!ward.ward_id) return;
    navigate(`/providers/patients/ward_overview/add?ward_id=${ward.ward_id}`);
  }, [navigate, ward]);

  const onPressEdit = useCallback((patientId) => {
    if (!patientId) return;
    navigate(`/providers/patients/ward_overview/edit?patient_id=${patientId}`);
  }, [navigate]);

  const onPressDelete = useCallback((patientId) => {
    return deletePatient(patientId)
      .then(() => {
        return fetchPatientsFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchPatientsFromApi, setAuth]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = patientTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [patientTableColumns]);

  useEffect(() => {
    fetchPatientsFromApi();
  }, [fetchPatientsFromApi]);

  const rowData = useMemo(() => {
    if (!patients) return [];
    return patients.map((row) => {
      return {
        ...row,
        ward: row?.ward_id?.name,
        lastmoddate: datetimeFormatter(row.lastmoddate),
      };
    });
  }, [patients]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={patientTableColumns}
        idField="patient_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        onSort={onClickSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        selectable
        clickable
      />
    </MKBox>
  );
};

PatientsListViewSection.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default PatientsListViewSection;
