import get from 'lodash/get';
import api, { getStoredAuth, setStoredAuth } from 'api';
import { getMe } from 'api/users';
import { initialAuthState } from 'contexts/auth';
import config from 'config';

let refreshTokenPromise = null;

export const refreshToken = (refToken) => {
  if (refreshTokenPromise) {
    return refreshTokenPromise;
  }
  const body = {
    grant_type: 'refresh_token',
    refresh_token: refToken,
  };
  refreshTokenPromise = api.postUrlFormEncoded('/auth', body, 'basic')
    .then((res) => {
      const { data: newAuth } = res;
      const storedAuth = getStoredAuth();
      newAuth.user = get(storedAuth, 'user');
      setStoredAuth(newAuth);
      return res;
    })
    .finally(() => {
      refreshTokenPromise = null;
    });
  return refreshTokenPromise;
};

export const login = (username, password) => {
  return api.postUrlFormEncoded('/auth', {
    username,
    password,
    grant_type: 'password',
    audience: config.oauthAudience,
  }, 'basic')
    .then(({ data }) => {
      setStoredAuth(data);
      return getMe({$expand: 'role/scopes' });
    });
};

export const logout = () => {
  const storedAuth = getStoredAuth();
  const body = { token: get(storedAuth, 'access_token') };
  return api.postUrlFormEncoded('/token/revoke', body, 'basic')
    .then(() => setStoredAuth(initialAuthState));
};

export const getClientCredential = () => {
  const body = {
    grant_type: 'client_credentials',
    audience: config.oauthAudience,
  };
  return api.postUrlFormEncoded('/auth', body, 'basic');
};

export const forgetPassword = (username) => {
  return getClientCredential()
    .then(({ data: auth }) => {
      setStoredAuth(auth);
      return api.get(`/users/${username}/forget_password`, null, 'bearer');
    });
};

export const resetPassword = (username, oldPassword, newPassword) => {
  const body = {
    old: { password: oldPassword },
    new: { password: newPassword },
  };
  return api.put(`/users/${username}/reset_password`, body, 'bearer');
};
