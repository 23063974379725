import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSolution, updateSolution } from 'api/solutions';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { getLocaleMap } from 'utils/locales';
import EditSolutionForm from './EditSolutionForm';

const ResourceNotFound = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_resource_not_found'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4>
          {lm.labels_resource_not_found}
        </h4>
      </Container>
    </MKBox>
  );
};

const EditSolutionSection = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_edit_solution'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const [solution, setSolution] = useState(null);
  const [requestError, setRequestError] = useState(false);
  const [searchParams] = useSearchParams();
  const solution_id = searchParams.get('solution_id');

  const fetchSolutionFromApi = useCallback(() => {
    if (!solution_id) {
      return Promise.resolve();
    }
    return getSolution(solution_id)
      .then(({ data }) => {
        setSolution(data);
      })
      .catch((error) => {
        switch (error.response.status) {
          case 200:
            setRequestError(false);
            break;
          default:
            setRequestError(true);
            break;
        }
      });
  }, [solution_id]);

  const updateSolutionToApi = useCallback((values, { setFieldError }) => {
    if (!solution_id) {
      return Promise.resolve();
    }
    const updateBody = {
      display_name: values.display_name,
      type: values.type,
    };
    return updateSolution(solution_id, updateBody)
      .then(({ data }) => {
        setSolution(data);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [solution_id]);

  useEffect(() => {
    fetchSolutionFromApi();
  }, [fetchSolutionFromApi]);

  if (solution) {
    return (
      <MKBox flex={1} py="2rem">
        <Container>
          <h4 style={{ marginBottom: 10 }}>
            {lm.labels_edit_solution}
          </h4>
          <EditSolutionForm
            solution={solution}
            onSave={updateSolutionToApi}
          />
        </Container>
      </MKBox>
    );
  }

  if (requestError) {
    return <ResourceNotFound />;
  }
};

export default EditSolutionSection;
