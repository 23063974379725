import PropTypes from 'prop-types';
import Carousel from 'components/Carousel';

const CarouselSection = ({ items, direction, item_props, timer_ms, hide_buttons, hide_indicators, buttons_color, buttons_size, indicators_color, indicators_size, editable, onPressEdit, ...props }) => {
  return (
    <Carousel
      items={items}
      item_props={item_props}
      direction={direction}
      timer={parseInt(timer_ms, 10)}
      hide_buttons={hide_buttons}
      hide_indicators={hide_indicators}
      buttons_color={buttons_color}
      buttons_size={buttons_size}
      indicators_color={indicators_color}
      indicators_size={indicators_size}
      {...props}
    />
  );
};

CarouselSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  direction: PropTypes.oneOf(['row', 'column']),
  item_props: PropTypes.object,
  timer_ms: PropTypes.string,
  hide_buttons: PropTypes.bool,
  hide_indicators: PropTypes.bool,
  buttons_color: PropTypes.string,
  buttons_size: PropTypes.string,
  indicators_color: PropTypes.string,
  indicators_size: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

CarouselSection.defaultProps = {
  items: [],
  direction: null,
  hide_buttons: false,
  hide_indicators: false,
  buttons_color: null,
  buttons_size: null,
  indicators_color: null,
  indicators_size: null,
  item_props: {},
  timer_ms: null,
};

export default CarouselSection;
