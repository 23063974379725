import api from 'api';

export const getHandlerSignals = (params) => {
  return api.get('/handler_signals', params, 'bearer');
};

export const getHandlerSignal = (id, params) => {
  return api.get(`/handler_signals/${id}`, params, 'bearer');
};

export const updateHandlerSignal = (id, body) => {
  return api.put(`/handler_signals/${id}`, body, 'bearer');
};

export const createHandlerSignal = (body) => {
  return api.post('/handler_signals', body, 'bearer');
};

export const deleteHandlerSignal = (id) => {
  return api.delete(`/handler_signals/${id}`, 'bearer');
};
