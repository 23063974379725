import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditSectionForm from 'pages/EditSection/EditSectionForm';
import { getSection, updateSection, getSectionDefinitions, createDynamicTableRow, createSection } from 'api/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const EditSectionPage = () => {
  const [section, setSection] = useState(null);
  const [sectionDefinitions, setSectionDefinitions] = useState([]);
  const { pageId, sectionId } = useParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const fetchSectionFromApi = useCallback(() => {
    if (!sectionId) {
      return Promise.resolve();
    }
    const sectionParams = {};
    return getSection(sectionId, sectionParams)
      .then(({ data }) => {
        setSection(data);
      });
  }, [sectionId]);

  const fetchSectionDefinitionsFromApi = useCallback(() => {
    const sectionDefinitionParams = {
      $expand: 'collection_definition',
    };
    return getSectionDefinitions(sectionDefinitionParams)
      .then(({ data }) => {
        setSectionDefinitions(data);
      });
  }, []);

  const onClickCancel = useCallback(() => {
    return navigate(-1);
  }, [navigate]);

  const onSubmitSectionToApi = useCallback((values, { setFieldError }) => {
    const selectedSectionDefinition = (sectionDefinitions || []).find(({ section_definition_id }) => section_definition_id === values.section_definition);
    const selectedCollectionDefinitionId = selectedSectionDefinition?.collection_definition?.collection_definition_id;

    if (!selectedCollectionDefinitionId) {
      return Promise.resolve();
    }
    const updateBody = {
      section_definition: values.section_definition,
      sequence: values.sequence,
      is_viewport: values.is_viewport,
    };
    const createSectionData = () => createDynamicTableRow(selectedCollectionDefinitionId, {})
      .then(({ data }) => {
        const createBody = {
          ...updateBody,
          page: pageId,
          dyn_t: data?.id,
        };
        return createSection(createBody);
      });
    return (sectionId ? updateSection(sectionId, updateBody) : createSectionData())
      .then(({ data }) => {
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [sectionId, pageId, sectionDefinitions, navigate]);

  useEffect(() => {
    Promise.all([
      fetchSectionFromApi(),
      fetchSectionDefinitionsFromApi(),
    ])
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchSectionFromApi, fetchSectionDefinitionsFromApi, setAuth]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditSectionForm
          section={section}
          sectionDefinitions={sectionDefinitions}
          onSubmit={onSubmitSectionToApi}
          onCancel={onClickCancel}
        />
      </Container>
    </MKBox>
  );
};

export default EditSectionPage;
