import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { createCenter } from 'api/alpha/centers';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import CenterForm from './centerForm';

const CenterCreateSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['create_center'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const providerId = searchParams.get('providerId');

  const createCenterToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      name: values.name,
    };

    if (providerId) {
      payload.service_provider_id = providerId;
    }

    return createCenter(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate, providerId]);

  return (
    <MKBox flex={1} py="2rem">
      <AVBreadcrumb startingNode={localeMapping.create_center} showBackButton />
      <CenterForm
        onSave={createCenterToApi}
      />
    </MKBox>
  );
};

export default CenterCreateSection;
