import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Image from 'components/Image';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Container } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const HeaderNavbar = ({ logo_src, logo_props, items, item_props, menu_color, menu_secondary_color, extra_section, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };
    mobileMediaQuery.addEventListener('change', handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);
    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange);
    };
  }, []);

  const handleItemClick = (link) => {
    navigate(link);
  };

  if (!isMobile) {
    return (
      <Container>
        <MKBox
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            ...props,
          }}
        >
          <MKBox
            flexGrow="1"
          >
            <Image src={logo_src} sx={{ ...logo_props }} />
          </MKBox>
          <MKBox
            flexGrow="4"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            {items.map((item, i) => {
              const style = {};
              if (item.link === location.pathname) {
                style.fontWeight = 'bold';
              }
              return (

                <MKBox
                  key={i}
                  paddingX={1}
                  onClick={() => handleItemClick(item.link)}
                  sx={{ cursor: 'pointer', color: menu_color, ...item_props }}
                  style={{ ...style }}
                >
                  {item.title}
                </MKBox>
              );
            })}
          </MKBox>
          {extra_section && (
          <MKBox
            flexGrow="1"
          >
            {extra_section}
          </MKBox>
          )}
        </MKBox>
      </Container>
    );
  }
  // return mobile view
  return (
    <MKBox display="flex" flexDirection="column">
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <IconButton
          onClick={() => toggleDropdown()}
          sx={{
            fontSize: '2rem',
            color: { menu_color },
          }}
        >
          {isDropdownOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <Image src={logo_src} sx={{ ...logo_props }} />
        {extra_section && (
          <MKBox>
            {extra_section}
          </MKBox>
        )}
      </MKBox>
      <MKBox>
        {isDropdownOpen && (
          <MKBox
            mt="2vh"
          >
            {items.map((item, i) => {
              const style = {};
              if (item.link === location.pathname) {
                style.fontWeight = 'bold';
              }
              return (
                <>
                  <Divider />
                  <MKBox
                    key={i}
                    onClick={() => { navigate(item.link); }}
                    sx={{ paddingX: '3vw', cursor: 'pointer', color: menu_color, ...item_props }}
                    style={{ ...style }}
                  >
                    {item.title}
                  </MKBox>
                </>
              );
            })}
          </MKBox>
        )}
      </MKBox>
    </MKBox>
  );
};

HeaderNavbar.propTypes = {
  logo_src: PropTypes.string,
  logo_props: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  })),
  item_props: PropTypes.object,
  menu_color: PropTypes.string,
  menu_secondary_color: PropTypes.string,
  extra_section: PropTypes.any,
};

export default HeaderNavbar;
