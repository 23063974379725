import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { getPublicSections } from 'api/sections';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import MKBox from 'components/MaterialKit/MKBox';
import { IconButton, Stack } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

const Carousel = ({
  items,
  item_props,
  timer,
  direction,
  hide_buttons,
  hide_indicators,
  buttons_color,
  buttons_size,
  indicators_color,
  indicators_size,
  ...props
}) => {
  const [subSections, setSubSections] = useState([]);
  const [subSectionsProps, setSubSectionsProps] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const { auth, setAuth } = useAuth();

  const fetchSectionsFromApi = useCallback((sectionIds) => {
    const sectionParams = {
      'section_id[in]': sectionIds.join(','),
      '$expand': 'section_definition/collection_definition/attributes',
    };
    return getPublicSections(sectionParams)
      .then(({ data }) => {
        setSubSections(data);
      });
  }, []);

  useEffect(() => {
    const sectionIds = (items || []).reduce((ids, item) => {
      const sections = item.sections || (item.section ? [item.section] : []);
      if (sections) {
        return [...ids, ...sections];
      }
      return ids;
    }, []);
    if (sectionIds.length > 0) {
      fetchSectionsFromApi(sectionIds, !auth.user)
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [items, fetchSectionsFromApi, auth.user, setAuth]);

  useEffect(() => {
    (subSections || []).forEach((subSection) => {
      const { section_id } = subSection;
      return fetchSectionProps(subSection, !auth.user)
        .then((subSectionProps) => {
          setSubSectionsProps((oriSubSectionsProps) => {
            const updatedSubSectionsProps = { ...oriSubSectionsProps };
            updatedSubSectionsProps[section_id] = subSectionProps;
            return updatedSubSectionsProps;
          });
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    });
  }, [subSections, auth.user, setAuth]);

  const updateIndex = (newIndex) => {
    let index;
    const lastIndex = items.length - 1;

    if (newIndex < 0) {
      index = lastIndex;
    } else if (newIndex > lastIndex) {
      index = 0;
    } else {
      index = newIndex;
    }

    setActiveIndex(index);
  };

  useEffect(() => {
    if (timer === 0) {
      return;
    }
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, timer || 5000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <MKBox
      display="flex"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        maxWidth: '100%',
        ...props,
      }}
    >
      <MKBox
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        sx={{
          whiteSpace: 'nowrap',
          transition: 'transform 0.4s',
          width: '100%',
        }}
      >
        {(items || []).map((item, i) => {
          const { sections, section, ...extraProps } = item;
          const sectionIds = sections || (section ? [section] : []);
          return (sectionIds || []).map((sectionId, j) => {
            const subSection = subSections.find((s) => s.section_id === sectionId);
            const sectionComponent = renderSectionComponent(subSection, subSectionsProps[sectionId], extraProps.editable, extraProps.onPressEdit);
            return subSection ? (
              <MKBox
                key={i}
                display="inline-flex"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="stretch"
                sx={{
                  whiteSpace: 'normal',
                }}
              >
                {sectionComponent}
              </MKBox>

            ) : null;
          });
        })}
      </MKBox>

      {!hide_indicators && (
        <MKBox
          style={{
            position: 'absolute',
            bottom: '5%',
            left: 0,
            right: 0,
            transform: 'translateY(-50%)',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 20px',
          }}
        >
          <Stack direction="row" spacing={1}>
            {(items || []).map((_, index) => (
              <IconButton
                key={index}
                onClick={() => updateIndex(index)}
                sx={{
                  fontSize: indicators_size || '1.5rem',
                  color: indicators_color || '#344767',
                }}
              >
                {index === activeIndex ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
              </IconButton>
            ))}
          </Stack>
        </MKBox>
      )}

      { !hide_buttons && (
      <MKBox
        style={{
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 20px',
        }}
      >
        <IconButton
          onClick={() => updateIndex(activeIndex - 1)}
          sx={{ fontSize: buttons_size || '3rem', color: buttons_color || '#344767' }}
        >
          <ArrowBackIos />
        </IconButton>
        <IconButton
          onClick={() => updateIndex(activeIndex + 1)}
          sx={{ fontSize: buttons_size || '3rem', color: buttons_color || '#344767' }}
        >
          <ArrowForwardIos />
        </IconButton>
      </MKBox>
      )}
    </MKBox>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  item_props: PropTypes.object,
  direction: PropTypes.oneOf(['column', 'row']),
  timer: PropTypes.number,
  hide_buttons: PropTypes.bool,
  buttons_color: PropTypes.string,
  buttons_size: PropTypes.string,
  hide_indicators: PropTypes.bool,
  indicators_color: PropTypes.string,
  indicators_size: PropTypes.string,
};

export default Carousel;
