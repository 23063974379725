import MKBox from 'components/MaterialKit/MKBox';
import React from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import ServiceProvidersListViewSection from './ProvidersList';

const AVServiceProvidersManagementSection = () => {
  return (
    <MKBox display="flex" flexDirection="column" justifyContent="flex-start">
      <AVBreadcrumb />
      <ServiceProvidersListViewSection />
    </MKBox>
  );
};

export default AVServiceProvidersManagementSection;
