import api from 'api';

export const getCenters = (params) => {
  return api.get('/centers', params, 'bearer');
};

export const getCenter = (id, params) => {
  return api.get(`/centers/${id}`, params, 'bearer');
};

export const updateCenter = (id, body) => {
  return api.put(`/centers/${id}`, body, 'bearer');
};

export const createCenter = (body) => {
  return api.post('/centers', body, 'bearer');
};

export const deleteCenter = (id) => {
  return api.delete(`/centers/${id}`, 'bearer');
};
