import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import { deleteServiceProvider, getServiceProviders } from 'api/alpha/service_providers';
import { datetimeFormatter } from 'utils/datetime';

const ServiceProvidersListViewSection = () => {
  const serviceProviderTableColumns = useMemo(() => [
    { field: 'name', sortable: true },
    { field: 'amount', label: 'Centers number' },
    { field: 'lastmoddate', label: 'Last Modified', sortable: true },
    {
      field: 'service_provider_id',
      label: 'Service Provider ID',
      width: '10%',
      sortable: true,
    },
  ], []);

  const [serviceProviders, setServiceProviders] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchProvidersFromApi = useCallback(() => {
    const requestParams = {
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}),
    };
    return getServiceProviders(requestParams)
      .then(({ data }) => {
        setServiceProviders(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, sortBy, sortDirection]);

  const onPressAdd = useCallback(() => {
    navigate('/providers/create_service_provider');
  }, [navigate]);

  const onPressEdit = useCallback((serviceProviderId) => {
    navigate(`/providers/service_providers?service_provider_id=${serviceProviderId}&providerName=${serviceProviders.find(({ service_provider_id }) => serviceProviderId === service_provider_id).name}&edit=true`);
  }, [navigate, serviceProviders]);

  const onPressDelete = useCallback((serviceProviderId) => {
    return deleteServiceProvider(serviceProviderId)
      .then(() => {
        return fetchProvidersFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchProvidersFromApi, setAuth]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = serviceProviderTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [serviceProviderTableColumns]);

  useEffect(() => {
    fetchProvidersFromApi();
  }, [fetchProvidersFromApi]);

  const rowData = useMemo(() => {
    if (!serviceProviders) return [];
    return serviceProviders.map((row) => {
      return {
        ...row,
        amount: row.centers?.length,
        lastmoddate: datetimeFormatter(row.lastmoddate, 'YYYY-MM-DD HH:mm:ss'),
      };
    });
  }, [serviceProviders]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={serviceProviderTableColumns}
        idField="service_provider_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        onSort={onClickSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        selectable
      />
    </MKBox>
  );
};

export default ServiceProvidersListViewSection;
