import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';

const CollapseBox = ({ sectionNode, iconOpenUrl, iconCloseUrl, offset, iconSize, ...props }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderToggleButton = () => (
    <button
      type="button"
      style={{
        position: 'absolute',
        top: offset.top || '',
        right: offset.right || '',
        bottom: offset.bottom || '',
        left: offset.left || '',
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
        zIndex: 1, // Ensure the button is above other elements
      }}
      onClick={toggleOpen}
    >
      <img
        src={isOpen ? iconCloseUrl : iconOpenUrl}
        alt={isOpen ? 'Close' : 'Open'}
        width={iconSize.width || '20'}
        height={iconSize.height || '20'}
      />
    </button>
  );

  const renderContent = () => (
    <MKBox
      sx={{
        position: 'relative',
        overflow: 'hidden',
        transition: 'max-height 1s ease', // Add a smooth transition effect
        maxHeight: isOpen ? '1000px' : '0', // Adjust the height as needed
        ...props,
      }}
    >
      {
        isOpen && sectionNode && sectionNode.map((node, i) => (
          <MKBox
            key={i}
            sx={{ ...props }}
          >
            {node}
          </MKBox>
        ))
      }
    </MKBox>
  );

  return (
    <MKBox
      sx={{
        position: 'relative',
        ...props,
      }}
    >
      {renderToggleButton()}
      {renderContent()}
    </MKBox>
  );
};

CollapseBox.propTypes = {
  sectionNode: PropTypes.arrayOf(PropTypes.any),
  iconOpenUrl: PropTypes.string,
  iconCloseUrl: PropTypes.string,
  offset: PropTypes.shape({
    top: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
  }),
  iconSize: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

CollapseBox.defaultProps = {
  sectionNode: null,
  offset: {},
  iconOpenUrl: '',
  iconCloseUrl: '',
};

export default CollapseBox;
