import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MKBox from 'components/MaterialKit/MKBox';

const getSelectedOptions = (options, values) => {
  return (options || []).filter(
    ({ value }) => values.includes(value),
  );
};

const Select = ({ label, value, onChange, options, multiple, invalid, ...props }) => {
  const structuredValue = useMemo(() => {
    if (multiple) {
      if (!value) {
        return [];
      }
      return Array.isArray(value) ? value : [value];
    }
    const selectedOption = getSelectedOptions(options, [value])[0];
    return (['number', 'boolean'].includes(typeof selectedOption?.value)) ? selectedOption?.value : (selectedOption?.value || '');
  }, [multiple, value, options]);

  const getDisplayValue = useCallback((v) => {
    return getSelectedOptions(options, Array.isArray(v) ? v : [v])
      .reduce((str, option) => `${str ? `${str}, ` : ''}${option.label}`, '');
  }, [options]);

  return (
    <FormControl fullWidth>
      <InputLabel error={invalid}>{label}</InputLabel>
      <MuiSelect
        label={label}
        value={structuredValue}
        onChange={(e) => onChange(e.target.value)}
        renderValue={getDisplayValue}
        multiple={multiple}
        error={invalid}
        fullWidth
        {...props}
      >
        {(options || []).map((option, i) => {
          const { label: optionLabel, value: optionValue } = option;
          return (
            <MenuItem key={`${i}-${optionValue}`} value={optionValue}>
              {multiple && (
                <MKBox mr={1}>
                  <Checkbox sx={{ padding: 0.5 }} checked={structuredValue.includes(optionValue)} />
                </MKBox>
              )}
              {optionLabel}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  invalid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
};

Select.defaultProps = {
  label: null,
  value: null,
  onChange: () => {},
  multiple: false,
  invalid: false,
  options: [],
};

export default Select;
