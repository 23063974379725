import api from 'api';

export const getPatients = (params) => {
  return api.get('/patients', params, 'bearer');
};

export const getPatient = (id, params) => {
  return api.get(`/patients/${id}`, params, 'bearer');
};

export const updatePatient = (id, body) => {
  return api.put(`/patients/${id}`, body, 'bearer');
};

export const createPatient = (body) => {
  return api.post('/patients', body, 'bearer');
};

export const deletePatient = (id) => {
  return api.delete(`/patients/${id}`, 'bearer');
};
