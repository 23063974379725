import Button from 'components/Button';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { sendApiRequest } from 'api/general';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const TwoButtonCallApi = ({ label_1, label_2, url_1, url_2, method_1, method_2, params_1, params_2 }) => {
  const [apiResponse, setApiResponse] = useState(null);
  const { setAuth } = useAuth();

  const callApi1 = useCallback(() => {
    const params = { ...params_1 };
    sendApiRequest(url_1, method_1, params, 'bearer')
      .then(({ data }) => {
        setApiResponse(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [url_1, method_1, params_1, setAuth]);

  const callApi2 = useCallback(() => {
    const params = { ...params_2 };
    sendApiRequest(url_2, method_2, params, 'bearer')
      .then(({ data }) => {
        setApiResponse(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [url_2, method_2, params_2, setAuth]);

  return (
    <MKBox p={2}>
      <MKBox m={2}>
        <Button
          onClick={callApi1}
          variant="gradient"
          color="secondary"
        >
          {label_1 || 'label_1'}
        </Button>
      </MKBox>
      <MKBox m={2}>
        <Button
          onClick={callApi2}
          variant="gradient"
          color="secondary"
        >
          {label_2 || 'label_2'}
        </Button>
      </MKBox>
      <MKBox px={4} py={2}>
        {apiResponse && (
          <>
            <MKTypography variant="h3" fontWeight="regular" fontSize>
              Response:
            </MKTypography>
            <MKTypography variant="p" fontWeight="regular" fontSize>
              {JSON.stringify(apiResponse)}
            </MKTypography>
          </>
        )}
      </MKBox>
    </MKBox>
  );
};

TwoButtonCallApi.propTypes = {
  label_1: PropTypes.string,
  label_2: PropTypes.string,
  url_1: PropTypes.string,
  url_2: PropTypes.string,
  method_1: PropTypes.oneOf(['get', 'post', 'put', 'patch', 'delete']),
  method_2: PropTypes.oneOf(['get', 'post', 'put', 'patch', 'delete']),
  params_1: PropTypes.object,
  params_2: PropTypes.object,
};

export default TwoButtonCallApi;
