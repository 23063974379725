import PropTypes from 'prop-types';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { tryGetLocalizedText } from 'utils/locales';

const ButtonSection = ({ text, to, href, variant, color, size, font_size, full_width, circular, border_radius, icon_only, disabled, editable, onPressEdit, ...props }) => {
  const { i18n: { language } } = useTranslation();
  if (text) {
    const textContent = tryGetLocalizedText(text, language);
    const formattedBorderRadius = Number.isNaN(Number(border_radius)) ? border_radius : Number(border_radius);
    const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
    return (
      <Button
        to={to}
        href={href}
        variant={variant}
        color={color}
        size={size}
        fontSize={formattedFontSize}
        fullWidth={full_width}
        circular={circular}
        borderRadius={formattedBorderRadius}
        iconOnly={icon_only}
        disabled={disabled}
        {...props}
      >
        {textContent}
      </Button>
    );
  }
};

ButtonSection.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  full_width: PropTypes.bool,
  circular: PropTypes.bool,
  border_radius: PropTypes.string,
  icon_only: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default ButtonSection;
