import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { deleteCenter, getCenters } from 'api/alpha/centers';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';

const CentersListViewSection = ({ provider }) => {
  const centerTableColumns = useMemo(() => [
    { field: 'name', sortable: true },
    { field: 'center_number', label: 'Center number' },
    { field: 'lastmoddate', label: 'Last Modified', sortable: true },
    {
      field: 'center_id',
      label: 'Center ID',
      width: '10%',
      sortable: true,
    },
  ], []);

  const [centers, setCenters] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [searchParams] = useSearchParams();
  const serviceProviderId = searchParams.get('service_provider_id');

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchCentersFromApi = useCallback(() => {
    const requestParams = {};
    if (serviceProviderId) {
      requestParams.service_provider_id = serviceProviderId;
      requestParams.$expand = 'service_provider_id';
      requestParams.$orderBy = sortBy ? `${sortBy} ${sortDirection}` : null;
    }
    return getCenters(requestParams)
      .then(({ data }) => {
        setCenters(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [serviceProviderId, setAuth, sortBy, sortDirection]);

  const onPressAdd = useCallback(() => {
    navigate(`/providers/create_center?providerId=${provider?.service_provider_id}`);
  }, [navigate, provider?.service_provider_id]);

  const onPressEdit = useCallback((centerId) => {
    navigate(`/providers/centers?center_id=${centerId}&providerName=${provider?.name}&centerName=${centers.find(({ center_id }) => centerId === center_id).name}&edit=true`);
  }, [centers, navigate, provider?.name]);

  const onPressDelete = useCallback((centerId) => {
    return deleteCenter(centerId)
      .then(() => {
        return fetchCentersFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchCentersFromApi, setAuth]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = centerTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [centerTableColumns]);

  useEffect(() => {
    fetchCentersFromApi();
  }, [fetchCentersFromApi]);

  const rowData = useMemo(() => {
    if (!centers) return [];
    return centers.map((row) => {
      return {
        ...row,
        center_number: row.wards?.length || 0,
        lastmoddate: row.lastmoddate && datetimeFormatter(row.lastmoddate, 'YYYY-MM-DD HH:mm:ss'),
      };
    });
  }, [centers]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={centerTableColumns}
        idField="center_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        onSort={onClickSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        selectable
      />
    </MKBox>
  );
};

CentersListViewSection.propTypes = {
  provider: PropTypes.shape({
    service_provider_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default CentersListViewSection;
