import MKBox from 'components/MaterialKit/MKBox';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import getStompClient from 'socket/stompClient';
import { useState } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';

const DebugDeviceSection = ({ devicePhysicalId, websocketUrl, ...props }) => {
  const [startDebug, setStartDebug] = useState(false);
  const [dataCounter, setDataCounter] = useState(null);
  const [debugMessage, setDebugMessage] = useState(null);

  const addCameraMessageListener = (stompClient) => {
    if (stompClient && stompClient.connected) {
      stompClient.subscribe(`/debug/${devicePhysicalId}`, (res) => {
        setDebugMessage(res.body);
      });
    }
  };

  const addDataCounterListener = (stompClient) => {
    if (stompClient && stompClient.connected) {
      stompClient.subscribe('/data_counter', (res) => {
        const counterArray = JSON.parse(res.body)
        setDataCounter((counterArray || []).map((counter) => {
          const timestamp = Object.keys(counter)[0];
          const count = counter[timestamp];
          return <MKBox>{`${moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}: ${count}`}</MKBox>;
        }));
      });
    }
  };

  const onStartDebug = () => {
    let formattedWebsocketUrl;
    if (websocketUrl.startsWith('ws:')) {
      formattedWebsocketUrl = `${websocketUrl.replace('ws:', 'http:')}/ws`;
    } else if (websocketUrl.startsWith('wss:')) {
      formattedWebsocketUrl = `${websocketUrl.replace('wss:', 'https:')}/ws`;
    }
    const stompClient = getStompClient(formattedWebsocketUrl || 'https://portal.alphavision.tech/alpha-vision-in-websocket-server/ws');
    setStartDebug(true);
    stompClient.onConnect = (frame) => {
      if (stompClient.connected) {
        console.log('connected debug websocket');
        addCameraMessageListener(stompClient);
        addDataCounterListener(stompClient);
      }
    };
    stompClient.onStompError = (err) => {
      console.error('stompClient err', err);
    };
    stompClient.activate();
  };

  return (
    <Grid container flexDirection="column" marginTop={5}>
      <Grid item>
        <Button onClick={onStartDebug}>
          Verify
        </Button>
        {startDebug && (
          <MKBox>
            <MKBox mt={5}>
              {dataCounter}
            </MKBox>
            <MKBox mt={5}>
              {debugMessage}
            </MKBox>
          </MKBox>
        )}
      </Grid>
    </Grid>
  );
};

DebugDeviceSection.propTypes = {
  devicePhysicalId: PropTypes.string,
  websocketUrl: PropTypes.string,
};

export default DebugDeviceSection;
