import { React, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetadataSection = ({ page_title, metadata_items, favicon_url }) => {
  useEffect(() => {
    if (favicon_url === null) return;
    const existingFavicons = document.querySelectorAll('link[rel="shortcut icon"]');
    existingFavicons.forEach((favicon) => {
      favicon.parentNode.removeChild(favicon);
    });
    const newFavicon = document.createElement('link');
    newFavicon.rel = 'shortcut icon';
    newFavicon.type = 'image/png';
    newFavicon.href = favicon_url;
    document.head.appendChild(newFavicon);
  }, [favicon_url]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{page_title}</title>
        {metadata_items.map((item, i) => {
          return (
            <meta key={i} name={item.name} content={item.content} />
          );
        })}
      </Helmet>
    </HelmetProvider>
  );
};

MetadataSection.propTypes = {
  page_title: PropTypes.string,
  metadata_items: PropTypes.arrayOf(PropTypes.shape({})),
  favicon_url: PropTypes.string,
};

MetadataSection.defaultProps = {
  page_title: null,
  metadata_items: [],
  favicon_url: null,
};

export default MetadataSection;
