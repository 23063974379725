/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import MKTable from 'components/MaterialKit/MKTable';
import MKBox from 'components/MaterialKit/MKBox';
import AnchorMenu from 'components/AnchorMenu';
import Button from 'components/Button';
import { withProps } from 'utils/hoc';
import { getLocaleMap } from 'utils/locales';
import { useTranslation } from 'react-i18next';
import TablePageActionButtonGroup from './TablePageActionButtonGroup';

const DataTable = ({ columns, data, idField, selectable, objectTypes, typeRouting, onSelectRow, onClickRow, onPressAdd, onPressEdit, onPressDelete, onPressCopy, onPressPaste, onPressUpdateIP, onPressUpdateFloorPolygon, totalCount, currentPage, onPageChange, rowsPerPage, onRowsPerPageChange, rowsPerPageOptions, paginationDisabled, sortBy, sortDirection, onSort, pageButtonProps, addButtonLabel, editButtonLabel, deleteButtonLabel, copyButtonLabel, pasteButtonLabel, onPressReboot, onPressUpgradeAI, onPressUpgradeWatchdog, onPressUpdateUrl, multirowSelection, ...props }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [localeMapping, setLocaleMapping] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation('Portal');

  useEffect(() => {
    getLocaleMap(['add_button', 'edit_button', 'delete_button', 'confirmation_prompts_delete', 'copy_button', 'paste_button',
      'labels_to', 'labels_of', 'labels_more_than', 'labels_page_size', 'reboot', 'upgrade_ai_software', 'upgrade_watchdog_software', 'update_url',
      'update_camera_floor_polygon', 'update_video_streaming_server_ip',
    ])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const onOpenMenu = useCallback(({ currentTarget }) => setMenuAnchor(currentTarget), []);

  const selectedItem = useMemo(() => (data || []).find(
    (datum) => datum[idField] === selectedId,
  ), [data, idField, selectedId]);

  const headers = useMemo(() => [
    ...(selectable ? [{
      name: 'selected',
      label: (
        multirowSelection ? (
          <Checkbox
            checked={selectedIds.length === data.length}
            indeterminate={selectedIds.length > 0 && selectedIds.length < data.length}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds(data.map((datum) => datum[idField]));
              } else {
                setSelectedIds([]);
              }
            }}
            disabled={!multirowSelection}
          />
        ) : null
      ),
      width: '0',
    }] : []),
    ...columns.map((column, i) => {
      const { label, field, ...colProps } = column;
      let arrowIcon;

      if (sortDirection === 'asc') {
        arrowIcon = 'arrow_drop_up';
      } else if (sortDirection === 'desc') {
        arrowIcon = 'arrow_drop_down';
      }
      return {
        name: field,
        label: (
          <Grid container justifyContent={i > 0 ? 'center' : 'flex-start'} alignItems="center" flexWrap="nowrap">
            {label !== undefined ? t(`${label}`) ? t(`${label}`) : label : startCase(t(`${field}`))}
            {field === sortBy && (
              <Icon fontSize="default" color="dark">
                {arrowIcon}
              </Icon>
            )}
          </Grid>
        ),
        align: i > 0 ? 'center' : 'left',
        ...colProps,
      };
    }),
  ], [columns, data, idField, multirowSelection, selectable, selectedIds.length, sortBy, sortDirection, t]);

  const rows = useMemo(() => (data || []).map((datum) => {
    return columns.reduce((rowData, column) => {
      const { field, to, urlParams, href, formatter, content, renderContent, onClick } = column;
      const updatedRowData = { ...rowData };
      const displayText = formatter ? formatter(get(datum, field)) : get(datum, field);
      const parsedTo = (urlParams || []).reduce((result, urlParam) => {
        return result.replaceAll(`:${urlParam}`, get(datum, urlParam));
      }, to);

      if (typeof renderContent === 'function') {
        updatedRowData[field] = renderContent(datum);
      } else if (content) {
        updatedRowData[field] = content;
      } else if (to || href || onClick) {
        updatedRowData[field] = (
          <Button
            variant="text"
            onClick={() => (typeof onClick === 'function' ? onClick(datum) : null)}
            to={parsedTo}
            href={href}
            fullWidth
          >
            {displayText}
          </Button>
        );
      } else {
        updatedRowData[field] = displayText;
      }
      if (selectable) {
        updatedRowData.selected = (
          <Checkbox
            checked={selectedIds.includes(datum[idField])}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (e.target.checked) {
                if (multirowSelection) {
                  setSelectedIds((prevSelectedIds) => [...prevSelectedIds, datum[idField]]);
                } else {
                  setSelectedIds([datum[idField]]);
                }
              } else {
                setSelectedIds((prevSelectedIds) => prevSelectedIds.filter((id) => id !== datum[idField]));
              }
            }}
          />
        );
      }
      return updatedRowData;
    }, {});
  }), [data, columns, selectable, selectedIds, idField, multirowSelection]);

  const actionButtons = useMemo(() => [
    ...(typeof onPressAdd === 'function' ? [{
      label: localeMapping.add_button || addButtonLabel || 'Add',
      color: 'success',
      onClick: objectTypes.length > 0 ? onOpenMenu : () => onPressAdd('default'),
    }] : []),
    ...(typeof onPressEdit === 'function' ? [{
      label: localeMapping.edit_button || editButtonLabel || 'Edit',
      color: 'info',
      disabled: selectedIds.length !== 1,
      to: typeRouting[selectedItem?.type] || typeRouting?.default,
      onClick: () => onPressEdit(selectedIds[0]),
    }] : []),
    ...(typeof onPressDelete === 'function' ? [{
      label: localeMapping.delete_button || deleteButtonLabel || 'Delete',
      color: 'error',
      disabled: selectedIds.length !== 1,
      onClick: () => {
        if (window.confirm(localeMapping.confirmation_prompts_delete || 'Are you sure you want to delete this item?')) {
          return Promise.resolve(onPressDelete(selectedIds[0]))
            .then(() => setSelectedIds([]));
        }
        return Promise.resolve();
      },
    }] : []),
    ...(typeof onPressCopy === 'function' ? [{
      label: localeMapping.copy_button || copyButtonLabel || 'Copy',
      color: 'dark',
      disabled: selectedIds.length !== 1,
      onClick: () => onPressCopy(selectedId[0]),
    }] : []),
    ...(typeof onPressPaste === 'function' ? [{
      label: localeMapping.paste_button || pasteButtonLabel || 'Paste',
      color: 'secondary',
      onClick: onPressPaste,
    }] : []),
  ], [onPressAdd, localeMapping.add_button, localeMapping.edit_button, localeMapping.delete_button, localeMapping.copy_button, localeMapping.paste_button, localeMapping.confirmation_prompts_delete, addButtonLabel, objectTypes.length, onOpenMenu, onPressEdit, editButtonLabel, selectedIds, typeRouting, selectedItem?.type, onPressDelete, deleteButtonLabel, onPressCopy, copyButtonLabel, onPressPaste, pasteButtonLabel, selectedId]);

  const extraButtons = useMemo(() => [
    ...(typeof onPressReboot === 'function' ? [{
      label: localeMapping.reboot,
      color: 'warning',
      disabled: selectedIds.length === 0,
      onClick: () => onPressReboot(selectedIds),
    }] : []),
    ...(typeof onPressUpgradeAI === 'function' ? [{
      label: localeMapping.upgrade_ai_software,
      color: 'warning',
      disabled: selectedIds.length === 0,
      onClick: () => onPressUpgradeAI(selectedIds),
    }] : []),
    ...(typeof onPressUpgradeWatchdog === 'function' ? [{
      label: localeMapping.upgrade_watchdog_software,
      color: 'warning',
      disabled: selectedIds.length === 0,
      onClick: () => onPressUpgradeWatchdog(selectedIds),
    }] : []),
    ...(typeof onPressUpdateIP === 'function' ? [{
      label: localeMapping.update_video_streaming_server_ip,
      color: 'warning',
      disabled: selectedIds.length === 0,
      onClick: () => onPressUpdateIP(selectedIds),
    }] : []),
    ...(typeof onPressUpdateFloorPolygon === 'function' ? [{
      label: localeMapping.update_camera_floor_polygon,
      color: 'warning',
      disabled: selectedIds.length === 0,
      onClick: () => onPressUpdateFloorPolygon(selectedIds),
    }] : []),
    ...(typeof onPressUpdateUrl === 'function' ? [{
      label: localeMapping.update_url,
      color: 'warning',
      disabled: selectedIds.length === 0,
      onClick: () => onPressUpdateUrl(selectedIds),
    }] : []),
  ], [localeMapping, onPressReboot, selectedIds, onPressUpgradeAI, onPressUpgradeWatchdog, onPressUpdateIP, onPressUpdateFloorPolygon, onPressUpdateUrl]);

  const objectTypeOptions = useMemo(() => objectTypes.map((type) => ({
    label: type.name,
    value: type.object_type_id,
  })), [objectTypes]);

  return (
    <MKBox>
      {actionButtons.length > 0 && (
        <Grid container justifyContent="space-between" gap={1} mb={2}>
          <Stack direction="row" spacing={1}>
            {actionButtons.map((actionButton, idx) => {
              const { label, color, onClick, to, disabled } = actionButton;
              return (
                <Button
                  size="small"
                  variant="gradient"
                  color={color}
                  onClick={onClick}
                  to={to}
                  disabled={disabled}
                >
                  {label}
                </Button>
              );
            })}
          </Stack>
          <Stack direction="row" spacing={1}>
            {extraButtons.map((actionButton, idx) => {
              const { label, color, onClick, to, disabled } = actionButton;
              return (
                <Button
                  size="small"
                  variant="gradient"
                  color={color}
                  onClick={onClick}
                  to={to}
                  disabled={disabled}
                >
                  {label}
                </Button>
              );
            })}
          </Stack>
        </Grid>
      )}
      <MKTable
        columns={headers}
        rows={rows}
        onClickHeader={onSort}
        getRowProps={typeof onClickRow === 'function' ? (row, idx) => ({
          hover: true,
          sx: { cursor: 'pointer' },
          onClick: () => {
            const clickedItem = data[idx];
            return Promise.resolve(onClickRow(clickedItem[idField]))
              .then(() => {
                const targetRoute = typeRouting[clickedItem?.type] || typeRouting?.default;
                if (targetRoute) {
                  navigate(typeRouting[clickedItem?.type] || typeRouting?.default);
                }
              });
          },
        }) : null}
      />
      {!paginationDisabled && (
        <TablePagination
          component="div"
          showFirstButton
          showLastButton
          count={totalCount}
          page={currentPage}
          onPageChange={(e, page) => onPageChange(page)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => onRowsPerPageChange(e.target.value)}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={({ from, to, count, page }) => `${from} ${localeMapping.labels_to} ${to} ${localeMapping.labels_of} ${count !== -1 ? count : `${localeMapping.labels_more_than} ${to}`}`}
          labelRowsPerPage={localeMapping.labels_page_size}
          ActionsComponent={withProps(TablePageActionButtonGroup, { buttonProps: pageButtonProps })}
          sx={{
            '.MuiTablePagination-spacer': {
              display: 'none',
            },
          }}
        />
      )}
      <AnchorMenu
        items={objectTypeOptions}
        onClickItem={onPressAdd}
        anchor={menuAnchor}
        setAnchor={setMenuAnchor}
      />
    </MKBox>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string,
      to: PropTypes.string,
      href: PropTypes.string,
      urlParams: PropTypes.arrayOf(PropTypes.string),
      formatter: PropTypes.func,
      content: PropTypes.node,
      renderContent: PropTypes.func,
      onClick: PropTypes.func,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  idField: PropTypes.string,
  selectable: PropTypes.bool,
  multirowSelection: PropTypes.bool,
  objectTypes: PropTypes.object,
  typeRouting: PropTypes.object,
  onSelectRow: PropTypes.func,
  onClickRow: PropTypes.func,
  onPressAdd: PropTypes.func,
  onPressEdit: PropTypes.func,
  onPressDelete: PropTypes.func,
  onPressCopy: PropTypes.func,
  onPressPaste: PropTypes.func,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onRowsPerPageChange: PropTypes.func,
  onPressUpdateIP: PropTypes.func,
  onPressUpdateFloorPolygon: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  paginationDisabled: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  pageButtonProps: PropTypes.object,
  addButtonLabel: PropTypes.string,
  editButtonLabel: PropTypes.string,
  deleteButtonLabel: PropTypes.string,
  copyButtonLabel: PropTypes.string,
  pasteButtonLabel: PropTypes.string,
  onPressReboot: PropTypes.func,
  onPressUpgradeAI: PropTypes.func,
  onPressUpgradeWatchdog: PropTypes.func,
  onPressUpdateUrl: PropTypes.func,
};

DataTable.defaultProps = {
  columns: [],
  data: [],
  idField: 'id',
  selectable: false,
  multirowSelection: false,
  objectTypes: [],
  typeRouting: {},
  onSelectRow: null,
  onClickRow: null,
  onPressAdd: null,
  onPressEdit: null,
  onPressDelete: null,
  onPressCopy: null,
  onPressPaste: null,
  onPressUpdateIP: null,
  onPressUpdateFloorPolygon: null,
  onPressUpgradeAI: null,
  onPressUpgradeWatchdog: null,
  onPressUpdateUrl: null,
  onPressReboot: null,
  totalCount: 0,
  currentPage: 0,
  onPageChange: () => {},
  rowsPerPage: 10,
  onRowsPerPageChange: () => {},
  rowsPerPageOptions: [5, 10, 15, 20],
  paginationDisabled: false,
  sortBy: null,
  sortDirection: null,
  onSort: () => {},
  pageButtonProps: {},
  addButtonLabel: 'Add',
  editButtonLabel: 'Edit',
  deleteButtonLabel: 'Delete',
  copyButtonLabel: 'Copy',
  pasteButtonLabel: 'Paste',
};

export default DataTable;
