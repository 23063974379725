import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUser, updateUser, createUser, getUserRoles, createUserClient } from 'api/users';
import { getWard } from 'api/alpha/wards';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getErrorMessage, handleErrorResponse } from 'utils/general';
import { withLoading } from 'utils/hoc';
import { getPatients } from 'api/alpha/patients';
import { useAuth } from 'contexts/auth';
import Button from 'components/Button';
import { Grid } from '@mui/material';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { createUserServiceProvider, deleteUserServiceProvider, getServiceProviders } from 'api/alpha/service_providers';
import { getLocaleMap } from 'utils/locales';
import AVGuestRecordTable from './guestRecordTable';
import EditUserForm from './EditUserForm';

const ResourceNotFound = () => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_resource_not_found', 'guest_name', 'family_member_name', 'center', 'ward_location', 'contact_number', 'email', 'user_management', 'edit'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4>
          {lm.labels_resource_not_found}
        </h4>
      </Container>
    </MKBox>
  );
};

const EditUserSection = ({ setLoading }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_edit', 'labels_create', 'labels_user'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const [user, setUser] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [requestError, setRequestError] = useState(false);
  const [guestData, setGuestData] = useState({});
  const [wardData, setWardData] = useState({});
  const [serviceProviders, setServiceProviders] = useState([]);
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userId = searchParams.get('username');
  const isEdit = searchParams.get('edit');

  const fetchUserRolesFromApi = useCallback(() => {
    return getUserRoles()
      .then(({ data }) => {
        setUserRoles(data);
      })
      .catch((error) => {
        handleErrorResponse(error, setAuth);
      });
  }, [setAuth]);

  const getLocationData = useCallback((wardId) => {
    if (!wardId) {
      return Promise.resolve();
    }
    return getWard(wardId, { $expand: 'center_id/service_provider_id' })
      .then(({ data }) => {
        setWardData(data);
      })
      .catch((error) => {
        handleErrorResponse(error, setAuth);
      });
  }, [setAuth]);

  const getGuestData = useCallback((username) => {
    return getPatients({ 'family_username[eq]': username })
      .then(({ data }) => {
        setGuestData(data[0]);
      })
      .catch((error) => {
        handleErrorResponse(error, setAuth);
      });
  }, [setAuth]);

  const fetchUserFromApi = useCallback(() => {
    if (!userId) {
      return Promise.resolve();
    }
    setLoading(true);
    return getUser(userId, { $expand: 'user_service_providers/service_provider' })
      .then(({ data }) => {
        setUser(data);
        return data;
      })
      .then((fetchedUser) => {
        if (fetchedUser.role === 3 && fetchedUser.username) {
          return getGuestData(fetchedUser.username);
        }
      })
      .catch((error) => {
        setRequestError(true);
        handleErrorResponse(error, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, setLoading, getGuestData, setAuth]);

  useEffect(() => {
    getLocationData(guestData?.ward);
  }, [getLocationData, guestData?.ward]);

  const updateUserToApi = useCallback((values, { setFieldError }) => {
    if (!userId) {
      return Promise.resolve();
    }
    const updateBody = {
      display_name: values.display_name,
      role: values.role,
      phone: values.phone,
    };
    setLoading(true);
    const userServiceProviders = values.user_service_providers;
    const prevServiceProviderIds = values.initial_service_providers;
    const selectedServiceProviderIds = values.selected_service_providers;
    const newUserServiceProviderIds = selectedServiceProviderIds.filter((selectedId) => !prevServiceProviderIds.includes(selectedId));
    const deleteUserServiceProviderIds = [];
    prevServiceProviderIds.forEach((prevId) => {
      if (!selectedServiceProviderIds.includes(prevId)) {
        const targetUserServiceProvider = userServiceProviders.find((userServiceProvider) => userServiceProvider.service_provider.service_provider_id === prevId);
        deleteUserServiceProviderIds.push(targetUserServiceProvider.user_service_provider_id);
      }
    });
    const userServiceProviderPromises = [];
    newUserServiceProviderIds.forEach((newId) => {
      const postBody = {
        user: values.email,
        service_provider: newId,
      };
      userServiceProviderPromises.push(createUserServiceProvider(postBody));
    });
    deleteUserServiceProviderIds.forEach((deleteId) => {
      userServiceProviderPromises.push(deleteUserServiceProvider(deleteId));
    });
    return Promise.all(userServiceProviderPromises)
      .then(() => {
        return updateUser(userId, updateBody)
          .then(({ data }) => {
            setUser(data);
            navigate(-1);
          })
          .catch((err) => {
            setFieldError('form', err.message);
          })
          .finally(() => {
            setLoading(false);
          });
      });
  }, [userId, setLoading, navigate]);

  const createUserToApi = useCallback((values, { setFieldError }) => {
    const createBody = {
      display_name: values.display_name,
      username: values.email,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
      phone: values.phone,
      role: values.role,
    };
    setLoading(true);
    let promise;
    if (values.role === 3) {
      promise = createUserClient(createBody);
    } else {
      promise = createUser(createBody);
    }
    return Promise.resolve(promise)
      .then(({ data }) => {
        const newServiceProviderIds = values.selected_service_providers;
        const userServiceProviderPromises = newServiceProviderIds.map((newId) => {
          const postBody = {
            user: values.email,
            service_provider: newId,
          };
          return createUserServiceProvider(postBody);
        });

        return Promise.all(userServiceProviderPromises)
          .then(() => navigate(-1));
      })
      .catch((err) => {
        setFieldError('form', getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, navigate]);

  const fetchServiceProviders = useCallback(() => {
    return getServiceProviders()
      .then(({ data }) => {
        setServiceProviders(data);
      })
      .catch((error) => {
        handleErrorResponse(error, setAuth);
      });
  }, [setAuth, setServiceProviders]);

  useEffect(() => {
    fetchUserFromApi();
  }, [fetchUserFromApi]);

  useEffect(() => {
    fetchUserRolesFromApi();
  }, [fetchUserRolesFromApi]);

  useEffect(() => {
    fetchServiceProviders();
  }, [fetchServiceProviders]);

  if (requestError) {
    return <ResourceNotFound />;
  }

  const editSection = () => {
    return (
      <Container>
        <h4 style={{ marginBottom: 10 }}>
          {`${userId ? lm.labels_edit : lm.labels_create} ${lm.labels_user}`}
        </h4>
        <EditUserForm
          user={user}
          onSave={userId ? updateUserToApi : createUserToApi}
          roles={userRoles}
          serviceProviders={serviceProviders}
        />
      </Container>
    );
  };

  const viewSection = () => {
    if (user) {
      return (
        <Container>
          <Grid container item xs={6} spacing={0}>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {`${lm.family_member_name}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {user.display_name}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {`${lm.guest_name}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {guestData?.name || 'N/A'}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {`${lm.center} (${lm.ward_location}):`}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {`${wardData?.center_id?.service_provider_id?.name || 'Not Set'}/${wardData?.center_id?.name || 'Not Set'}/${wardData?.name || 'Not Set'}`}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {`${lm.contact_number}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {user?.phone}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {`${lm.email}:`}
              </MKTypography>
            </Grid>
            <Grid item xs={6}>
              <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
                {user?.email}
              </MKTypography>
            </Grid>
          </Grid>

          <AVGuestRecordTable guest_id={guestData?.patient_identifier} patient_id={guestData?.patient_id} />

        </Container>
      );
    }
  };

  if (!userRoles.length) {
    return null;
  }
  return (
    <MKBox>
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py="1rem">
        <AVBreadcrumb startingNode={lm.user_management} showBackButton />
      </MKBox>
      { user?.role === 3 && !isEdit ? viewSection() : editSection() }
    </MKBox>
  );
};

EditUserSection.propTypes = {
  setLoading: PropTypes.func,
};

export default withLoading(EditUserSection);
