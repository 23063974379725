import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { datetimeFormatter } from 'utils/datetime';
import { createDynamicTableRow, deleteDynamicTableRow, getDynamicTableRows, updateDynamicTableRow } from 'api/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { pushAlert, updateAlert } from 'api/alpha/alerts';
import { useWards } from 'contexts/wards';
import { useSignals } from 'contexts/signals';
import { getLocaleMap } from 'utils/locales';
import ReportForm from './reportForm';

const attributesMap = {
  ward_name: '4b3c7394-7778-47ab-aa52-3a758fe44408',
  ward_id: '0adca14a-6df2-4288-998c-2e4b2f469051',
  service_provider_id: '928ccd10-94fe-4edb-a8f1-49a6d5d888de',
  service_provider_name: 'c9826f13-37d7-4064-9d66-f83c7d34831f',
  center_id: 'de0b764d-4336-4b51-9a57-aed39d8c424e',
  center_name: '1b924861-2e79-448e-bb18-4c31d418f4f7',
  reported_by: '2835d4b8-e5b1-4faf-be31-e01d5e8c2e2f',
  guest_info: '8219d25c-3392-4786-b82f-3c2ef375492e',
  guest_id: '45e79154-e499-4a48-8d35-0f2413c4f438',
  patient_id: '01928f9c-c971-7133-9686-a7a324e034a6',
  device_physical_id: '87b94689-5725-4fc3-8e5f-95f2f304cf27',
  details: '48a92535-ebd4-462c-98e3-93ab5d568405',
  accident_time: '0dd23c0a-9afe-4b53-89a1-5a20b54f8450',
  tags: '1a977995-5dad-4fd2-87d3-79b4b0937c2a',
  false_alarm: '1ccb744b-b46b-4bef-9b40-d7c3d599f95e',
  guest_name: 'b8e31d7e-abdd-40fe-91ff-30171a376242',
  alert_id: '89d89c33-58e9-4414-9385-b6a2d43d6aad',
  camera_id: '9bcf870c-0f0a-4c91-8694-cb03374ce18e',
};

const saveAttributesMap = {
  ward_name: '6f646373-807d-4d91-9b9d-9125630c1a4d',
  ward_id: '5671c71e-405a-4096-940a-cd2553356c25',
  service_provider_id: '64f3fd79-609c-44bf-9bf1-94abcd2ea127',
  service_provider_name: '06093070-ed5e-4455-b808-6c9fa989a9bb',
  center_id: '89f1741b-0d9c-4f89-a9ea-1cc587f341f1',
  center_name: '9f17c2ab-532d-425f-8e2c-d6afec8b9c94',
  reported_by: '1996a4b4-3c73-473d-a793-993b1024cb24',
  guest_info: '5e29d127-c319-4636-b176-7742da5d4e55',
  guest_id: '03eeea4a-3db8-474e-8550-1dd20d1d4991',
  patient_id: '019294bd-74cc-7be1-9796-466290a8c09f',
  guest_name: '782c209f-2da3-45bb-a64a-fe1128bd75ab',
  device_physical_id: '0eb312fa-288f-46c5-86a5-6d08224c2e1a',
  details: '3e77037c-07ea-4d3a-bca9-a63952f3155f',
  accident_time: '1cd0fd3c-223c-45cf-a866-35224f49c260',
  tags: '21848470-f38e-47b0-8d58-66fe4d72ad6e',
  false_alarm: '72c772dc-0373-4f63-804f-4e746c18d814',
  alert_id: '22642fdf-28a9-449e-8e20-2a0360e463f6',
  camera_id: '8fb73b78-9900-4b1c-b3bd-cf449daebbae',
};

const ReportSubmissionPage = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['guest_info', 'guest_name', 'problem_solve_report', 'issue_report', 'ward', 'accident_tag', 'camera_id', 'guest_id', 'happened_date', 'detail_report'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { wards, setWards, reduceWardAlerts } = useWards();
  const { setSignal } = useSignals();
  const [info, setInfo] = useState(null);
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [savedForm, setSavedForm] = useState(null);
  const alertId = searchParams.get('alert_id');
  const wardId = searchParams.get('ward_id');

  useEffect(() => {
    if (wardId && alertId) {
      setInfo(wards?.map((ward) => {
        if (ward.ward_id === wardId) {
          return { ...ward, accident: ward?.accidents?.find((accident) => accident.alert_id === alertId) };
        }
        return null;
      }).filter(Boolean)[0]);
    }
  }, [wardId, alertId, wards]);

  const handleSave = (values) => {
    const body = {};
    Object.keys(values).forEach((key) => {
      body[saveAttributesMap[key]] = values[key];
    });
    body[saveAttributesMap.center_id] = body[saveAttributesMap.center_id].center_id;
    const createBody = {
      json_short_data: JSON.stringify(body),
    };
    if (savedForm) {
      updateDynamicTableRow('bcb6d211-9308-45d8-a1da-0e4527d01481', savedForm.rowId, createBody)
        .then((res) => {
          if (res.status === 200) {
            setSignal(true);
            navigate('/');
          }
        });
    } else {
      createDynamicTableRow('bcb6d211-9308-45d8-a1da-0e4527d01481', createBody)
        .then((res) => {
          if (res.status === 200) {
            setSignal(true);
            navigate('/');
          }
        });
    }
  };

  const handleNotificationPush = () => {
    // eslint-disable-next-line no-console
    console.log('Sending notification to info', info);
    const serviceProviderName = info?.center_id?.service_provider_id?.name;
    const centerName = info?.center_id?.name;
    const wardName = info?.name;
    const body = {
      title: 'Ward Alert Resolved',
      content: `An emergency alert from ${serviceProviderName}/${centerName}/${wardName} has been resolved`,
    };
    console.log('Sending notification to mobile app', body);
    const username = info?.accident?.patient?.family_username?.username;
    return pushAlert(username, body)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log('Notification sent successfully', response);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending notification', error);
      });
  };

  const handleSubmit = (values) => {
    const body = {};
    Object.keys(values).forEach((key) => {
      body[attributesMap[key]] = values[key];
    });
    const createBody = {
      json_short_data: JSON.stringify(body),
    };
    createDynamicTableRow('3ffd1633-4903-40f5-8520-f898e57d6c3b', createBody)
      .then((res) => {
        if (res.status === 200) {
          if (savedForm) {
            deleteDynamicTableRow('bcb6d211-9308-45d8-a1da-0e4527d01481', savedForm.rowId);
          }
          updateAlert(alertId, { status: 'resolved' });
          reduceWardAlerts(wardId, alertId);
          setSignal(true);
          Promise.resolve(handleNotificationPush()).then(() => {
            navigate(`/history/reports/ward-reports?center_id=${values.center_id}&ward_id=${values.ward_id}&ward_name=${values.ward_name}&center_name=${values.center_name}`);
            window.location.reload();
          });
        }
      });
  };

  const fetchCollectionDefinition = useCallback(() => {
    getCollectionDefinition('bcb6d211-9308-45d8-a1da-0e4527d01481', { $expand: 'attributes' })
      .then((res) => {
        setCollectionDefinition(res.data);
      });
  }, []);

  const checkForSavedForm = useCallback(() => {
    getDynamicTableRows('bcb6d211-9308-45d8-a1da-0e4527d01481')
      .then(({ data }) => {
        data.forEach((row) => {
          const dynObj = getDynamicData(row, collectionDefinition?.attributes);
          if (dynObj?.alert_id === alertId) {
            dynObj.rowId = row.id;
            setSavedForm(dynObj);
          }
        });
      });
  }, [alertId, collectionDefinition?.attributes]);

  useEffect(() => {
    if (!collectionDefinition) {
      fetchCollectionDefinition();
    }
    checkForSavedForm();
  }, [checkForSavedForm, collectionDefinition, fetchCollectionDefinition]);

  if (!alertId || !wardId) {
    return null;
  }

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="#3583C5">
          {localeMapping.problem_solve_report}
        </MKTypography>
      </MKBox>
      <MKBox display="flex" flexDirection="column" justifyContent="start" alignItems="left" fontSize="14px">
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {localeMapping.issue_report}
        </MKTypography>

        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.ward}: ${info?.name}`}
        </MKTypography>

        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {localeMapping.accident_tag}
        </MKTypography>

        <MKBox display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">

          <MKBox py={1} px={2} sx={{ backgroundColor: '#C5010A', borderRadius: '8px' }} mr={1}>
            <MKTypography variant="body1" fontSize="18px" fontWeight="bold" color="white">{info?.accident?.tag}</MKTypography>
          </MKBox>

        </MKBox>

        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.camera_id}: ${info?.accident?.camera?.camera_identifier}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.guest_info}: ${info?.accident?.patient?.patient_info}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.guest_id}: ${info?.accident?.patient?.patient_identifier}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.guest_name}: ${info?.accident?.patient?.name}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.happened_date}: ${datetimeFormatter(info?.accident?.accident_time, 'YYYY-MM-DD HH:mm:ss')}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.detail_report}:`}
        </MKTypography>
        <ReportForm info={info} initialValues={savedForm || info} onSave={handleSave} onSubmit={handleSubmit} />
      </MKBox>
    </MKBox>
  );
};

export default ReportSubmissionPage;
