import api from 'api';

export const getEventTriggers = (params) => {
  return api.get('/event_triggers', params, 'bearer');
};

export const getEventTrigger = (id, params) => {
  return api.get(`/event_triggers/${id}`, params, 'bearer');
};

export const updateEventTrigger = (id, body) => {
  return api.put(`/event_triggers/${id}`, body, 'bearer');
};

export const createEventTrigger = (body) => {
  return api.post('/event_triggers', body, 'bearer');
};

export const deleteEventTrigger = (id) => {
  return api.delete(`/event_triggers/${id}`, 'bearer');
};
