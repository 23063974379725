import PropTypes from 'prop-types';
import MKTypography from 'components/MaterialKit/MKTypography';
import { tryGetLocalizedText } from 'utils/locales';
import { useTranslation } from 'react-i18next';

const TextSection = ({ text, variant, align, color, font_size, font_weight, is_rich_text, editable, onPressEdit, ...props }) => {
  const { t, i18n: { language } } = useTranslation('Portal');
  if (text) {
    const textContent = t('User Management') || tryGetLocalizedText(text, language);
    const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
    return (
      <MKTypography
        variant={variant}
        textAlign={align}
        color={color}
        fontSize={formattedFontSize}
        fontWeight={font_weight}
        {...(is_rich_text ? {
          dangerouslySetInnerHTML: {
            __html: textContent || '',
          },
        } : {
          children: textContent || '',
        })}
        {...props}
      />
    );
  }
};

TextSection.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  font_weight: PropTypes.string,
  is_rich_text: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default TextSection;
