import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKButton from 'components/MaterialKit/MKButton';
import TextInput from 'components/TextInput';
import { Formik } from 'formik';

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  message: '',
};
const ContactFormSection = ({ ...props }) => {
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleChecked = useCallback(() => setAgreeTerms((val) => !val), []);

  const onSubmit = useCallback((values, { setFieldError }) => {
    alert(JSON.stringify(values, 0, 2));
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox width="100%" component="form" method="post" autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextInput
                  name="first_name"
                  label="First Name"
                  value={values.first_name}
                  onChange={handleChange('first_name')}
                  onBlur={handleBlur('first_name')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name="last_name"
                  label="Last Name"
                  value={values.last_name}
                  onChange={handleChange('last_name')}
                  onBlur={handleBlur('last_name')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="email"
                  type="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="message"
                  label="Your Message"
                  value={values.message}
                  onChange={handleChange('message')}
                  onBlur={handleBlur('message')}
                  multiline
                  rows={6}
                />
              </Grid>
              <Grid item xs={12} alignItems="center" ml={-1}>
                <Switch checked={agreeTerms} onChange={handleChecked} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  sx={{ cursor: 'pointer', userSelect: 'none' }}
                  onClick={handleChecked}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </MKTypography>
                <MKTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="regular"
                  color="white"
                >
                  Terms and Conditions
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} my={2}>
              <MKButton
                variant="gradient"
                color="success"
                onClick={handleSubmit}
                type="submit"
                disabled={!agreeTerms}
                fullWidth
              >
                SEND MESSAGE
              </MKButton>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

ContactFormSection.propTypes = {

};

export default ContactFormSection;
