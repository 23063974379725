import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKInput from 'components/MaterialKit/MKInput';
import MKButton from 'components/MaterialKit/MKButton';
import { INPUT_TYPE_IMAGE, INPUT_TYPE_VIDEO } from 'components/InputField';
import Modal from 'components/Modal';
import Image from 'components/Image';
import VideoPlayer from 'components/VideoPlayer';
import { Grid } from '@mui/material';
import Select from 'components/Select';
import { getFiles, deleteFile } from 'api/files';
import { handleErrorResponse, isUuid } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import CopyToClipboard from 'react-copy-to-clipboard';
import get from 'lodash/get';
import { formatFileSize } from 'utils/file';
import mime from 'mime';

const editAssetsPageUrl = '/assets/edit/';

const orderbyOptions = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
];
const orderbyFieldOptions = [
  { label: 'File Id', value: 'file_id' },
  { label: 'File Name', value: 'file_name' },
  { label: 'File Size', value: 'file_size' },
  { label: 'Created At', value: 'createddate' },
  { label: 'Modified At', value: 'lastmoddate' },
];

const limitOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const iconsFontSize = '20px';

const AssetList = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [currentFile, setCurrentFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchAuthorQuery, setSearchAuthorQuery] = useState('');
  const [orderbyField, setOrderbyField] = useState('createddate');
  const [orderby, setOrderby] = useState('desc');
  const [limit, setLimit] = useState(20);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [previewOpen, setPreviewOpen] = useState(false);

  const inputType = useMemo(() => {
    const fileMimeType = mime.getType(currentFile?.file_url) || '';
    return fileMimeType.split('/')[0] || '';
  }, [currentFile?.file_url]);

  const fetchFilesFromApi = useCallback((params) => {
    getFiles(params)
      .then(({ headers, data }) => {
        const contentRange = get(headers, 'content-range');
        const dataCount = Number(contentRange.split('/')[1]);
        const calculatedTotalPages = Math.floor(dataCount / limit) + (dataCount % limit === 0 ? 0 : 1);
        if (page > calculatedTotalPages) {
          setPage(1);
          return;
        }
        setFiles(data);
        setTotalFiles(dataCount);
        setTotalPages(calculatedTotalPages);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [limit, page, setAuth]);

  useEffect(() => {
    const params = {
      $orderby: `${orderbyField} ${orderby}`,
      ...(searchQuery && { 'file_name[like]': `%${searchQuery}%` }),
      ...(searchAuthorQuery && { 'created_by[like]': `%${searchAuthorQuery}%` }),
      ...(limit && { $top: limit }),
      ...(page !== 1 && { $skip: limit * (page - 1) }),
    };
    fetchFilesFromApi(params);
  }, [fetchFilesFromApi, limit, orderby, orderbyField, page, searchAuthorQuery, searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAuthorSearchChange = (event) => {
    setSearchAuthorQuery(event.target.value);
  };

  const onPressEdit = (fileId) => {
    navigate(`${editAssetsPageUrl}${fileId}`);
  };

  const onPressDelete = (fileId) => {
    if (!isUuid(fileId)) {
      return;
    }
    deleteFile(fileId)
      .then((res) => {
        if (res.status === 204) {
          const params = {
            $orderby: `${orderbyField} ${orderby}`,
            ...(searchQuery && { 'file_name[like]': `%${searchQuery}%` }),
            ...(searchAuthorQuery && { 'created_by[like]': `%${searchAuthorQuery}%` }),
            ...(limit && { $top: limit }),
            ...(page !== 1 && { $skip: limit * (page - 1) }),
          };
          fetchFilesFromApi(params);
        }
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  };

  const onPressView = (file) => {
    setCurrentFile(file);
    setPreviewOpen(true);
  };

  const onUploadNewAssetPressed = ({ type }) => {
    navigate(`${editAssetsPageUrl}-1`, { state: { type } });
  };

  const getFileNameLabel = (fileName) => {
    return fileName.length > 50 ? `${fileName.substring(0, 50)}...` : fileName;
  };

  const filesTable = () => {
    return (
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="left"
        sx={{ pb: 1, pt: 0.5, px: 0.4, border: '1px solid #000', borderRadius: '5px' }}
      >
        {files.map((file, index) => (
          <MKBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ px: 1, py: 0.15, mb: 1, backgroundColor: index % 2 === 0 ? '#cfcfcf' : '#dbdbdb', borderRadius: '5px' }}
          >
            <MKBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ flex: 1 }}
            >
              <MKTypography variant="body1" color="black" fontSize="16px" sx={{ flex: 1 }}>
                {getFileNameLabel(file.file_name)}
              </MKTypography>
              <MKTypography variant="body1" color="#2e2e2e" fontSize="12px">
                {formatFileSize(file.file_size)}
              </MKTypography>
            </MKBox>
            <MKBox sx={{ ml: 1 }}>
              <IconButton variant="text" onClick={() => { console.log('Copied Link:', file.file_url); }} sx={{ fontSize: iconsFontSize }}>
                <CopyToClipboard text={file.file_url}>
                  <LinkIcon />
                </CopyToClipboard>
              </IconButton>
              <IconButton variant="text" onClick={() => { console.log('Copied id:', file.file_id); }} sx={{ fontSize: iconsFontSize }}>
                <CopyToClipboard text={file.file_id}>
                  <ContentCopyIcon />
                </CopyToClipboard>
              </IconButton>
              <IconButton variant="text" onClick={() => { onPressView(file); }} sx={{ fontSize: iconsFontSize }}>
                <VisibilityIcon />
              </IconButton>
              <IconButton variant="text" onClick={() => { onPressEdit(file.file_id); }} sx={{ fontSize: iconsFontSize }}>
                <EditIcon />
              </IconButton>
              <IconButton variant="text" color="error" onClick={() => { onPressDelete(file.file_id); }} sx={{ fontSize: iconsFontSize }}>
                <DeleteIcon />
              </IconButton>
            </MKBox>
          </MKBox>
        ))}
      </MKBox>
    );
  };

  const noFiles = () => {
    return (
      <MKBox
        display="flex"
        sx={{ p: 1, border: '1px solid #000', borderRadius: '5px' }}
      >
        <MKTypography variant="body1" color="black" fontSize="16px">
          No files found
        </MKTypography>
      </MKBox>
    );
  };

  const handlePlus = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleMinus = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <MKBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2} lg={2}>
          <MKInput type="text" placeholder="Search by filename" value={searchQuery} onChange={handleSearchChange} sx={{ mb: 1, width: '100%' }} />
          <MKInput type="text" placeholder="Search by author" value={searchAuthorQuery} onChange={handleAuthorSearchChange} sx={{ mb: 1, width: '100%' }} />
          <Select label="Order By" value={orderbyField} onChange={(v) => setOrderbyField(v)} options={orderbyFieldOptions} sx={{ mb: 1 }} />
          <Select label="Order" value={orderby} onChange={(v) => setOrderby(v)} options={orderbyOptions} sx={{ mb: 1 }} />
          <Select label="Limit" value={limit} onChange={(v) => setLimit(v)} options={limitOptions} sx={{ mb: 1 }} />
          <MKBox
            display="flex"
            flexDirection="column"
            sx={{ border: '1px solid lightgray', borderRadius: '5px', mb: 1, px: 1 }}
          >
            <MKBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mt: 1 }}
            >
              <MKButton color="primary" size="small" disabled={page <= 1} onClick={() => { handleMinus(); }}> &lt; </MKButton>
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`${page} / ${totalPages}`}
              </MKTypography>
              <MKButton color="primary" size="small" disabled={page >= totalPages} onClick={() => { handlePlus(); }}> &gt; </MKButton>
            </MKBox>
            <MKBox
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="start"
              sx={{ mt: 0.5 }}
            >
              <MKTypography variant="body1" color="black" fontSize="16px">
                {`From ${page === 1 ? 1 : limit * (page - 1) + 1} to ${(limit * page) > totalFiles ? (totalFiles) : (limit * page)} of ${totalFiles || 0}`}
              </MKTypography>
            </MKBox>
          </MKBox>
          <MKButton
            onClick={() => onUploadNewAssetPressed({ type: INPUT_TYPE_IMAGE })}
            fullWidth
            color="primary"
            sx={{ py: 1 }}
          >
            Upload Image
          </MKButton>
          <MKButton
            onClick={() => onUploadNewAssetPressed({ type: INPUT_TYPE_VIDEO })}
            fullWidth
            color="secondary"
            sx={{ py: 1, mt: 1 }}
          >
            Upload Video
          </MKButton>
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          {files.length ? filesTable() : noFiles()}
        </Grid>
      </Grid>
      <Modal
        showHeader={false}
        isOpen={previewOpen}
        onClose={() => setPreviewOpen(false)}
        p={1}
      >
        <MKBox display="flex" justifyContent="center" alignItems="center" sx={{ position: 'relative', width: '70vw', height: '70vh' }}>
          {inputType === INPUT_TYPE_IMAGE && (
            <Image
              src={currentFile?.file_url}
              width="100%"
              height="100%"
            />
          )}
          {inputType === INPUT_TYPE_VIDEO && (
            <VideoPlayer
              url={currentFile?.file_url}
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          )}
        </MKBox>
      </Modal>
    </MKBox>
  );
};

export default AssetList;
