import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';

const PatientForm = ({ patient, users, isEdit, onSave, ...props }) => {
  const initialValues = useMemo(() => {
    return {
      name: patient?.name || '',
      patient_identifier: patient?.patient_identifier || '',
      patient_info: patient?.patient_info || '',
      family_username: patient?.family_username || '',
      ward_id: patient?.ward_id || '',
      radar_id: patient?.radar_id || '',
      sos_id: patient?.sos_id || '',
    };
  }, [patient]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                <MKInput
                  name="name"
                  label="Patient Name"
                  value={values.name || ''}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="patient_identifier"
                  label="Patient ID"
                  value={values.patient_identifier || ''}
                  onChange={handleChange('patient_identifier')}
                  onBlur={handleBlur('patient_identifier')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="phone"
                  label="Phone"
                  value={values.phone || ''}
                  onChange={handleChange('phone')}
                  onBlur={handleBlur('phone')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="address"
                  label="Address"
                  value={values.address || ''}
                  onChange={handleChange('address')}
                  onBlur={handleBlur('address')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="patient_info"
                  label="Patient Info"
                  multiline
                  maxRows={10}
                  minRows={5}
                  value={values.patient_info || ''}
                  onChange={handleChange('patient_info')}
                  onBlur={handleBlur('patient_info')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <Select
                  value={values.family_username}
                  label="Patient Family Member User"
                  onChange={handleChange('family_username')}
                  onBlur={handleBlur('family_username')}
                  options={users}
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="radar_id"
                  label="Radar ID"
                  value={values.radar_id || ''}
                  onChange={handleChange('radar_id')}
                  onBlur={handleBlur('radar_id')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="sos_id"
                  label="SOS ID"
                  value={values.sos_id || ''}
                  onChange={handleChange('sos_id')}
                  onBlur={handleBlur('sos_id')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={10}>
                &nbsp;
              </Grid>
              <Grid container item xs={2}>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

PatientForm.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    ward_id: PropTypes.string,
    patient_identifier: PropTypes.string,
    patient_info: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    family_username: PropTypes.string,
    radar_id: PropTypes.string,
    sos_id: PropTypes.string,
  }),
  users: PropTypes.array,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

PatientForm.defaultProps = {
  patient: {},
  users: [],
  isEdit: false,
  onSave: () => {},
};

export default PatientForm;
