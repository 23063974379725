import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';

const Breadcrumbs = ({ routes, separator, maxItems, labelProps, ...props }) => {
  return (
    <MKBox width="100%">
      <MuiBreadcrumbs
        separator={separator}
        maxItems={maxItems}
        {...props}
      >
        {routes.map(({ label, to, active, ...rest }) => (
          <MKTypography
            key={label}
            fontWeight={active ? 'medium' : 'regular'}
            {...(to ? {
              to,
              component: Link,
              sx: {
                '&:hover, &:focus': {
                  color: ({ palette: { secondary } }) => secondary.focus,
                },
              },
            } : {})}
            {...labelProps}
            {...rest}
          >
            {label}
          </MKTypography>
        ))}
      </MuiBreadcrumbs>
    </MKBox>
  );
};

Breadcrumbs.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
  separator: PropTypes.node,
  maxItems: PropTypes.number,
  labelProps: PropTypes.object,
};

Breadcrumbs.defaultProps = {
  routes: [],
  separator: <ArrowForwardIosIcon />,
  maxItems: 5,
  labelProps: {},
};

export default Breadcrumbs;
