import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { getWard } from 'api/alpha/wards';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';
import { getLocaleMap } from 'utils/locales';

const WardInfoListViewSection = ({ ward }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'patients', 'cameras'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [searchParams] = useSearchParams();
  const providerName = searchParams.get('providerName') || 'Unknown Provider';
  const centerName = searchParams.get('centerName') || 'Unknown Center';
  const tableColumns = useMemo(() => [
    { field: 'name', label: 'Item' },
    { field: 'amount', label: 'Amount' },
    { field: 'lastmoddate', label: 'Last Modified' },
  ], []);

  const [data, setData] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchPatientsFromApi = useCallback(() => {
    if (!ward?.ward_id) return Promise.resolve();
    const requestParams = {};
    requestParams.$expand = 'av_devices/cameras,patients';
    return getWard(ward?.ward_id, requestParams)
      .then((response) => {
        setData(response.data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, ward?.ward_id]);

  const onPressEdit = useCallback((itemId) => {
    if (itemId === '1') {
      navigate(`/providers/patients/ward_overview?ward_id=${ward?.ward_id}&providerName=${providerName}&centerName=${centerName}&wardName=${ward?.name}&edit=true`);
    } else if (itemId === '2') {
      navigate(`/providers/devices/ward_overview?ward_id=${ward?.ward_id}&providerName=${providerName}&centerName=${centerName}&wardName=${ward?.name}&edit=true`);
    } else if (itemId === '3') {
      navigate(`/providers/cameras/ward_overview?ward_id=${ward?.ward_id}&edit=true`);
    }
  }, [centerName, navigate, providerName, ward?.name, ward?.ward_id]);

  useEffect(() => {
    fetchPatientsFromApi();
  }, [fetchPatientsFromApi]);

  const rowData = useMemo(() => {
    if (!data) return [];
    const rows = {};
    try {
      const latestDeviceObject = data?.av_devices.reduce((acc, curr, index) => {
        if (index === 0) return curr;
        if (curr?.lastmoddate > acc?.lastmoddate) {
          return curr;
        }
        return acc;
      });
      rows.latestDeviceMod = latestDeviceObject.lastmoddate;
    } catch (e) {
      rows.latestDeviceMod = null;
    }

    try {
      const latestPatientObject = data?.patients.reduce((acc, curr, index) => {
        if (index === 0) return curr;
        if (curr?.lastmoddate > acc?.lastmoddate) {
          return curr;
        }
        return acc;
      });
      rows.latestPatientMod = latestPatientObject.lastmoddate;
    } catch (e) {
      rows.latestPatientMod = null;
    }

    const cameras = [];
    data?.av_devices?.forEach((device) => {
      if (device.cameras.length !== 0) {
        cameras.push(...device.cameras);
      }
    }, []);

    try {
      const latestCameraObject = cameras.reduce((acc, curr, index) => {
        if (index === 0) return curr;
        if (curr?.lastmoddate > acc?.lastmoddate) {
          return curr;
        }
        return acc;
      });
      rows.latestCameraMod = latestCameraObject.lastmoddate;
    } catch (e) {
      rows.latestCameraMod = null;
    }

    return [
      { id: '1', name: localeMapping.patients, amount: data?.patients?.length, lastmoddate: datetimeFormatter(rows?.latestPatientMod, 'YYYY-MM-DD HH:mm:ss') },
      { id: '2', name: localeMapping.devices, amount: data?.av_devices?.length, lastmoddate: datetimeFormatter(rows?.latestDeviceMod, 'YYYY-MM-DD HH:mm:ss') },
      { id: '3', name: localeMapping.cameras, amount: cameras?.length, lastmoddate: datetimeFormatter(rows?.latestCameraMod, 'YYYY-MM-DD HH:mm:ss') },
    ];
  }, [data, localeMapping]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={tableColumns}
        idField="id"
        onPressEdit={onPressEdit}
        selectable
        clickable
      />
    </MKBox>
  );
};

WardInfoListViewSection.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default WardInfoListViewSection;
