import React, { useState } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'contexts/auth';
import { AppProvider } from 'contexts/app';
import { GlobalDataProvider } from 'contexts/global-data';
import AppComponent from 'App';
import { WardsProvider } from 'contexts/wards';
import { SignalsProvider } from 'contexts/signals';
import { PythonProvider } from 'react-py';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

const packages = {
  micropip: ['numpy', 'opencv-python', 'matplotlib'],
};

const App = () => {
  const [app, setApp] = useState(null);
  return (
    <AppProvider value={{ app, setApp }}>
      <AuthProvider>
        <GlobalDataProvider>
          <WardsProvider>
            <SignalsProvider>
              <PythonProvider packages={packages}>
                <BrowserRouter basename={app?.base_url || '/'}>
                  <AppComponent />
                </BrowserRouter>
              </PythonProvider>
            </SignalsProvider>
          </WardsProvider>
        </GlobalDataProvider>
      </AuthProvider>
    </AppProvider>
  );
};

root.render(<App />);
