import MKBox from 'components/MaterialKit/MKBox';
import React, { useEffect, useState } from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import SoftwareCenterDistributionSection from './softwareCenterDistributionPage';

const SoftwareListViewPageSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'softwares'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.softwares]}
        showBackButton
        backButtonPath="/devices/"
      />
      <MKBox>
        <SoftwareCenterDistributionSection />
      </MKBox>
    </MKBox>
  );
};

export default SoftwareListViewPageSection;
