import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Switch } from '@mui/material';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';
import * as Yup from 'yup';
import InputField from 'components/InputField';
import { getLocaleMap } from 'utils/locales';

const validationSchema = Yup.object().shape({
  camera_identifier: Yup.string()
    .required('Camera Identifier is required'),
  camera_number: Yup.string()
    .required('Camera Number is required'),
});

const CameraForm = ({ camera, devices, patients, isEdit, onSave, ...props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['camera_id', 'camera_number', 'camera_ip', 'two_way_id', 'two_way_password', 'floor_polygon_file', 'add_to', 'assign_patient', 'active', 'save',
      'select_file', 'select_device', 'select_device', 'select_patient',
    ])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const initialValues = useMemo(() => {
    if (!camera) {
      return ({
        camera_identifier: null,
        camera_number: 0,
        device: '',
        patient: '',
        ip: '',
        floor_polygon: '',
        floor_polygon_file: '',
        id: '',
        password: '',
      });
    }
    return ({
      camera_identifier: camera?.camera_identifier,
      camera_number: camera?.camera_number,
      device: camera?.device?.av_device_id,
      patient: camera?.patient?.patient_id,
      ip: camera?.ip,
      floor_polygon: camera?.floor_polygon,
      floor_polygon_file: camera?.floor_polygon_file,
      id: camera?.id,
      password: camera?.password,
    });
  }, [camera]);

  const patientOptions = useMemo(() => {
    const opts = { value: null, label: 'Select Patient' };
    if (patients) {
      return [opts, ...patients];
    }
    return [opts];
  }, [patients]);

  const handleSave = (values, { setFieldError }) => {
    onSave(values, { setFieldError });
  };

  return (
    <Formik
      onSubmit={handleSave}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 4, ml: 1 }}>
            <Grid container spacing={1}>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.camera_id}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="camera_identifier"
                    label={localeMapping.camera_id}
                    value={values.camera_identifier || ''}
                    onChange={handleChange('camera_identifier')}
                    onBlur={handleBlur('camera_identifier')}
                    fullWidth
                  />
                  {errors.camera_identifier && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.camera_identifier}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.camera_number}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="camera_number"
                    label={localeMapping.camera_number}
                    value={values.camera_number || ''}
                    onChange={handleChange('camera_number')}
                    onBlur={handleBlur('camera_number')}
                    fullWidth
                  />
                  {errors.camera_number && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.camera_number}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.camera_ip}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="ip"
                    label={localeMapping.camera_ip}
                    value={values.ip || ''}
                    onChange={handleChange('ip')}
                    onBlur={handleBlur('ip')}
                    fullWidth
                  />
                  {errors.ip && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.ip}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.two_way_id}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="id"
                    label={localeMapping.two_way_id}
                    value={values.id || ''}
                    onChange={handleChange('id')}
                    onBlur={handleBlur('id')}
                    fullWidth
                  />
                  {errors.id && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.id}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.two_way_password}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="id"
                    label={localeMapping.two_way_password}
                    value={values.password || ''}
                    onChange={handleChange('password')}
                    onBlur={handleBlur('password')}
                    fullWidth
                  />
                  {errors.password && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.password}</MKTypography>}
                </Grid>
              </Grid>
              <Grid container alignItems="top" marginBottom={3}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.floor_polygon_file}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <InputField
                    type="file"
                    name="floor_polygon_file"
                    label={localeMapping.select_file}
                    value={values.floor_polygon_file}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    {...{
                      accept_file_types: ['.npy'],
                    }}
                  />
                  {errors.file && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.file}</MKTypography>}
                </Grid>
              </Grid>
              {/* <Grid container alignItems="top" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    Floor Polygon
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <MKInput
                    name="floor_polygon"
                    label="Floor Polygon"
                    value={values.floor_polygon || ''}
                    onChange={handleChange('floor_polygon')}
                    onBlur={handleBlur('floor_polygon')}
                    fullWidth
                  />
                  {errors.floor_polygon && <MKTypography variant="subtitle" color="red" fontSize="12px">{errors.floor_polygon}</MKTypography>}
                </Grid>
              </Grid> */}
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.add_to}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    label={localeMapping.select_device}
                    value={values.device}
                    onChange={(v) => {
                      setFieldValue('device', v);
                    }}
                    options={devices}
                    multiple={false}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.assign_patient}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <Select
                    label={localeMapping.select_patient}
                    value={values.patient}
                    onChange={(v) => {
                      setFieldValue('patient', v);
                    }}
                    options={patientOptions}
                    multiple={false}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" sx={{ mb: 1 }}>
                <Grid item xs={2}>
                  <MKTypography variant="body1" color="black" fontSize="16px">
                    {localeMapping.active}
                  </MKTypography>
                </Grid>
                <Grid item xs={10}>
                  <Switch checked={values.active} onChange={() => { setFieldValue('active', !(values.active)); }} />
                </Grid>
              </Grid>
              <Grid container item xs={2} justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  // disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : localeMapping.save}
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

CameraForm.propTypes = {
  camera: PropTypes.shape({
    camera_identifier: PropTypes.string,
    camera_number: PropTypes.number,
    device: PropTypes.string,
    patient: PropTypes.string,
    active: PropTypes.bool,
    ip: PropTypes.string,
    floor_polygon: PropTypes.string,
    floor_polygon_file: PropTypes.object,
    id: PropTypes.string,
    password: PropTypes.string,
  }),
  devices: PropTypes.array,
  patients: PropTypes.array,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

CameraForm.defaultProps = {
  camera: {},
  isEdit: false,
  onSave: () => {},
};

export default CameraForm;
