import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { updateDevice, getDevice } from 'api/alpha/devices';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getServiceProviders } from 'api/alpha/service_providers';
import { getCenters } from 'api/alpha/centers';
import { getWards } from 'api/alpha/wards';
import { getAIScripts, getWatchdogScripts } from 'api/alpha/scripts';
import { getLocaleMap } from 'utils/locales';
import DeviceForm from './deviceForm';

const DeviceEditSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'centers', 'edit'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('edit');
  const deviceId = searchParams.get('device_id');

  const [hierarchy, setHierarchy] = useState({});
  const [device, setDevice] = useState(null);
  const [aiScripts, setAIScripts] = useState([]);
  const [watchdogScripts, setWatchdogScripts] = useState([]);

  const prepareHierarchy = useCallback(() => {
    const promises = [getServiceProviders(), getCenters(), getWards(), getAIScripts(), getWatchdogScripts()];
    return Promise.all(promises);
  }, []);

  const fetchDeviceFromApi = useCallback(() => {
    if (!deviceId) return Promise.resolve();
    return getDevice(deviceId, { $expand: 'ward_id/center_id/service_provider_id,fall_detection_script,watchdog_script' })
      .then(({ data }) => {
        setDevice(data);
      });
  }, [deviceId]);

  useEffect(() => {
    if (!deviceId) return;
    fetchDeviceFromApi();
    prepareHierarchy()
      .then((data) => {
        const [serviceProviders, centers, wards, aiScriptsData, watchdogScriptsData] = data;
        setHierarchy({
          serviceProviders: serviceProviders.data,
          centers: centers.data,
          wards: wards.data,
        });
        setAIScripts(aiScriptsData.data);
        setWatchdogScripts(watchdogScriptsData.data);
      });
  }, [deviceId, fetchDeviceFromApi, prepareHierarchy]);

  const updateDeviceToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      ...values,
      fall_detection_script: values.fall_detection_script_id,
      watchdog_script: values.watchdog_script_id,
    };

    delete payload.service_provider;
    delete payload.center;
    delete payload.patient_id;

    return updateDevice(device?.av_device_id, payload)
      .then((resp) => {
        if (resp.status === 200) {
          return getDevice(device?.av_device_id, { $expand: 'ward_id/center_id/service_provider_id,fall_detection_script,watchdog_script' })
            .then(() => {
              if (isEdit) {
                navigate('/devices/list');
              } else {
                navigate(`/devices/list/view?device_id=${device?.av_device_id}`);
              }
            });
        }
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [device?.av_device_id, isEdit, navigate]);

  const aiScriptsOptions = useMemo(() => aiScripts.map((script) => ({
    value: script.fall_detection_script_id,
    label: script.name,
  })), [aiScripts]);

  const watchdogScriptsOptions = useMemo(() => watchdogScripts.map((script) => ({
    value: script.watchdog_script_id,
    label: script.name,
  })), [watchdogScripts]);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.centers, device?.device_number || 'Unknown Device', localeMapping.edit]}
        showBackButton
        backButtonPath={isEdit ? '/devices/list' : `/devices/list/view?device_id=${device?.av_device_id}`}
      />
      <DeviceForm device={device || null} onSave={updateDeviceToApi} hierarchy={hierarchy} aiScriptsOptions={aiScriptsOptions} watchdogScriptsOptions={watchdogScriptsOptions} />
    </MKBox>
  );
};

export default DeviceEditSection;
