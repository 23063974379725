import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import Image from 'components/Image';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from 'api/auth';
import { handleErrorResponse } from 'utils/general';
import { initialAuthState, useAuth } from 'contexts/auth';
import LanguageButton from 'components/LanguageButton';
import { getLocaleMap } from 'utils/locales';

const AlphaVisionHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [localeMapping, setLocaleMapping] = useState({});
  const { auth, setAuth } = useAuth();
  const userRoleScopes = useMemo(() => auth.user?.role?.scopes || [], [auth.user]);
  const isAdmin = !!userRoleScopes.find((roleScope) => roleScope?.scope === 'admin_only');

  const currentTab = `/${location.pathname.split('/')[1]}`;
  const usersLink = '/users';
  const providersLink = '/providers';
  const reportsLink = '/reports';
  const devicesLink = '/devices';
  const historyLink = '/history';
  const homeLink = '/';

  const handleLogout = useCallback(() => {
    return logout()
      .then(() => {
        setAuth(initialAuthState);
        navigate('/login');
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [navigate, setAuth]);

  useEffect(() => {
    getLocaleMap(['home', 'user_management', 'centers', 'history', 'reports', 'devices', 'logout'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  let languageText;
  const currentLanguage = localStorage.getItem('i18nextLng');
  switch (currentLanguage) {
    case 'en-US':
      languageText = 'English';
      break;
    case 'zh-HK':
      languageText = '中文';
      break;
    default:
      languageText = 'English';
      break;
  }

  return (
    <MKBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        py: '12px',
        px: '2rem',
        backgroundColor: '#EEFEFF',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
        mb: '1rem',
      }}
    >
      <MKBox
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          height={50}
          width={50}
          src="https://portal.alphavision.tech/alpha-vision-server/contents/files/1710490530547-logo.png"
        />
        <MKTypography variant="body1" marginLeft="12px" fontWeight="bold" color="#3583C5" fontSize="16px">
          Alpha Vision
        </MKTypography>
      </MKBox>

      <MKBox>
        <Button variant="text" size="small" to={homeLink}>
          <MKTypography variant="body1" color="#3583C5" fontWeight={currentTab === homeLink ? 'bold' : 'regular'} fontSize="16px">
            {localeMapping.home}
          </MKTypography>
        </Button>
        {isAdmin && (
          <Button variant="text" size="small" to={usersLink}>
            <MKTypography variant="body1" color="#3583C5" fontWeight={currentTab === usersLink ? 'bold' : 'regular'} fontSize="16px">
              {localeMapping.user_management}
            </MKTypography>
          </Button>
        )}
        {isAdmin && (
          <Button variant="text" size="small" to={providersLink}>
            <MKTypography variant="body1" color="#3583C5" fontWeight={currentTab === providersLink ? 'bold' : 'regular'} fontSize="16px">
              {localeMapping.centers}
            </MKTypography>
          </Button>
        )}
        {isAdmin && (
          <Button variant="text" size="small" to={historyLink}>
            <MKTypography variant="body1" color="#3583C5" fontWeight={currentTab === historyLink ? 'bold' : 'regular'} fontSize="16px">
              {localeMapping.history}
            </MKTypography>
          </Button>
        )}
        {isAdmin && (
          <Button variant="text" size="small" to={reportsLink}>
            <MKTypography variant="body1" color="#3583C5" fontWeight={currentTab === reportsLink ? 'bold' : 'regular'} fontSize="16px">
              {localeMapping.reports}
            </MKTypography>
          </Button>
        )}
        {isAdmin && (
          <Button variant="text" size="small" to={devicesLink}>
            <MKTypography variant="body1" color="#3583C5" fontWeight={currentTab === devicesLink ? 'bold' : 'regular'} fontSize="16px">
              {localeMapping.devices}
            </MKTypography>
          </Button>
        )}
      </MKBox>

      <MKBox>
        <Button variant="text" size="small">
          <Image
            height={20}
            width={20}
            src="https://portal.alphavision.tech/alpha-vision-server/contents/files/1710491643235-language_icon.png"
          />
          {/* <MKTypography variant="body1" color="#3583C5" marginLeft="8px" fontSize="16px">
            English
          </MKTypography> */}
          <LanguageButton
            options="en-US,zh-HK"
            text={languageText}
            variant="text"
            color="3583C5"
            // size={languageSize}
            fontSize={16}
            // fullWidth={languageFullWidth}
            // circular={languageCircular}
            // borderRadius={languageBorderRadius}
            // iconOnly={languageIconOnly}
            // containerProps={languageContainerProps}
            // {...languageProps}
          />

        </Button>

        <Button variant="contained" size="small" color="#3583C5" onClick={handleLogout}>
          <MKTypography variant="body1" color="white" fontSize="16px">
            {localeMapping.logout}
          </MKTypography>
        </Button>
      </MKBox>
    </MKBox>
  );
};

export default AlphaVisionHeader;
