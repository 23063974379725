import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';
import { getLocaleMap } from 'utils/locales';
import ConfirmDialog from './dialog';

const ReportForm = ({ initialValues, onSave, ...props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['tag', 'fall', 'others', 'report_details', 'save'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [open, setOpen] = useState(false);
  const [formikValues, setFormikValues] = useState({});
  const onDecline = () => {
    setFormikValues({});
    setOpen(false);
  };

  const handleOpenConfirmDialog = (values) => {
    setFormikValues(values);
    setOpen(true);
  };

  const onAccept = (value) => {
    setOpen(false);
    if (value) {
      onSave(formikValues);
    }
  };

  return (
    <Formik
      onSubmit={handleOpenConfirmDialog}
      initialValues={initialValues || {}}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={1} justifyContent="right">
              <Grid container item xs={12}>
                <MKTypography color="black" fontSize="inherit" fontWeight="bold" variant="body1">
                  {`${localeMapping.tag}:`}
                </MKTypography>
                <Select
                  name="tags"
                  value={values.tags}
                  onChange={(v) => { setFieldValue('tags', v); }}
                  options={[{ label: localeMapping.fall, value: 'Fall' }, { label: localeMapping.others, value: 'Others' }]}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKTypography color="black" fontSize="inherit" fontWeight="bold" variant="body1">
                  {`${localeMapping.report_details}:`}
                </MKTypography>
                <MKInput
                  name="details"
                  value={values.details || ''}
                  onChange={handleChange('details')}
                  onBlur={handleBlur('details')}
                  multiline
                  minRows={5}
                  maxRows={15}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={2} mt={1} justifyContent="flex-end">
                <Button
                  type="button"
                  variant="gradient"
                  color="info"
                  onClick={() => handleOpenConfirmDialog(values)}
                  fullWidth
                >
                  {localeMapping.save}
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
              </Grid>
            </Grid>
            <ConfirmDialog open={open} onAccept={onAccept} onDecline={onDecline} />
          </MKBox>
        );
      }}
    </Formik>
  );
};

ReportForm.propTypes = {
  info: PropTypes.shape({
    ward_id: PropTypes.string,
    center_id: PropTypes.string,
    name: PropTypes.string,
    false_alarm: PropTypes.bool,
    details: PropTypes.string,
    accident: PropTypes.shape({
      alert_id: PropTypes.string,
      tags: PropTypes.string,
      tag: PropTypes.string,
      accident_time: PropTypes.string,
      patient: PropTypes.shape({
        patient_identifier: PropTypes.string,
        patient_info: PropTypes.string,
      }),
      device: PropTypes.shape({
        device_physical_id: PropTypes.string,
      }),
    }),
  }),
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onSubmit: PropTypes.func,
};

ReportForm.defaultProps = {
  info: {},
  onSave: () => {},
  onSubmit: () => {},
};

export default ReportForm;
