import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MKButton from 'components/MaterialKit/MKButton';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import AssetsUploader from 'pages/Assets/assetsUploader';
import { isUuid } from 'utils/general';
import MKTypography from 'components/MaterialKit/MKTypography';

const EditAssetPage = () => {
  const { fileId } = useParams();
  const [typeLabel, setTypeLabel] = useState('Image');
  const [acceptFileTypes, setAcceptFileTypes] = useState(['image/*']);
  const [file, setFile] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setFile(fileId);
    if (location.state?.type === 'image') {
      setTypeLabel('Upload Image');
      setAcceptFileTypes(['image/*']);
      return;
    }
    if (location.state?.type === 'video') {
      setTypeLabel('Upload Video');
      setAcceptFileTypes(['video/*']);
      return;
    }
    if (isUuid(fileId)) {
      setTypeLabel('Update Asset');
      setAcceptFileTypes(['image/*', 'video/*']);
      return;
    }
    setTypeLabel('Upload Asset');
    setAcceptFileTypes(['image/*', 'video/*']);
  }, [fileId, location.state]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <MKButton
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => navigate(-1)}
          sx={{ mb: 4, fontSize: '1rem' }}
        >
          Go Back
        </MKButton>
        <AssetsUploader
          file_id={file === '-1' ? null : file}
          input_label={typeLabel}
          button_text={typeLabel}
          button_color="primary"
          button_variant="contained"
          button_size="medium"
          button_font_size="1rem"
          max_file_size={10}
          image_croppable
          accept_file_types={acceptFileTypes}
          onChange={(id) => { if (id === '') { navigate('/assets/'); return; } navigate(`/assets/edit/${id}`, { replace: true }); }}
        />
      </Container>
    </MKBox>
  );
};

export default EditAssetPage;
