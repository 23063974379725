import MKBox from 'components/MaterialKit/MKBox';
import React, { useEffect, useState } from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import DeviceListViewSection from './deviceList';

const AVDevicesListViewSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'centers'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.centers]}
        showBackButton
        backButtonPath="/devices/"
      />
      <MKBox>
        <DeviceListViewSection />
      </MKBox>
    </MKBox>
  );
};

export default AVDevicesListViewSection;
