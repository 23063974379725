import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { tryGetLocalizedText } from 'utils/locales';
import LanguageButton from 'components/LanguageButton';

const LanguageButtonSection = ({ options, text, color, size, font_size, full_width, circular, border_radius, icon_only, ...props }) => {
  const { i18n: { language } } = useTranslation();

  if (text) {
    const textContent = tryGetLocalizedText(text, language);
    const formattedBorderRadius = Number.isNaN(Number(border_radius)) ? border_radius : Number(border_radius);
    const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);
    return (
      <LanguageButton
        options={options}
        text={textContent}
        color={color}
        size={size}
        fontSize={formattedFontSize}
        fullWidth={full_width}
        circular={circular}
        borderRadius={formattedBorderRadius}
        iconOnly={icon_only}
        {...props}
      />
    );
  }
};

LanguageButtonSection.propTypes = {
  options: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'gradient']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  full_width: PropTypes.bool,
  circular: PropTypes.bool,
  border_radius: PropTypes.string,
  icon_only: PropTypes.bool,
};

export default LanguageButtonSection;
