import { useState } from 'react';
import { PropTypes } from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Image from 'components/Image';
import Button from 'components/Button';
import { useAuth } from 'contexts/auth';
import UserLogoutModal from './UserLogoutModal';

const HeaderUserInfo = ({ fontColor }) => {
  const { auth } = useAuth();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const openModal = () => {
    setIsLogoutModalOpen(true);
  };

  const closeModal = () => {
    setIsLogoutModalOpen(false);
  };

  const tryOpenModal = () => {
    if (isLogoutModalOpen) {
      closeModal();
    } else {
      openModal();
    }
  };

  const stubImageUrl = 'https://i.imgur.com/zaVqqz7.jpg';

  return (
    <MKBox
      display="flex"
    >
      <MKBox display="flex" flexDirection="column" mr="1rem">
        <MKTypography variant="h5" color={fontColor}>
          {auth.user?.display_name || ''}
        </MKTypography>
        <MKTypography variant="h6" color={fontColor}>
          {auth.user?.email || ''}
        </MKTypography>
      </MKBox>
      <MKBox position="relative">
        <Button
          variant="text"
          onClick={tryOpenModal}
          sx={{
            border: 'none',
            background: 'none',
            padding: 0,
            cursor: 'pointer',
          }}
        >
          <Image
            src={auth.user?.profile_image || stubImageUrl}
            alt="User profile avatar"
            width="3rem"
            height="3rem"
            borderRadius="50%"
            loading="lazy"
          />
        </Button>
        <UserLogoutModal isOpen={isLogoutModalOpen} onClose={closeModal} />
      </MKBox>
    </MKBox>
  );
};

HeaderUserInfo.propTypes = {
  fontColor: PropTypes.string,
};

export default HeaderUserInfo;
