import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { datetimeFormatter } from 'utils/datetime';
import DataTable from 'components/DataTable';
import CloseIcon from '@mui/icons-material/Close';
import { getAlerts } from 'api/alpha/alerts';
import { getLocaleMap } from 'utils/locales';

const LogDisplayerDialog = ({ ward, open, onClose }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['ward_logs'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [reports, setReports] = useState([]);

  const logTableColumns = useMemo(() => [
    { field: 'accident_tags', label: 'Tags' },
    { field: 'center_name', label: 'Center' },
    { field: 'ward_name', label: 'Ward' },
    { field: 'camera_id', label: 'Camera ID' },
    { field: 'guest_id', label: 'Guest ID' },
    { field: 'device_physical_id', label: 'Device ID' },
    { field: 'accident_time', label: 'Accident Time' },
  ], []);

  const fetchReportsFromApi = useCallback(() => {
    getAlerts({ $expand: 'ward/center_id/service_provider_id,patient,device,camera' })
      .then(({ data }) => {
        setReports(data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('err', err);
      });
  }, []);

  useEffect(() => {
    fetchReportsFromApi();
  }, [fetchReportsFromApi]);

  const rowData = useMemo(() => {
    // TODO - Implement the logic to map the data to the columns / Filter by WARD ID got from location.state.info.ward_id
    if (!reports) return [];
    const rowD = reports.map((row) => {
      if (row.ward.ward_id !== ward.ward_id) return;
      const bod = {};
      bod.accident_tags = row.tag;
      bod.center_name = row.ward.center_id.name;
      bod.ward_name = row.ward.name;
      bod.guest_id = row?.patient?.patient_identifier;
      bod.device_physical_id = row?.device?.device_physical_id;
      bod.camera_id = row?.camera?.camera_identifier;
      bod.createddate = datetimeFormatter(row.createddate, 'YYYY-MM-DD HH:mm:ss');
      bod.accident_time = datetimeFormatter(Number(row.accident_time), 'YYYY-MM-DD HH:mm:ss');
      return bod;
    }).filter(Boolean);
    return rowD;
  }, [reports, ward.ward_id]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <MKBox px={2} py={2} sx={{ backgroundColor: 'white' }}>
        <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <MKTypography variant="body1" fontSize="20px" fontWeight="bold" color="black">
            {localeMapping.ward_logs}
          </MKTypography>
          <IconButton onClick={handleClose} sx={{ color: '#C5010A' }}>
            <CloseIcon />
          </IconButton>
        </MKBox>
        <DataTable
          data={rowData}
          columns={logTableColumns}
          idField="ward_id"
        />
      </MKBox>
    </Dialog>
  );
};

LogDisplayerDialog.propTypes = {
  ward: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default LogDisplayerDialog;
