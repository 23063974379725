import React, { useEffect, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKButton from 'components/MaterialKit/MKButton';
import Image from 'components/Image';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { IconButton } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { getLocaleMap } from 'utils/locales';

const AVBreadcrumb = ({ onSortClick, showBackButton, backButtonPath, showSortButton, startingNode, items }) => {
  const navigate = useNavigate();
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['service_providers'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const renderItems = () => {
    if (!items) {
      return null;
    }
    return items.map((item, index) => {
      return (
        <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="#3583C5" key={index}>
          &gt;
          &nbsp;
          {item}
            &nbsp;
        </MKTypography>
      );
    });
  };

  return (
    <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ py: 2 }}>
      <MKBox position="relative" display="flex" flexDirection="row" alignItems="center">
        {showBackButton && (
          <MKBox position="absolute" left="-40px">
            <IconButton onClick={() => navigate(backButtonPath || -1)} sx={{ color: '#3583C5' }}>
              <ArrowBackIos fontSize="16px" color="inherit" />
            </IconButton>
          </MKBox>
        )}
        <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="#3583C5">
          {startingNode || localeMapping.service_providers}
          &nbsp;
        </MKTypography>
        {renderItems()}
      </MKBox>
      {showSortButton && (
        <MKBox>
          <MKButton
            color="#3583C5"
            variant="text"
            size="small"
            onClick={onSortClick}
            sx={{
              backgroundColor: '#EEFEFF',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
            }}
          >
            <Image
              src="https://portal.alphavision.tech/alpha-vision-server/contents/files/1710744962322-filter_icon.png"
              width="20px"
              height="20px"
              marginRight="8px"
            />
            <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="#3583C5">
              Sort By
            </MKTypography>
          </MKButton>
        </MKBox>
      )}
    </MKBox>
  );
};

AVBreadcrumb.propTypes = {
  items: PropTypes.array,
  startingNode: PropTypes.string,
  onSortClick: PropTypes.func,
  backButtonPath: PropTypes.string,
  showBackButton: PropTypes.bool,
  showSortButton: PropTypes.bool,
};

export default AVBreadcrumb;
