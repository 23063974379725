import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import getStompClient from 'socket/stompClient';
import { useState } from 'react';

const DebugSection = ({ ...props }) => {
  const [startDebug, setStartDebug] = useState(false);
  const [debugMessage, setDebugMessage] = useState(false);

  const addCameraMessageListener = (stompClient) => {
    if (stompClient && stompClient.connected) {
      stompClient.subscribe('/debug', (res) => {
        setDebugMessage(res.body);
      });
    }
  };

  const onStartDebug = () => {
    const stompClient = getStompClient('http://ec2-18-163-114-221.ap-east-1.compute.amazonaws.com/alpha-vision-in-websocket-server/ws')
    setStartDebug(true);
    stompClient.onConnect = (frame) => {
      if (stompClient.connected) {
        console.log('connected debug websocket')
        addCameraMessageListener(stompClient);
      }
    };
    stompClient.onStompError = (err) => {
      console.error('stompClient err', err);
    };
    stompClient.activate();
  };

  return (
    <MKBox>
      <Button onClick={onStartDebug}>
        Start Debug
      </Button>
      {startDebug && (
        <MKBox mt={5}>
          {debugMessage}
        </MKBox>
      )}
    </MKBox>
  );
};

DebugSection.propTypes = {
};

export default DebugSection;
