import api from 'api';

export const getCameras = (params) => {
  return api.get('/cameras', params, 'bearer');
};

export const getCamera = (id, params) => {
  return api.get(`/cameras/${id}`, params, 'bearer');
};

export const updateCamera = (id, body) => {
  return api.put(`/cameras/${id}`, body, 'bearer');
};

export const createCamera = (body) => {
  return api.post('/cameras', body, 'bearer');
};

export const deleteCamera = (id) => {
  return api.delete(`/cameras/${id}`, 'bearer');
};
