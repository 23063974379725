import React, { useEffect, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import AIScriptsListViewSection from './scriptsTable';

const AVScriptUploader = ({ ...props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['devices', 'softwares', 'ai_scripts'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  return (
    <MKBox
      {...props}
    >
      <AVBreadcrumb
        startingNode={localeMapping.devices}
        items={[localeMapping.softwares, localeMapping.ai_scripts]}
        showBackButton
        backButtonPath="/devices/softwares/list"
      />
      <AIScriptsListViewSection />
    </MKBox>
  );
};

export default AVScriptUploader;
