import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { datetimeFormatter } from 'utils/datetime';
import { createDynamicTableRow, deleteDynamicTableRow, getDynamicTableRows, updateDynamicTableRow } from 'api/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import { getPatient } from 'api/alpha/patients';
import { createAlert } from 'api/alpha/alerts';
import { useAuth } from 'contexts/auth';
import { useSignals } from 'contexts/signals';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import ReportForm from './reportForm';

const saveAttributesMap = {
  ward_name: '6f646373-807d-4d91-9b9d-9125630c1a4d',
  ward_id: '5671c71e-405a-4096-940a-cd2553356c25',
  service_provider_id: '64f3fd79-609c-44bf-9bf1-94abcd2ea127',
  service_provider_name: '06093070-ed5e-4455-b808-6c9fa989a9bb',
  center_id: '89f1741b-0d9c-4f89-a9ea-1cc587f341f1',
  center_name: '9f17c2ab-532d-425f-8e2c-d6afec8b9c94',
  reported_by: '1996a4b4-3c73-473d-a793-993b1024cb24',
  guest_info: '5e29d127-c319-4636-b176-7742da5d4e55',
  guest_id: '03eeea4a-3db8-474e-8550-1dd20d1d4991',
  patient_id: '019294bd-74cc-7be1-9796-466290a8c09f',
  guest_name: '782c209f-2da3-45bb-a64a-fe1128bd75ab',
  device_number: '0eb312fa-288f-46c5-86a5-6d08224c2e1a',
  details: '3e77037c-07ea-4d3a-bca9-a63952f3155f',
  accident_time: '1cd0fd3c-223c-45cf-a866-35224f49c260',
  tags: '21848470-f38e-47b0-8d58-66fe4d72ad6e',
  false_alarm: '72c772dc-0373-4f63-804f-4e746c18d814',
  alert_id: '22642fdf-28a9-449e-8e20-2a0360e463f6',
};

const NewReportSubmissionSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['guest_info', 'guest_name', 'problem_solve_report', 'issue_report', 'ward', 'accident_tag', 'camera_id', 'guest_id', 'happened_date', 'detail_report'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [patient, setPatient] = useState(null);
  const wardId = searchParams.get('ward_id');
  const patientId = searchParams.get('patient_id');
  const { setSignal } = useSignals();
  const { auth } = useAuth();
  const user = auth?.user;

  const handleSave = (values) => {
    const body = {};
    Object.keys(values).forEach((key) => {
      body[saveAttributesMap[key]] = values[key];
    });
    const alertBody = {
      ward: values.ward_id,
      patient: patientId,
      accident_time: values.accident_time,
      tag: values.tags,
    };
    createAlert(alertBody)
      .then((res) => {
        const createBody = {};
        if (res.status === 201 || res.status === 200) {
          body['22642fdf-28a9-449e-8e20-2a0360e463f6'] = res.data.alert_id;
          createBody.json_short_data = JSON.stringify(body);
          setSignal(true);
        }
        return createDynamicTableRow('bcb6d211-9308-45d8-a1da-0e4527d01481', createBody);
      })
      .then((res) => {
        if (res.status === 200) {
          navigate(-1);
        }
      });
  };

  const fetchCollectionDefinition = useCallback(() => {
    // fetch collection definition
    getCollectionDefinition('bcb6d211-9308-45d8-a1da-0e4527d01481', { $expand: 'attributes' })
      .then((res) => {
        setCollectionDefinition(res.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error:', err);
      });
  }, []);

  const fetchPatient = useCallback(() => {
    // fetch patient details
    return getPatient(patientId, { $expand: 'ward/center_id/service_provider_id' })
      .then((res) => {
        setPatient(res.data);
      });
  }, [patientId]);

  useEffect(() => {
    if (!collectionDefinition) {
      fetchCollectionDefinition();
    }
    if (patientId) {
      fetchPatient();
    }
  }, [collectionDefinition, fetchCollectionDefinition, fetchPatient, patientId]);

  const initialValues = useMemo(() => {
    if (patient) {
      return {
        ward_id: patient.ward.ward_id,
        center_id: patient.ward.center_id.center_id,
        ward_name: patient.ward.name,
        center_name: patient.ward.center_id.name,
        service_provider_id: patient.ward.center_id.service_provider_id.service_provider_id,
        service_provider_name: patient.ward.center_id.service_provider_id.name,
        accident_time: Math.floor(new Date().getTime()),
        guest_id: patient.patient_identifier,
        patient_id: patient.patient_id,
        guest_info: patient.patient_info,
        details: 'Report details',
        reported_by: user?.username,
        tags: 'Fall',
        false_alarm: true,
        alert_id: '',
      };
    }
    return {};
  }, [patient, user?.username]);

  if (!wardId && !patientId) {
    return null;
  }

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb startingNode={`${patient?.ward?.name}`} showBackButton />
      <MKBox display="flex" flexDirection="column" justifyContent="start" alignItems="left" fontSize="14px">
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {patient?.name}
        </MKTypography>

        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.ward}: ${patient?.ward?.name}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.guest_info}: ${patient?.patient_info}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.guest_id}: ${patient?.patient_identifier}`}
        </MKTypography>
        <MKTypography mt={1} color="black" fontSize="inherit" fontWeight="bold" variant="body1">
          {`${localeMapping.happened_date}: ${initialValues.accident_time ? datetimeFormatter(initialValues.accident_time, 'YYYY-MM-DD hh:mm:ss') : ''}`}
        </MKTypography>
        <ReportForm initialValues={initialValues} onSave={handleSave} />
      </MKBox>
    </MKBox>
  );
};

export default NewReportSubmissionSection;
