import { React } from 'react';
import { PropTypes } from 'prop-types';
import FloatingIcon from 'components/FloatingIcon';

const FloatingIconSection = ({ src, mobile_src, width, height, bottom_offset, right_offset, z_index, to, href }) => {
  return (
    <FloatingIcon
      src={src}
      mobileSrc={mobile_src}
      width={width}
      height={height}
      bottom_offset={bottom_offset}
      right_offset={right_offset}
      zIndex={z_index}
      to={to}
      href={href}
    />
  );
};

FloatingIconSection.propTypes = {
  src: PropTypes.string,
  mobile_src: PropTypes.string,
  bottom_offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right_offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  z_index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.string,
  href: PropTypes.string,
};

export default FloatingIconSection;
