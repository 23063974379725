import { React, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Image from 'components/Image';
import { useNavigate } from 'react-router-dom';

const FloatingIcon = ({ src, mobileSrc, width, height, zIndex, bottom_offset, right_offset, to, href }) => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };
    mobileMediaQuery.addEventListener('change', handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);
    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange);
    };
  }, []);

  const handleClick = () => {
    // Check if a navigation link is provided
    if (to) {
      navigate(to);
    } else if (href) {
      // If href is provided, open the link in a new tab
      window.open(href, '_blank');
    }
    // Add any additional logic for handling clicks on the image
  };
  return (
    <MKBox
      style={{
        position: 'fixed',
        bottom: bottom_offset || '20px',
        right: right_offset || '20px',
        cursor: 'pointer',
        zIndex: { zIndex }, // Adjust the z-index to ensure the icon is above other elements
      }}
      onClick={handleClick}
    >
      <Image src={isMobile ? (mobileSrc || src) : src} width={width} height={height} />
    </MKBox>
  );
};

FloatingIcon.propTypes = {
  src: PropTypes.string,
  mobileSrc: PropTypes.string,
  bottom_offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right_offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.string,
  href: PropTypes.string,
};

export default FloatingIcon;
