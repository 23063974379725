import qs from 'qs';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';

const MobileMenu = ({ menuItems, open, setOpen }) => {
  const location = useLocation();
  const queryParams = qs.parse((location.search || '').slice(1));

  const renderNavbarItems = useMemo(
    () => (menuItems || []).map((menuItem, idx) => {
      const { label: menuItemLabel, path, disabled } = menuItem;

      return !disabled ? (
        <MKBox
          key={`${idx}-${path}`}
          mx={1}
          display="flex"
          alignItems="center"
          onClick={() => setOpen(false)}
        >
          <Button
            to={`${path}?${qs.stringify(pick(queryParams, 'mode'))}`}
            variant="text"
            color={location.pathname === path ? 'dark' : 'secondary'}
            fontSize={16}
            size="small"
          >
            {menuItemLabel}
          </Button>
        </MKBox>
      ) : null;
    }),
    [location.pathname, menuItems, queryParams, setOpen],
  );

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MKBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
        {renderNavbarItems}
      </MKBox>
    </Collapse>
  );
};

MobileMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  setOpen: PropTypes.func,
};

export default MobileMenu;
