import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { createServiceProvider } from 'api/alpha/service_providers';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import ProviderForm from './providerForm';

const ProviderCreateSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['create_service_provider'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const navigate = useNavigate();
  const createProviderToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      name: values.name,
    };
    return createServiceProvider(payload)
      .then((res) => { if (res.status === 201) navigate(-1); })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <AVBreadcrumb startingNode={localeMapping.create_service_provider} showBackButton />
      <ProviderForm
        onSave={createProviderToApi}
      />
    </MKBox>
  );
};

export default ProviderCreateSection;
