import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import EditCollectionDefinitionForm from 'components/EditCollectionDefinitionForm';
import { getCollectionDefinition, updateCollectionDefinition } from 'api/collection_definitions';
import { handleErrorResponse, isUuid } from 'utils/general';

const EditCollectionFormSection = ({ collection_definition_id, update_success_message, ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const onClickCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {};
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  const onClickSave = useCallback((values) => {
    if (!isUuid(collection_definition_id)) {
      return Promise.resolve();
    }
    const updateBody = {
      name: values.name,
    };
    return updateCollectionDefinition(collection_definition_id, updateBody)
      .then(({ data }) => {
        setCollectionDefinition(data);
        alert(update_success_message);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collection_definition_id, update_success_message, setAuth]);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi, setAuth]);

  return (
    <EditCollectionDefinitionForm
      collectionDefinition={collectionDefinition}
      onSave={onClickSave}
      onCancel={onClickCancel}
      {...props}
    />
  );
};

EditCollectionFormSection.propTypes = {
  collection_definition_id: PropTypes.string,
  update_success_message: PropTypes.string,
};

export default EditCollectionFormSection;
