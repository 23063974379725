import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { updateSignal, getSignal } from 'api/signals';
import EditSignalForm from './EditSignalForm';

const SignalEditSection = () => {
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('edit') === 'true';
  const signalId = searchParams.get('signal_id');
  const isDecisionHandler = searchParams.get('is_decision_handler');
  const [signal, setSignal] = useState(null);
  const { setAuth } = useAuth();

  const fetchSignalFromApi = useCallback(() => {
    return signalId && getSignal(signalId)
      .then(({ data }) => {
        setSignal(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [signalId, setAuth]);

  const updateSignalToApi = (values, { setFieldError }) => {
    const updateBody = {
      signal_name: values.signal_name,
      sequence: values.sequence,
      decision_type: values.decision_type,
    };
    return updateSignal(signalId, updateBody)
      .then(({ data }) => {
        setSignal(data);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  };

  useEffect(() => {
    fetchSignalFromApi();
  }, [fetchSignalFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditSignalForm
          isEdit={isEdit}
          signal={signal}
          onSave={updateSignalToApi}
          isDecisionHandler={isDecisionHandler === 'true'}
        />
      </Container>
    </MKBox>
  );
};

export default SignalEditSection;
