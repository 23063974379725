import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MKBox from 'components/MaterialKit/MKBox';
import Breadcrumbs from 'components/Breadcrumbs';
import HeaderUserInfo from './HeaderUserInfo';

const BreadcrumbHeader = ({ bgColor, routes, separatorSize, color, variant, ...props }) => {
  const DEFAULT_MENU_HEADER_HEIGHT = 100;
  return (
    <MKBox bgColor={bgColor} p={{ xs: 0, md: 2 }} sx={{ height: DEFAULT_MENU_HEADER_HEIGHT }}>
      <MKBox py={1} borderRadius="xl" left={0}>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox ml={1}>
            <Breadcrumbs
              routes={routes}
              separator={(
                <ArrowForwardIosIcon
                  fontSize={separatorSize}
                  color={color}
                />
              )}
              labelProps={{
                variant,
                color,
              }}
              {...props}
            />
          </MKBox>
          <MKBox mr={2}>
            <HeaderUserInfo fontColor={color} />
          </MKBox>
        </MKBox>
      </MKBox>
    </MKBox>
  );
};

BreadcrumbHeader.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  separatorSize: PropTypes.string,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
      active: PropTypes.bool,
    }),
  ),
};

BreadcrumbHeader.defaultProps = {
  bgColor: null,
  color: null,
  separatorSize: null,
  variant: null,
  routes: [],
};

export default BreadcrumbHeader;
