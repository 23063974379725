import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKBadge from 'components/MaterialKit/MKBadge';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import { getMenuItemDependencies, deleteMenuItem } from 'api/menus';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getLocaleMap } from 'utils/locales';

const MenuItemList = ({ menuNodeId }) => {
  const [lm, setLm] = useState({});

  useEffect(() => {
    getLocaleMap(['labels_sequence', 'labels_menu_items', 'labels_label', 'labels_type', 'labels_node', 'labels_item', 'labels_path', 'labels_img_url', 'labels_disabled', 'labels_no', 'labels_yes'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const [menuItemDependencies, setMenuItemDependencies] = useState([]);
  const { appId, menuId, menuItemDependencyId } = useParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const fetchMenuItemDependenciesFromApi = useCallback(() => {
    if (!menuNodeId) {
      return Promise.resolve();
    }
    const menuItemDependencyParams = {
      menu_node: menuNodeId,
      $orderBy: 'sequence',
      $expand: 'menu_item',
    };
    return getMenuItemDependencies(menuItemDependencyParams)
      .then(({ data }) => {
        setMenuItemDependencies(data);
      });
  }, [menuNodeId]);

  const onClickAdd = useCallback(() => {
    navigate(`/app/${appId}/menu/${menuId}/menu-item-dependency${menuItemDependencyId ? `/${menuItemDependencyId}/add` : ''}`);
  }, [appId, menuId, menuItemDependencyId, navigate]);

  const onClickEdit = useCallback((selectedId) => {
    navigate(`/app/${appId}/menu/${menuId}/menu-item-dependency/${selectedId}`);
  }, [appId, menuId, navigate]);

  const onClickDelete = useCallback((selectedId) => {
    const selectedMenuItemDependency = menuItemDependencies.find(({ menu_item_dependency_id }) => menu_item_dependency_id === selectedId);
    if (!selectedMenuItemDependency?.menu_item?.menu_item_id) {
      return Promise.resolve();
    }
    return deleteMenuItem(selectedMenuItemDependency.menu_item.menu_item_id)
      .then(() => {
        return fetchMenuItemDependenciesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchMenuItemDependenciesFromApi, menuItemDependencies, setAuth]);

  useEffect(() => {
    fetchMenuItemDependenciesFromApi();
  }, [fetchMenuItemDependenciesFromApi]);

  const menuItemTableColumns = useMemo(() => [
    { field: 'sequence', label: lm.labels_sequence },
    {
      field: 'menu_item.label',
      label: lm.labels_label,
    },
    {
      field: 'menu_item.type',
      label: lm.labels_type,
      renderContent: ({ type }) => (
        <MKBadge
          color={type === 1 ? 'secondary' : 'info'}
          badgeContent={type === 1 ? lm.labels_node : lm.labels_item}
          container
        />
      ),
    },
    {
      field: 'menu_item.path',
      label: lm.labels_path,
    },
    {
      field: 'menu_item.img_url',
      label: lm.labels_img_url,
    },
    {
      field: 'menu_item.disabled',
      label: lm.labels_disabled,
      formatter: (v) => (v ? lm.labels_yes : lm.labels_no),
    },
  ], [lm.labels_disabled, lm.labels_img_url, lm.labels_item, lm.labels_label, lm.labels_no, lm.labels_node, lm.labels_path, lm.labels_sequence, lm.labels_type, lm.labels_yes]);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">{lm.labels_menu_items}</MKTypography>
      </MKBox>
      <DataTable
        data={menuItemDependencies}
        columns={menuItemTableColumns}
        idField="menu_item_dependency_id"
        onPressAdd={onClickAdd}
        onPressEdit={onClickEdit}
        onPressDelete={onClickDelete}
        paginationDisabled
        selectable
      />
    </MKBox>
  );
};

MenuItemList.propTypes = {
  menuNodeId: PropTypes.string,
};

MenuItemList.defaultProps = {
  menuNodeId: null,
};

export default MenuItemList;
