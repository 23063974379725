import React from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import FetchingTable from 'sections/FetchingTable';
import { solutionsTableColumns } from './tablecolumns';

const SolutionsTableSection = ({
  background_color,
  page_options,
  addable,
  editable,
  deletable,
  add_button_label,
  edit_button_label,
  delete_button_label,
  ...props
}) => {
  return (
    <MKBox
      px={{ xs: 0, md: 1 }}
      py={{ xs: 1, md: 2 }}
      bgColor={background_color}
      {...props}
    >
      <FetchingTable
        table_name="solutions"
        id_field="solution_id"
        page_options={page_options}
        columns={solutionsTableColumns}
        add_data_path="/solutions/add"
        edit_data_path="/solutions/edit"
        addable={addable}
        editable={editable}
        deletable={deletable}
        add_button_label={add_button_label}
        edit_button_label={edit_button_label}
        delete_button_label={delete_button_label}
      />

    </MKBox>
  );
};

SolutionsTableSection.propTypes = {
  background_color: PropTypes.string,
  page_options: PropTypes.arrayOf(
    PropTypes.object,
  ),
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  add_button_label: PropTypes.string,
  edit_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
};

export default SolutionsTableSection;
