import moment from 'moment';

export const DATE_FORMAT_DATE_ONLY_DEFAULT = 'YYYY-MM-DD';
export const DATE_FORMAT_DATE_TIME_DEFAULT = 'YYYY-MM-DD h:mm A';

export const isSameDay = (datetime1, datetime2) => {
  return moment(datetime1).isSame(moment(datetime2), 'day');
};

export const datetimeFormatter = (datetime, format) => {
  return datetime ? moment(datetime).format(format || DATE_FORMAT_DATE_ONLY_DEFAULT) : '--';
};

export const datetimeRangeFormatter = (startTime, endTime, dateFormat = DATE_FORMAT_DATE_ONLY_DEFAULT, timeFormat = 'HH:mm') => {
  const formattedStartTime = datetimeFormatter(startTime, `${dateFormat}, ${timeFormat}`);
  const formattedEndTime = datetimeFormatter(endTime, isSameDay(startTime, endTime) ? timeFormat : `${dateFormat}, ${timeFormat}`);
  return `${formattedStartTime} - ${formattedEndTime}`;
};
