/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useMemo } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// uuid is a library for generating unique id
import { v4 as uuidv4 } from 'uuid';

// lodash
import startCase from 'lodash/startCase';

// @mui material components
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

// Material Kit 2 PRO React components
import MKBox from 'components/MaterialKit/MKBox';
import MKAvatar from 'components/MaterialKit/MKAvatar';
import MKTypography from 'components/MaterialKit/MKTypography';

const MKTable = ({ columns, rows, getRowProps, onClickHeader }) => {
  const handleClickHeader = useCallback((headerName) => {
    if (typeof onClickHeader === 'function') {
      onClickHeader(headerName);
    }
  }, [onClickHeader]);

  const renderColumns = columns.map(({ label, name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <MKBox
        key={name}
        component="th"
        width={width || 'auto'}
        pt={1.5}
        pb={1.25}
        pl={align === 'left' ? pl : 3}
        pr={align === 'right' ? pr : 3}
        textAlign={align}
        color="secondary"
        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          fontSize: size.sm,
          fontWeight: fontWeightBold,
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
          cursor: (typeof onClickHeader === 'function') ? 'pointer' : 'auto',
        })}
        onClick={() => handleClickHeader(name)}
      >
        {label !== undefined ? label : startCase(name)}
      </MKBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <MKBox
            key={uuidv4()}
            component="td"
            p={1}
            sx={({ borders: { borderWidth, borderColor } }) => ({
              borderBottom: row.hasBorder ? `${borderWidth[1]} solid ${borderColor}` : 0,
            })}
          >
            <MKBox display="flex" alignItems="center" py={0.5} px={1}>
              <MKBox mr={2}>
                <MKAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </MKBox>
              <MKTypography variant="button" fontWeight="medium" sx={{ width: 'max-content' }}>
                {['number', 'boolean'].includes(typeof row[name][1]) ? `${row[name][1]}` : (row[name][1] || '-')}
              </MKTypography>
            </MKBox>
          </MKBox>
        );
      } else {
        template = (
          <MKBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            sx={({ borders: { borderWidth, borderColor } }) => ({
              borderBottom: row.hasBorder ? `${borderWidth[1]} solid ${borderColor}` : 0,
            })}
          >
            {row[name] && typeof row[name] === 'object' ? row[name] : (
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
                sx={{ display: 'inline-block', width: 'max-content' }}
              >
                {['number', 'boolean'].includes(typeof row[name]) ? `${row[name]}` : (row[name] || '-')}
              </MKTypography>
            )}
          </MKBox>
        );
      }

      return template;
    });

    return (
      <TableRow
        key={rowKey}
        {...(typeof getRowProps === 'function' ? getRowProps(row, key) : {})}
      >
        {tableRow}
      </TableRow>
    );
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <MKBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </MKBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [renderColumns, renderRows],
  );
};

// Setting default values for the props of MKTable
MKTable.defaultProps = {
  columns: [],
  rows: [],
  getRowProps: () => {},
  onClickHeader: () => {},
};

// Typechecking props for the MKTable
MKTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  rows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  getRowProps: PropTypes.func,
  onClickHeader: PropTypes.func,
};

export default MKTable;
