import MKBox from 'components/MaterialKit/MKBox';
import React, { useEffect, useCallback, useState } from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { useSearchParams } from 'react-router-dom';
import { getWard } from 'api/alpha/wards';
import { useAuth } from 'contexts/auth';
import { handleErrorResponse } from 'utils/general';
import { getLocaleMap } from 'utils/locales';
import CameraListViewSection from './cameraList';

const AVWardCamerasList = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['service_providers', 'cameras'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');
  const [ward, setWard] = useState(null);

  const fetchWardFromApi = useCallback(() => {
    const requestParams = {
      $expand: 'center_id/service_provider_id',
    };
    return wardId && getWard(wardId, requestParams)
      .then(({ data }) => {
        setWard(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [wardId, setAuth]);

  useEffect(() => {
    fetchWardFromApi();
  }, [fetchWardFromApi]);

  const centerName = ward?.center_id?.name || 'Unknown Center';
  const providerName = ward?.center_id?.service_provider_id?.name || 'Unknown Provider';
  const wardName = ward?.name || 'Unknown Ward';

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <AVBreadcrumb
        startingNode={localeMapping.service_providers}
        items={[providerName, centerName, wardName, localeMapping.cameras]}
        showBackButton
        backButtonPath={`/providers/wards?ward_id=${wardId}&centerName=${centerName}&providerName=${providerName}`}
      />
      <MKBox>
        <CameraListViewSection ward={ward} />
      </MKBox>
    </MKBox>
  );
};

export default AVWardCamerasList;
