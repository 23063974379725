import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import WardsListViewSection from 'sections/AVWardsManagement/WardsList';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getCenter, updateCenter } from 'api/alpha/centers';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import CenterForm from './centerForm';

const CenterEditSection = () => {
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('edit') === 'true';
  const centerId = searchParams.get('center_id');
  const providerName = searchParams.get('providerName') || 'Unknown Provider';

  const [center, setCenter] = useState(null);
  const { setAuth } = useAuth();

  const fetchCenterFromApi = useCallback(() => {
    const params = {};
    return centerId && getCenter(centerId, params)
      .then(({ data }) => {
        setCenter(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [centerId, setAuth]);

  const updateCenterToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      name: values.name,
    };
    return updateCenter(centerId, updateBody)
      .then(({ data }) => {
        setCenter(data);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [centerId]);

  useEffect(() => {
    fetchCenterFromApi();
  }, [fetchCenterFromApi]);

  return (
    <MKBox>
      <AVBreadcrumb items={[providerName, center?.name]} showBackButton />
      <CenterForm
        isEdit={isEdit}
        center={center}
        onSave={updateCenterToApi}
      />
      <WardsListViewSection center={center} />
    </MKBox>
  );
};

export default CenterEditSection;
