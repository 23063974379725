import React, { useCallback, useEffect, useState } from 'react';
import { getCameraHistoryData } from 'api/alpha/reports';
import MKBox from 'components/MaterialKit/MKBox';
import { Grid } from '@mui/material';
import Image from 'components/Image';
import { useSearchParams } from 'react-router-dom';
import { usePython } from 'react-py';
import Button from 'components/Button';

const SkeletonReplay = (...props) => {
  const [imgOutput, setImgOutput] = useState();
  const { runPython, stdout, stderr, isLoading } = usePython();
  const [searchParams] = useSearchParams();
  const alertId = searchParams.get('alert_id');
  const [currentPath, setCurrentPath] = useState(null);

  const drawSkeleton = useCallback((skeletons) => {
    const plot = skeletons.length > 0 ? 'True' : 'False';
    const plotTwo = skeletons.length > 1 ? 'True' : 'False';
    const skeleton_to_plot = [];
    skeletons.forEach((skeleton) => {
      if (skeleton.length > 0 && skeleton[0].length > 0) {
        skeleton_to_plot.push(JSON.stringify(skeleton[0][0]));
      }
    });
    const pointOne = plot && skeletons.length > 0 && skeletons[0].length > 0 ? JSON.stringify(skeletons[0][0]) : null;
    console.log(runPython(`
import numpy as np
import os
import base64
from io import BytesIO
import cv2
os.environ['MPLBACKEND'] = 'AGG'
import matplotlib.pyplot as plt
import matplotlib.image as mpimg

COCO_PAIR = [(0, 13), (1, 2), (1, 3), (3, 5), (2, 4), (4, 6), (13, 7), (13, 8),  # Body
              (7, 9), (8, 10), (9, 11), (10, 12)]
POINT_COLORS = [(0, 255, 255), (0, 191, 255), (0, 255, 102), (0, 77, 255), (0, 255, 0),  # Nose, LEye, REye, LEar, REar
                (77, 255, 255), (77, 255, 204), (77, 204, 255), (191, 255, 77), (77, 191, 255), (191, 255, 77),  # LShoulder, RShoulder, LElbow, RElbow, LWrist, RWrist
                (204, 77, 255), (77, 255, 204), (191, 77, 255), (77, 255, 191), (127, 77, 255), (77, 255, 127), (0, 255, 255)]  # LHip, RHip, LKnee, Rknee, LAnkle, RAnkle, Neck
LINE_COLORS = [(0, 215, 255), (0, 255, 204), (0, 134, 255), (0, 255, 50), (77, 255, 222),
                (77, 196, 255), (77, 135, 255), (191, 255, 77), (77, 255, 77), (77, 222, 255),
                (255, 156, 127), (0, 127, 255), (255, 127, 77), (0, 77, 255), (255, 77, 36)]

pts = None
frame = None

points_array = ${skeleton_to_plot}
    
# Window name in which image is displayed 
window_name = 'Image'
  
# Start coordinate, here (5, 5) 
# represents the top left corner of rectangle 
start_point = (5, 5) 
  
# Ending coordinate, here (220, 220) 
# represents the bottom right corner of rectangle 
end_point = (220, 220) 
  
# Blue color in BGR 
color = (255, 0, 0) 
  
# Line thickness of 2 px 
thickness = 2
  
# Using cv2.rectangle() method 
# Draw a rectangle with blue line borders of thickness of 2 px 
frame = np.zeros(shape=(384,384, 3), dtype=int)
#frame = cv2.rectangle(frame, start_point, end_point, color, thickness) 

l_pair = COCO_PAIR
p_color = POINT_COLORS
line_color = LINE_COLORS

part_line = {}

if(${plotTwo}):
  for point in points_array:
    if(${plot}):
      pts = np.array(point, dtype=np.float32)
      pts = np.concatenate((pts, np.expand_dims((pts[1, :] + pts[2, :]) / 2, 0)), axis=0)

    if(${plot}):
      for n in range(pts.shape[0]):
          if pts[n, 2] <= 0.05:
              continue
          cor_x, cor_y = int(pts[n, 0]), int(pts[n, 1])
          part_line[n] = (cor_x, cor_y)
          cv2.circle(frame, (cor_x, cor_y), 3, p_color[n], -1)

      for i, (start_p, end_p) in enumerate(l_pair):
          if start_p in part_line and end_p in part_line:
              start_xy = part_line[start_p]
              end_xy = part_line[end_p]
              cv2.line(frame, start_xy, end_xy, line_color[i], int(1*(pts[start_p, 2] + pts[end_p, 2]) + 1))
else:
  if(${plot}):
    pts = np.array(${pointOne}, dtype=np.float32)
    pts = np.concatenate((pts, np.expand_dims((pts[1, :] + pts[2, :]) / 2, 0)), axis=0)

  if(${plot}):
    for n in range(pts.shape[0]):
        if pts[n, 2] <= 0.05:
            continue
        cor_x, cor_y = int(pts[n, 0]), int(pts[n, 1])
        part_line[n] = (cor_x, cor_y)
        cv2.circle(frame, (cor_x, cor_y), 3, p_color[n], -1)

    for i, (start_p, end_p) in enumerate(l_pair):
        if start_p in part_line and end_p in part_line:
            start_xy = part_line[start_p]
            end_xy = part_line[end_p]
            cv2.line(frame, start_xy, end_xy, line_color[i], int(1*(pts[start_p, 2] + pts[end_p, 2]) + 1))

plt.axis('off')
plt.imshow(frame, interpolation='nearest')
buf = BytesIO()
plt.margins(0,0)
plt.savefig(buf, format='png', bbox_inches='tight', pad_inches = 0)
buf.seek(0)
# Encode to a base64 str
img = 'data:image/png;base64,' + \
base64.b64encode(buf.read()).decode('utf-8')
# Write to stdout
print(img)
plt.clf()
            `));
  }, [runPython]);

  const fetchCameraHistoryData = useCallback(() => {
    return getCameraHistoryData({ alert: alertId })
      .then(({ data }) => {
        const interval = 2000;
        data.forEach((datum, index) => {
          setTimeout(() => {
            const skeletons = JSON.parse(datum.skeleton);
            return drawSkeleton(skeletons);
          }, index * interval);
        });
      });
  }, [drawSkeleton]);

  useEffect(() => {
    setImgOutput(stdout);
  }, [stdout]);

  useEffect(() => {
    console.log(stderr);
  }, [stderr]);

  useEffect(() => {
    const newPath = window.location.pathname;
    if (currentPath && newPath !== currentPath) {
      window.location.reload();
    } else {
      setCurrentPath(window.location.pathname);
    }
  }, [window.location.pathname, currentPath]);

  return (
    <MKBox display="flex" flexDirection="column" alignItems="center">
      <MKBox display="flex" justifyContent="flex-start" width="100%" padding="10px">
        <Button disabled={isLoading && alertId} onClick={fetchCameraHistoryData}>Start Replay</Button>
      </MKBox>
      <MKBox display="flex" maxWidth={400} bgColor="black" justifyContent="center" alignItems="center" marginTop="10px" sx={{ bgColor: 'black' }}>
        <Grid item padding={0} maxWidth={400} width={400} height={400} style={{ backgroundColor: 'black' }}>
      {/* <MKBox display="flex" bgColor="black" minWidth="75vh" minHeight="50vh" justifyContent="center" alignItems="center" marginTop={10} sx={{ bgColor: 'black' }}>
        <Grid item padding={0} maxWidth={imgOutput && 300} width={imgOutput ? 300 : '100%'} height={imgOutput ? 300 : '100%'} style={{ backgroundColor: 'black' }}> */}
          <Image name={!imgOutput && 'liveStream'} src={imgOutput} width="100%" height="100%" />
        </Grid>
      </MKBox>
    </MKBox>
  );
};

SkeletonReplay.propTypes = {
};

export default SkeletonReplay;
