// eslint-disable-next-line import/no-unresolved
import SockJS from 'sockjs-client';
// eslint-disable-next-line import/no-unresolved
import * as Stomp from '@stomp/stompjs';
import config from 'config';

const getStompClient = (websocketUrl) => {
  const stompClient = new Stomp.Client({
    webSocketFactory: () => {
      return new SockJS(websocketUrl || config.webSocketUrl);
    },
    debug: () => null,
  });
  return stompClient;
};

export default getStompClient;
