export function generateContrastingColor(uuid) {
  const numeric = parseInt(uuid.replace(/-/g, ''), 16);
  const seed = (numeric % 255) + 1;
  let mseed = seed;
  // Initialize the random number generator with the seed
  const seededRandom = (s) => {
    const x = Math.sin(s + 1) * 10000;
    return x - Math.floor(x);
  };

  // Function to calculate relative luminance of a color
  const calculateRelativeLuminance = (r, g, b) => {
    const sRGB = (c) => {
      let mc = c;
      mc /= 255;
      return mc <= 0.03928 ? mc / 12.92 : ((mc + 0.055) / 1.055) ** 2.4;
    };
    const R = sRGB(r);
    const G = sRGB(g);
    const B = sRGB(b);
    return 0.2126 * R + 0.7152 * G + 0.0722 * B;
  };

  let red; let green; let blue;
  let luminance = 0;

  // Loop until the generated color has sufficient contrast with white
  while (luminance < 0.0001) {
    red = Math.floor(seededRandom(mseed) * 256);
    green = Math.floor(seededRandom(mseed + 1) * 256);
    blue = Math.floor(seededRandom(mseed + 2) * 256);

    // Calculate relative luminance of the generated color
    luminance = calculateRelativeLuminance(red, green, blue);

    mseed += 1; // Increment seed for next iteration
  }

  // Convert RGB to hexadecimal format
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  const hexColor = `#${redHex}${greenHex}${blueHex}`;

  return hexColor;
}
// Path: src/sections/AVHome/helperFunctions.js
