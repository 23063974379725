import api from 'api';

export const getBusinessLogics = (params) => {
  return api.get('/business_logics', params, 'bearer');
};

export const getBusinessLogic = (id, params) => {
  return api.get(`/business_logics/${id}`, params, 'bearer');
};

export const updateBusinessLogic = (id, body) => {
  return api.put(`/business_logics/${id}`, body, 'bearer');
};

export const createBusinessLogic = (body) => {
  return api.post('/business_logics', body, 'bearer');
};

export const deleteBusinessLogic = (id) => {
  return api.delete(`/business_logics/${id}`, 'bearer');
};
