import MKBox from 'components/MaterialKit/MKBox';
import React, { useEffect, useState } from 'react';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import ReportsListViewSection from './reportsList';

const AVReportsHomeSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['reports'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);
  return (
    <MKBox display="flex" flexDirection="column" justifyContent="flex-start">
      {/* <AVBreadcrumb startingNode={localeMapping.reports} showSortButton /> */}
      <AVBreadcrumb startingNode={localeMapping.reports} />
      <ReportsListViewSection />
    </MKBox>
  );
};

export default AVReportsHomeSection;
