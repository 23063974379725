import PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';

const EditableWrapper = ({ editable, onPressEdit, children, sx, ...props }) => {
  return editable ? (
    <MKBox position="relative" sx={{ border: '2px dotted', m: 0.2, ...sx }} {...props}>
      {children}
      <MKBox
        position="absolute"
        right={4}
        top={4}
        sx={{ 'zIndex': 1500, 'opacity': 0.7, '&:hover': { zIndex: 1600, opacity: 1 } }}
      >
        <Button
          circular
          iconOnly
          color="dark"
          variant="gradient"
          size="medium"
          onClick={onPressEdit}
        >
          <EditOutlinedIcon />
        </Button>
      </MKBox>
    </MKBox>
  ) : children;
};

EditableWrapper.propTypes = {
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
  children: PropTypes.node,
  sx: PropTypes.object,
};

EditableWrapper.defaultProps = {
  editable: false,
  onPressEdit: () => {},
  children: null,
  sx: {},
};

export default EditableWrapper;
