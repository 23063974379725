import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getWard } from 'api/alpha/wards';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getLocaleMap } from 'utils/locales';
import PatientsListViewSection from './patientList';

const WardPatientsOverview = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['patients_count'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [searchParams] = useSearchParams();
  const wardId = searchParams.get('ward_id');
  const [ward, setWard] = useState(null);
  const { setAuth } = useAuth();
  const providerName = searchParams.get('providerName') || 'Unknown Provider';
  const centerName = searchParams.get('centerName') || 'Unknown Center';

  const fetchWardFromApi = useCallback(() => {
    const requestParams = {};
    return wardId && getWard(wardId, requestParams)
      .then(({ data }) => {
        setWard(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [wardId, setAuth]);

  useEffect(() => {
    fetchWardFromApi();
  }, [fetchWardFromApi]);

  return (
    <MKBox>
      <AVBreadcrumb items={[providerName, centerName, ward?.name, 'Patients']} showBackButton />
      <MKTypography variant="body1" fontSize="16px" color="black">
        {`${localeMapping.patients_count}: ${ward?.patients?.length || 0}`}
      </MKTypography>
      <PatientsListViewSection
        ward={ward}
      />
    </MKBox>
  );
};

export default WardPatientsOverview;
