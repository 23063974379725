import PropTypes from 'prop-types';
import TextSection from 'sections/Text';
import { datetimeFormatter } from 'utils/datetime';

const DateSection = ({ value, date_format, editable, onPressEdit, ...props }) => {
  const parsedValue = !Number.isNaN(Number(value)) ? Number(value) : value;
  const formattedDate = datetimeFormatter(parsedValue, date_format);
  return (
    <TextSection
      text={formattedDate}
      {...props}
    />
  );
};

DateSection.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date_format: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default DateSection;
