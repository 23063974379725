import api from 'api';

export const getParameters = (params) => {
  return api.get('/parameters', params, 'bearer');
};

export const getParameter = (id, params) => {
  return api.get(`/parameters/${id}`, params, 'bearer');
};

export const updateParameter = (id, body) => {
  return api.put(`/parameters/${id}`, body, 'bearer');
};

export const createParameter = (body) => {
  return api.post('/parameters', body, 'bearer');
};
