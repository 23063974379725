import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Breadcrumbs from 'components/Breadcrumbs';
import { getCollectionDefinition } from 'api/collection_definitions';
import { handleErrorResponse, isUuid } from 'utils/general';
import { useAuth } from 'contexts/auth';

const CollectionBreadcrumbsSection = ({ collection_definition_id, max_display_items, ...props }) => {
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const [parentCollectionDefinitions, setParentCollectionDefinitions] = useState([]);
  const { setAuth } = useAuth();

  const fetchCollectionDefinitionFromApi = useCallback(() => {
    if (isUuid(collection_definition_id)) {
      const collectionDefinitionParams = {
        $expand: 'attributes,parent_collection_definition/parent_collection_definition',
      };
      return getCollectionDefinition(collection_definition_id, collectionDefinitionParams)
        .then(({ data }) => {
          setCollectionDefinition(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collection_definition_id, setAuth]);

  const getRecursiveParentCollectionDefinitions = useCallback((pCollectionDefinitions) => {
    let collectionList = pCollectionDefinitions;
    let [pCollectionDefinition] = pCollectionDefinitions;
    if (!pCollectionDefinition || !pCollectionDefinition.parent_collection_definition) {
      return Promise.resolve(collectionList);
    }
    while (typeof pCollectionDefinition.parent_collection_definition === 'object') {
      pCollectionDefinition = pCollectionDefinition.parent_collection_definition;
      collectionList = [pCollectionDefinition, ...collectionList];
      if (!pCollectionDefinition.parent_collection_definition) {
        return Promise.resolve(collectionList);
      }
    }
    const collectionDefinitionParams = {
      $expand: 'parent_collection_definition/parent_collection_definition',
    };
    return getCollectionDefinition(pCollectionDefinition.parent_collection_definition, collectionDefinitionParams)
      .then(({ data }) => {
        return getRecursiveParentCollectionDefinitions([data, ...collectionList]);
      });
  }, []);

  useEffect(() => {
    fetchCollectionDefinitionFromApi();
  }, [fetchCollectionDefinitionFromApi]);

  useEffect(() => {
    if (collectionDefinition) {
      getRecursiveParentCollectionDefinitions([collectionDefinition])
        .then((collections) => {
          setParentCollectionDefinitions(collections.slice(0, -1));
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [collectionDefinition, getRecursiveParentCollectionDefinitions, setAuth]);

  return (
    <MKBox>
      {!isUuid(collection_definition_id) && (
        <MKTypography color="error">Invalid Collection Definition</MKTypography>
      )}
      <Breadcrumbs
        maxItems={max_display_items}
        routes={[
          ...parentCollectionDefinitions.map((parentCollectionDefinition) => ({
            label: parentCollectionDefinition.name,
          })),
          {
            label: collectionDefinition?.name,
            active: true,
          },
        ]}
        {...props}
      />
    </MKBox>
  );
};

CollectionBreadcrumbsSection.propTypes = {
  collection_definition_id: PropTypes.string,
  max_display_items: PropTypes.number,
};

export default CollectionBreadcrumbsSection;
