import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useSearchParams } from 'react-router-dom';
import { getParameters } from 'api/parameters';
import { getLocaleMap } from 'utils/locales';
import DataTable from 'components/DataTable';

const ParameterListViewSection = () => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap(['labels_parameters', 'labels_parameter_id', 'labels_parameter_name'])
      .then((response) => {
        setLm(response);
      });
  }, []);
  const parameterTableColumns = useMemo(() => [
    {
      field: 'parameter_id',
      label: lm.labels_parameter_id,
    },
    { field: 'parameter_name', label: lm.labels_parameter_name },
  ], [lm.labels_parameter_id, lm.labels_parameter_name]);

  const [parameters, setParameters] = useState([]);
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const businessLogicId = searchParams.get('business_logic_id');

  const fetchParametersFromApi = useCallback(() => {
    const params = {
      business_logic: businessLogicId,
    };
    return businessLogicId && getParameters(params)
      .then(({ data }) => {
        setParameters(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, setAuth]);

  useEffect(() => {
    fetchParametersFromApi();
  }, [fetchParametersFromApi]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">{lm.labels_parameters}</MKTypography>
      </MKBox>
      <DataTable
        data={parameters}
        columns={parameterTableColumns}
        idField="parameter_id"
      />
    </MKBox>
  );
};

export default ParameterListViewSection;
