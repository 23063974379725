/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';
import { getLocaleMap } from 'utils/locales';
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ReportIssuingDialog from './reportIssuingDialog';
import LogDisplayerDialog from './logDisplayerDialog';

export const onlineIcon = () => {
  return (
    <MKBox display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ borderRadius: '50%', backgroundColor: '#00FF00', width: '10px', height: '10px' }} />
  );
};

export const offlineIcon = () => {
  return (
    <MKBox display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ borderRadius: '50%', backgroundColor: '#FF0000', width: '10px', height: '10px' }} />
  );
};

export const presenceIcon = (presence) => {
  return (
    <MKBox display="flex" flexDirection="row" alignItems="center" justifyContent="center" sx={{ borderRadius: '50%', backgroundColor: presence ? '#FFFFFF' : '#000000', width: '10px', height: '10px' }} />
  );
};

const ProblemWardCard = ({ ward, radarData }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [info, setInfo] = useState({});
  const [timeSinceLastAccident, setTimeSinceLastAccident] = useState(0);
  const [localeMapping, setLocaleMapping] = useState({});
  const thirtyWardCenterId = '4d3d02d2-c80f-475c-aa6b-a652b66420db';

  useEffect(() => {
    getLocaleMap(['details', 'show_logs', 'device_status', 'camera_status', 'guests', 'alerts', 'emergency_alarm', 'alert', 'patient', 'fall', 'others', 'falls', 'sos', 'heart_rate', 'presence', 'breath_rate', 'radar_status', 'not_registered'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const handleCloseIssuingDialog = () => {
    setOpen(false);
  };

  const handleOpenLogModal = () => {
    setLogsOpen(true);
  };

  const handleCloseLogModal = () => {
    setLogsOpen(false);
  };

  const handleOpenDialog = (accident) => {
    const mutableWard = { ...ward };
    mutableWard.accident = ward.accidents.find((acc) => {
      return acc.alert_id === accident.alert_id;
    });
    setInfo(mutableWard);
    setOpen(true);
  };

  const handlePoliceReport = (ward_name, center_name) => {
    // eslint-disable-next-line no-console
    console.log(`*Not implemented*\tReporting ${ward_name} from ${center_name}  to police`);
  };

  const getTimeSinceLastAlert = useCallback(() => {
    const sortedWards = ward?.accidents.sort((a, b) => {
      return b.accident_time - a.accident_time;
    });
    if (sortedWards.length === 0) return null;
    const lastAccident = sortedWards[0];
    const now = new Date();
    const accidentTime = new Date(Number(lastAccident.accident_time));
    let diff = Math.floor((now - accidentTime) / 1000);
    const days = Math.floor(diff / (60 * 60 * 24));
    diff -= days * (60 * 60 * 24);
    const hours = Math.floor(diff / (60 * 60));
    diff -= hours * (60 * 60);

    const minutes = Math.floor(diff / 60);
    diff -= minutes * 60;

    const seconds = diff;
    let formattedTime = '';
    if (days > 0) formattedTime += `${days} Day(s)`;
    if (hours > 0 && hours < 10) formattedTime += `0${hours}:`;
    else if (hours > 0) formattedTime += `${hours}:`;
    else formattedTime += '00:';
    if (minutes > 0 && minutes < 10) formattedTime += `0${minutes}:`;
    else if (minutes > 0) formattedTime += `${minutes}:`;
    else formattedTime += '00:';
    if (seconds > 0 && seconds < 10) formattedTime += `0${seconds}s`;
    else if (seconds > 0) formattedTime += `${seconds}s`;
    else formattedTime += '00s';

    setTimeSinceLastAccident(formattedTime.trim());
  }, [ward]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getTimeSinceLastAlert();
    }, 1000); // Check every second

    // Clear interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [getTimeSinceLastAlert]);

  return (
    <MKBox sx={{ border: '1.5px solid', borderColor: '#C5010A', backgroundColor: 'gray', borderRadius: '8px' }}>
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" px={2} py={1} sx={{ borderRadius: '8px 8px 0px 0px', backgroundColor: '#C5010A' }}>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <MKBox display="flex" flexDirection="column" alignItems="left" color="#fff">
              <MKTypography color="inherit" fontWeight="bold" fontSize="28px">{ward.ward_name}</MKTypography>
              <MKTypography color="inherit" fontWeight="bold" fontSize="28px">{ward.center_name}</MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              size="medium"
              color="#3583C5"
              fullWidth
              fontSize="18px"
              onClick={() => { navigate(`/users/ward-details?ward_id=${ward.ward_id}`); }}
              endIcon={(
                <ChevronRightIcon fontSize="inherit" />
            )}
            >
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">{localeMapping.details}</MKTypography>
            </Button>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox px={2} py={1} display="flex" flexDirection="column" alignItems="left" sx={{ borderRadius: '0px 0px 8px 8px', backgroundColor: '#FFEEF4' }}>
        <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="start">
          <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
              <MKBox fontSize="20px">
                <MKTypography variant="body1" fontSize="inherit">{`${localeMapping.guests}: ${ward?.guests || 0}`}</MKTypography>
                <MKTypography variant="body1" fontSize="inherit">{`${thirtyWardCenterId === ward.center_id ? localeMapping.falls : localeMapping.alerts}: ${ward?.alerts || 0}`}</MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                size="medium"
                color="#3583C5"
                fontSize="14px"
                fullWidth
                onClick={() => handleOpenLogModal()}
              >
                {localeMapping.show_logs}
              </Button>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={1}>
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={12} md={8} sx={{ mb: 1 }}>
              {ward?.accidents?.map((accident, index) => {
                const currentTimestamp = moment().valueOf();
                return (
                  <Button
                    key={index}
                    onClick={() => { handleOpenDialog(accident); }}
                    sx={{ backgroundColor: '#C5010A', borderRadius: '8px', mr: 0.3, mt: 0.3 }}
                  >
                    <MKBox display="flex" flexDirection="column" alignItems="start">
                      <MKTypography variant="body1" fontSize="12px" fontWeight="bold" color="white">{`${localeMapping.alert}: ${String(localeMapping[accident.tag] || localeMapping.others).toUpperCase()}`}</MKTypography>
                      <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white">{accident?.patient ? `${localeMapping.patient}: ${accident.patient.name}` : `Device: ${accident.device.device_number}`}</MKTypography>
                      <MKBox display="flex" flexDirection="row" alignItems="center">
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{localeMapping.radar_status}</MKTypography>
                        {accident?.patient?.radar_id
                          ? (radarData[accident?.patient?.radar_id] ? (currentTimestamp - radarData[accident?.patient?.radar_id]?.timestamp > 15000 ? offlineIcon() : onlineIcon()) : offlineIcon())
                          : <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white">{`(${localeMapping.not_registered})`}</MKTypography>}
                      </MKBox>
                      <MKBox display="flex" flexDirection="row" alignItems="center">
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{`${localeMapping.heart_rate}:`}</MKTypography>
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{radarData[accident?.patient?.radar_id]?.heart_bpm || '-'}</MKTypography>
                        {radarData[accident?.patient?.radar_id]?.heart_bpm === 0 && <WarningIcon style={{ color: 'yellow' }} />}
                      </MKBox>
                      <MKBox display="flex" flexDirection="row" alignItems="center">
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{`${localeMapping.breath_rate}:`}</MKTypography>
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{radarData[accident?.patient?.radar_id]?.breath_bpm || '-'}</MKTypography>
                        {radarData[accident?.patient?.radar_id]?.breath_bpm === 0 && <WarningIcon style={{ color: 'yellow' }} />}
                      </MKBox>
                      <MKBox display="flex" flexDirection="row" alignItems="center">
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{`${localeMapping.presence}:`}</MKTypography>
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{radarData[accident?.patient?.radar_id]?.heart_bpm > 0 ? 'YES' : 'NO'}</MKTypography>
                        {radarData[accident?.patient?.radar_id]?.heart_bpm > 0 ? presenceIcon(true) : presenceIcon(false)}
                      </MKBox>
                      <MKBox display="flex" flexDirection="row" alignItems="center">
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{localeMapping.device_status}</MKTypography>
                        {accident?.device?.active ? onlineIcon() : offlineIcon()}
                      </MKBox>
                      <MKBox display="flex" flexDirection="row" alignItems="center">
                        <MKTypography variant="body1" fontSize="12px" fontWeight="regular" color="white" sx={{ mr: 1 }}>{localeMapping.camera_status}</MKTypography>
                        {accident?.camera?.active ? onlineIcon() : offlineIcon()}
                      </MKBox>

                    </MKBox>
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </MKBox>
        {timeSinceLastAccident !== 0 && (<MKTypography mt={1} variant="body2" color="black" fontSize="16px">{`Since last alert: ${timeSinceLastAccident}`}</MKTypography>)}
      </MKBox>
      {open && (
        <ReportIssuingDialog
          info={info}
          open={open}
          onClose={handleCloseIssuingDialog}
          onReportToPolice={handlePoliceReport}
          radarData={radarData}
          onIssueSolved={() => {
            navigate(`/users/report-submission?ward_id=${info.ward_id}&alert_id=${info.accident.alert_id}`);
          }}
        />
      )}
      <LogDisplayerDialog
        open={logsOpen}
        ward={ward}
        onClose={handleCloseLogModal}
      />
    </MKBox>
  );
};

ProblemWardCard.propTypes = {
  ward: PropTypes.object.isRequired,
  radarData: PropTypes.object,
};

export default ProblemWardCard;
