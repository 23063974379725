import api from 'api';

export const getAIScripts = (params) => {
  return api.get('/fall_detection_scripts', params, 'bearer');
};

export const getWatchdogScripts = (params) => {
  return api.get('/watchdog_scripts', params, 'bearer');
};

export const getAIScript = (id, params) => {
  return api.get(`/fall_detection_scripts/${id}`, params, 'bearer');
};

export const getWatchdogScript = (id, params) => {
  return api.get(`/watchdog_scripts/${id}`, params, 'bearer');
};

export const updateAIScript = (id, body) => {
  return api.put(`/fall_detection_scripts/${id}`, body, 'bearer');
};

export const updateWatchdogScript = (id, body) => {
  return api.put(`/watchdog_scripts/${id}`, body, 'bearer');
};

export const createAIScript = (body) => {
  return api.post('/fall_detection_scripts', body, 'bearer');
};

export const createWatchdogScript = (body) => {
  return api.post('/watchdog_scripts', body, 'bearer');
};

export const deleteAIScript = (id) => {
  return api.delete(`/fall_detection_scripts/${id}`, 'bearer');
};

export const deleteWatchdogScript = (id) => {
  return api.delete(`/watchdog_scripts/${id}`, 'bearer');
};
