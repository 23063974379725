import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Image from 'components/Image';

const ContactFooterSection = ({ logo_url, address, email, website, copyright, linked_in_url, background_url, editable, onPressEdit, ...props }) => {
  return (
    <MKBox
      pt="3rem"
      component="footer"
      sx={background_url ? {
        backgroundImage: `url(${background_url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      } : null}
      {...props}
    >
      <Container>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <Image src={logo_url} height="3rem" />
          <Button
            iconOnly
            variant="text"
            href={linked_in_url}
          >
            <Image
              src="http://www.cionex.net/diy-server/contents/files/linkedin-round.png"
              height="2.5rem"
            />
          </Button>
        </MKBox>
      </Container>
      <Divider sx={{ background: 'white', my: 5 }} />
      <Container>
        <Grid container>
          <Grid item xs={12} lg={8} xl={6} mb={4}>
            <MKTypography variant="h5" color="white" mb={1}>
              Contact Us
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={3}>
              {address}
            </MKTypography>
            <MKBox display="flex" alignItems="center" mb={3}>
              <Image
                src="http://www.cionex.net/diy-server/contents/files/email-green.png"
                height="1rem"
              />
              <MKTypography
                component="a"
                href={`mailto:${email}`}
                variant="body2"
                color="white"
                ml="0.5rem"
              >
                {email}
              </MKTypography>
            </MKBox>
            <MKBox mb={3}>
              <MKTypography
                component="a"
                href={!(/^(http|https)/.test(website)) ? `https://${website}` : website}
                target="_blank"
                rel="noreferrer"
                variant="body2"
                color="white"
              >
                {website}
              </MKTypography>
            </MKBox>
            <Button
              href={`mailto:${email}`}
              color="success"
              size="large"
              fontSize={16}
              borderRadius="4px"
            >
              Contact Us
            </Button>
            <MKTypography variant="body2" color="white" pt={8}>
              {copyright}
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

ContactFooterSection.propTypes = {
  logo_url: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
  copyright: PropTypes.string,
  linked_in_url: PropTypes.string,
  background_url: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default ContactFooterSection;
