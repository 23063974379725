import SimpleHeaderSection from 'sections/SimpleHeader';
import MenuHeaderSection from 'sections/MenuHeader';
import SimpleFooterSection from 'sections/SimpleFooter';
import ContactFooterSection from 'sections/ContactFooter';
import LoginSection from 'sections/Login';
import TextSection from 'sections/Text';
import DateSection from 'sections/Date';
import ImageSection from 'sections/Image';
import ButtonSection from 'sections/Button';
import IconSection from 'sections/Icon';
import FlexBoxSection from 'sections/FlexBox';
import GridsSection from 'sections/Grids';
import BreadcrumbsSection from 'sections/Breadcrumbs';
import FileUploaderSection from 'sections/FileUploader';
import UsersTableSection from 'sections/UsersTable';
import SolutionsTableSection from 'sections/SolutionsTable';
import DynamicTableListViewSection from 'sections/DynamicTableListView';
import DynamicTableDetailViewSection from 'sections/DynamicTableDetailView';
import DynamicTableAttributeViewSection from 'sections/DynamicTableAttributeView';
import EditCollectionFormSection from 'sections/EditCollectionForm';
import CollectionBreadcrumbsSection from 'sections/CollectionBreadcrumbs';
import EditSolutionSection from 'sections/EditSolution';
import AppListViewSection from 'sections/AppListView';
import AppCreateSection from 'sections/AppCreate';
import EditUserSection from 'sections/EditUser';
import EditInputsFormSection from 'sections/EditInputsForm';
import TwoButtonCallApiSection from 'sections/TwoButtonCallApi';
import BusinessLogicListViewSection from 'sections/BusinessLogicListView';
import BusinessLogicCreateSection from 'sections/BusinessLogicCreate';
import BusinessLogicEditSection from 'sections/BusinessLogicEdit';
import EventTriggerEditSection from 'sections/EventTriggerEdit';
import EventTriggerCreateSection from 'sections/EventTriggerCreate';
import HandlerCreateSection from 'sections/HandlerCreate';
import HandlerEditSection from 'sections/HandlerEdit';
import SignalCreateSection from 'sections/SignalCreate';
import SignalEditSection from 'sections/SignalEdit';
import HandlerParameterCreateSection from 'sections/HandlerParameterCreate';
import HandlerParameterEditSection from 'sections/HandlerParameterEdit';
import BreadcrumbHeaderSection from 'sections/BreadcrumbHeader';
import BannerSection from 'sections/Banner';
import MapSection from 'sections/Map';
import HeaderNavbarSection from 'sections/HeaderNavbar';
import AccordionSection from 'sections/Accordion';
import MetadataSection from 'sections/Metadata';
import TextFromApiSection from 'sections/TextFromApi';
import CollapseBoxSection from 'sections/CollapseBox';
import ForgetPasswordSection from 'sections/ForgetPassword';
import ResetPasswordSection from 'sections/ResetPassword';
import ContactFormSection from 'sections/ContactForm';
import CarouselSection from 'sections/Carousel';
import ImageWithLink from 'sections/ImageWithLink';
import FloatingIcon from 'sections/FloatingIcon';
import RichTextSection from 'sections/RichText';
import AlphaVisionHeader from 'sections/AVHeader';
import AVHome from 'sections/AVHome';
import AVServiceProvidersManagement from 'sections/AVServiceProvidersManagement';
import LanguageButtonSection from 'sections/LanguageButton';
import NewGoogleTranslateButtonSection from 'sections/NewGoogleTranslateButton';
import ProviderCreateSection from './AVServiceProvidersManagement/createProvider';
import ProviderEditSection from './AVServiceProvidersManagement/editProvider';
import CenterEditSection from './AVCentersManagement/editCenter';
import WardEditSection from './AVWardsManagement/editWard';
import CenterCreateSection from './AVCentersManagement/createCenter';
import WardCreateSection from './AVWardsManagement/createWard';
import WardPatientsOverview from './AVPatient';
import AVDevicesManagementSection from './AVDevices';
import DeviceCreateSection from './AVDevices/createDevice';
import AVDevicesListViewSection from './AVDevices/deviceListViewPage';
import AVDevicesViewSection from './AVDevices/deviceView';
import DeviceEditSection from './AVDevices/editDevice';
import WardDeviceOverviewWithList from './AVDevices/deviceWardOverview';
import AVReportsHomeSection from './AVReports';
import ReportSubmissionPage from './AVReportSubmission';
import AVWardIssueReportsListViewSection from './AVWardIssuesReportsList';
import DebugSection from './Debug';
import AVPatientCreatePage from './AVCreatePatient';
import AVNotificationsMgt from './AVNotificationsMgt';
import AVHistorySection from './AVHistory';
import AVWardDetailsPage from './AVWardDetailsPage';
import WardPatientDetailsSection from './AVWardPatientDetails';
import NewReportSubmissionSection from './AVWardPatientDetails/newReportSubmissionPage';
import AVPatientEditPage from './AVEditPatient';
import AVCamerasListViewSection from './AVDevices/Cameras/cameraListViewPage';
import CameraCreateSection from './AVDevices/Cameras/createCamera';
import CameraEditSection from './AVDevices/Cameras/editCamera';
import AVWardCamerasList from './AVWardsManagement/camerasListPage';
import AVAIScriptUploader from './AVAIScriptUploaderPage';
import AIScriptCreateSection from './AVAIScriptUploaderPage/createScript';
import AVWatchdogScriptUploader from './AVWatchdogScriptUploaderPage';
import WatchdogScriptCreateSection from './AVWatchdogScriptUploaderPage/createScript';
// import ScriptEditSection from './AVAIScriptUploaderPage/editScript';
import AVSoftwaresListViewSection from './AVSoftwares/softwareListViewPage';
import SkeletonReportGeneratorSection from './SkeletonReportGenerator';
import SkeletonReplaySection from './SkeletonReplay';
import DeviceReportSection from './DeviceReport';

export const sectionDefinitionComponentMapping = {
  '25310d81-d558-40a8-bac5-18be605cae8f': SimpleHeaderSection,
  '092b2173-d14f-4f94-998c-0470cc0d6577': MenuHeaderSection,
  'a32667b8-22a4-4efe-9986-16877aa9076a': SimpleFooterSection,
  '5234c831-83fc-4e57-879d-b45ea6e18120': ContactFooterSection,
  '7b7002a2-bcc1-4ba5-a12f-8e6b9af7cc78': LoginSection,
  '656bb778-f965-457d-8f79-9e5aaaeda369': TextSection,
  '49daf9ec-f5d2-4cbf-af3f-d88291c1d907': DateSection,
  '0187cbe3-eaba-44a4-bd2d-0393c20f99b3': ImageSection,
  'dc5ed9fd-fcff-4320-81bc-f1b4ee55e637': ButtonSection,
  '42de2900-6bfb-442f-ac99-ce8e373b131d': IconSection,
  '2f9195b1-6c86-448a-8bf2-74196c8e8788': FlexBoxSection,
  'a56b67a1-f366-4891-b89e-8b275e33e7a4': GridsSection,
  '23ed1cc0-2376-4d52-8719-5e0344ebd3ae': BreadcrumbsSection,
  '11317177-6f1f-4859-976a-0347c6750740': FileUploaderSection,
  '7065ea00-46eb-4426-bb55-a5ed9bc5dc32': UsersTableSection,
  'b8344014-a62d-47ac-abd1-5b81427e95c6': SolutionsTableSection,
  'a328c693-fdb4-47a5-80f2-5133cdd55517': DynamicTableListViewSection,
  '08f9d734-9822-4ee8-a65d-7c73ab93d164': DynamicTableDetailViewSection,
  '5f517188-8cc9-4a46-810a-344ab73d7b87': DynamicTableAttributeViewSection,
  'fee5da64-84a8-4d8e-b743-09398979bed2': EditCollectionFormSection,
  '72f7d214-9c7a-4b69-b7ce-7562fb1c9b6a': CollectionBreadcrumbsSection,
  '5fb3219c-e8c0-4c72-8981-13bc53bdb66e': EditSolutionSection,
  '0e19f2f7-e35e-4dc3-bdac-ae1efe210bcc': AppListViewSection,
  '7a9ab307-7e4e-4cc0-adae-8c0be3f800b2': AppCreateSection,
  'ba83f9cc-20a9-4f2a-bdde-45caf9efac49': EditUserSection,
  'f522b59f-7bfd-4b6f-acfe-e259b91440d3': EditInputsFormSection, // 1 Inputs
  'c9373cfd-26b5-4bc4-8add-c8987f0a15a4': EditInputsFormSection, // 2 Inputs
  '1ec17af6-5f56-4fa3-8343-027d017c5342': EditInputsFormSection, // 3 Inputs
  '6a75bb25-1492-48e8-80a2-e1678e424acb': EditInputsFormSection, // 4 Inputs
  '83b00d21-f824-44bb-9313-b295ed35210f': TwoButtonCallApiSection,
  'bc503c6d-a3cb-4da7-b998-7e8b1e5a688f': BusinessLogicListViewSection,
  '60857454-31a9-4430-9596-2b74bc921fef': BusinessLogicCreateSection,
  'c67b06ab-2ecc-4f8c-b078-ebd5afa25264': BusinessLogicEditSection,
  'e02b0681-7bd0-43a4-b5fb-8e986ddb60e4': EventTriggerCreateSection,
  'def77128-42c1-4855-a1e0-d449f47ffdd2': EventTriggerEditSection,
  '6bd3f2b5-bd32-45aa-b6a8-ecdef08b92fa': HandlerCreateSection,
  '651c607d-4eab-4f05-958e-dede8f422aff': HandlerEditSection,
  '29cb023d-3138-4242-88bf-054ac7206568': SignalCreateSection,
  '545dbb6f-fc3b-4586-8b91-7157fcb4f5b9': SignalEditSection,
  'ca9d59b1-11ac-4f70-b9a1-ce11fe90d6ee': HandlerParameterCreateSection,
  '1e561303-5326-442b-9806-d70b08f63a15': HandlerParameterEditSection,
  '7a57def6-64e2-4bd9-a2bb-01ced8e6afad': BreadcrumbHeaderSection,
  '0910c381-fb71-4e25-968b-c0bb6cd93e1a': BannerSection,
  'db5ddb16-b545-4a19-a56a-de55bbd743cc': MapSection,
  'd0486481-7162-4b0c-b3b5-86b2e348cfec': HeaderNavbarSection,
  '039e5a9f-4f38-4fff-8c21-b4bc19967504': AccordionSection,
  '35e71e6a-dd84-4356-b0e1-dd63c93b4b27': MetadataSection,
  'c112b083-d358-43b1-945c-5dee2ba8a872': TextFromApiSection,
  '4e88f276-5d9b-40d5-bc32-51e1b67d735c': CollapseBoxSection,
  '9fe3edf1-d978-4bb5-9d93-d4db6de7d508': ForgetPasswordSection,
  '018d45de-58ef-797c-8515-873dbd879ef7': ResetPasswordSection,
  'b8dd4b12-1d9f-46d7-8621-b972598a0f6a': ContactFormSection,
  '6003e507-b89b-4205-8fe7-23c8dd26d13f': CarouselSection,
  '2ff5acd9-6a46-4e3a-9321-3f2099a8aa19': ImageWithLink,
  '120d543c-3174-4e32-bbac-413dc1a92be5': FloatingIcon,
  '48ec46f1-d586-417a-9c0c-b636b2c5da05': RichTextSection,
  'da2f92ef-e9f6-49e9-bbe0-7f6c5c11049e': AlphaVisionHeader,
  '735bbed2-873c-4ee1-879f-f7ecbc962d85': AVHome,
  '2a6cff71-9d9b-4cdf-8615-62efc14eecf5': AVServiceProvidersManagement,
  '7f8b198d-25f3-4387-aa06-510c1d60a049': ProviderCreateSection,
  '30e5e220-5278-411a-b2e2-6b39b7449a88': ProviderEditSection,
  '0542c86b-1789-4d48-a30d-b5f78cb377da': CenterCreateSection,
  '458d48fe-a450-4c83-a191-546a0c0a54f0': CenterEditSection,
  '9b8f4dfb-6461-4f7d-b7c7-0b2e01bfcb4b': WardCreateSection,
  'b5fa8eea-da60-4d93-b474-fd18f6f116db': WardEditSection,
  'a254607e-f983-4578-970b-15c7fb013d41': WardPatientsOverview,
  '189337f4-1959-49a2-af0f-3757e4b299bf': AVDevicesManagementSection,
  '5366f889-09f4-410e-937b-560e47d7e1d4': DeviceCreateSection,
  'd8506ea6-703a-4798-81b9-455645f35e63': AVDevicesListViewSection,
  '6da81742-1892-4ca4-9f1f-8d39c7f4203e': AVDevicesViewSection,
  'c8f711bb-a6e6-4abd-849d-ad5f373f8a14': DeviceEditSection,
  '30ba7c3b-6514-42b6-9f16-2bb892313b8a': WardDeviceOverviewWithList,
  'aadd5dc0-2010-4053-876c-390492116a67': AVReportsHomeSection,
  'b1c7a52e-a045-4ff6-aada-0fb98dd3cda8': ReportSubmissionPage,
  'f4aa8de6-e269-4afd-822f-2c62f578c2ce': AVWardIssueReportsListViewSection,
  '018f9b5e-6bb0-7759-bce0-3be7ee82ce19': DebugSection,
  '6dde8913-e505-410d-9259-9d4b3824834f': AVPatientCreatePage,
  'e8fdaadb-281a-47ae-85ca-50060611240f': AVNotificationsMgt,
  'f9252336-fa57-46a1-accf-bef7de233c6b': AVHistorySection,
  'b11c32e4-d8c7-4f12-afdd-2c84dd2c42eb': AVWardDetailsPage,
  '9ddbce27-c445-43bf-888d-e5af6a9d203e': WardPatientDetailsSection,
  '7e0ab802-1b05-4ebb-ab9f-e41393ace8ad': NewReportSubmissionSection,
  '3367e691-2994-430a-b5bd-cfb3e3fd3aee': AVPatientEditPage,
  '757755dd-9ede-4e2b-b984-58ce18701074': AVCamerasListViewSection,
  'a1931c64-7c21-47fb-8a1f-d142d4a29d40': CameraCreateSection,
  'a364c552-1252-4324-b288-1cb3c7cbc595': CameraEditSection,
  '1157bb37-daa4-4965-b0a2-30ab3e7c12fe': AVWardCamerasList,
  '1471dd18-7ee7-4165-a676-4d0036f91ddb': AVAIScriptUploader,
  '3960cd9e-c9aa-4181-8f0e-e74242018e41': AIScriptCreateSection,
  // 'e92ae313-247b-4c31-9a24-35f349a60908': ScriptEditSection,
  '01903570-f9b1-7e35-ba36-565185019bb1': AVSoftwaresListViewSection,
  '019038c3-ed8a-7b8c-ae84-8b941d468e10': AVWatchdogScriptUploader,
  '019038c5-f0fa-7c51-826d-ceb4370898c1': WatchdogScriptCreateSection,
  '018e809b-4df2-7740-b98f-4be13c10328b': LanguageButtonSection,
  '018ee17b-6a6b-742e-bbf3-0930f30cfd85': NewGoogleTranslateButtonSection,
  '0190fd50-47fd-729f-ba1a-353abc01b004': SkeletonReportGeneratorSection,
  '0190fe7c-6ffe-7bc2-99b2-69c6330d5895': SkeletonReplaySection,
  '01925607-102e-7548-ba4e-f35b79e77446': DeviceReportSection,
};
