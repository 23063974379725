import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Files from 'react-files';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CropIcon from '@mui/icons-material/Crop';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import Select from 'components/Select';
import Modal from 'components/Modal';
import { parseJSON } from 'utils/general';
import ImageCropper from './ImageCropper';

const getGCD = (a, b) => {
  if (!b) return a;
  return getGCD(b, a % b);
};

const getValuesFromResolution = (resolutionStr) => {
  const resolutionMatch = /([0-9]+)[ ]*x[ ]*([0-9]+)/.exec(resolutionStr);
  if (resolutionMatch && resolutionMatch[1] && resolutionMatch[2]) {
    return {
      width: Number(resolutionMatch[1]),
      height: Number(resolutionMatch[2]),
    };
  }
  return null;
};

const getImageFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const image = new Image();
      image.onload = () => {
        resolve(image);
      };
      image.onerror = (err) => {
        reject(err);
      };
      image.src = fileReader.result;
    };
    fileReader.readAsDataURL(file);
  });
};

const getVideoFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      resolve(video);
    };
    video.onerror = (err) => {
      reject(err);
    };
    video.src = url;
    video.load();
  });
};

const validateResolutions = (file, validResolutions) => {
  if (validResolutions?.length > 0) {
    let getMediaDimension = Promise.resolve([]);
    if ((file.type || '').startsWith('image')) {
      getMediaDimension = getImageFromFile(file)
        .then((image) => ([image.width, image.height]));
    } else if ((file.type || '').startsWith('video')) {
      getMediaDimension = getVideoFromFile(file)
        .then((video) => ([video.videoWidth, video.videoHeight]));
    }
    return getMediaDimension
      .then(([width, height]) => {
        const isValid = validResolutions.reduce((prevValid, resolution) => {
          if (width <= resolution.width && height <= resolution.height) {
            return true;
          }
          return prevValid;
        }, false);
        return isValid;
      });
  }
  return Promise.resolve(true);
};

const FilePicker = ({ onAdd, onError, buttonText, currentFile, maxFiles, maxFileSize, minFileSize, acceptFileTypes, acceptResolutions, imageCroppable, buttonProps, ...props }) => {
  const [cropperOpen, setCropperOpen] = useState(false);
  const [fixedAspectRatio, setFixedAspectRatio] = useState(true);
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(NaN);

  const validResolutions = useMemo(() => (acceptResolutions || []).reduce((list, acceptResolution) => {
    const resolution = getValuesFromResolution(acceptResolution);
    if (!resolution) {
      return list;
    }
    return [...list, resolution];
  }, []), [acceptResolutions]);

  const onChange = useCallback((files) => {
    if (files && files.length > 0) {
      const file = files[files.length - 1];
      return validateResolutions(file, validResolutions)
        .then((isResolutionValid) => {
          if (isResolutionValid) {
            return onAdd(file);
          }
          const errMsg = `Invalid Resolution (w x h): ${validResolutions.map((resolution) => `${resolution.width}x${resolution.height}`).join(', ')}`;
          if (window.confirm(`${errMsg}$\nDo you still want to continue?`)) {
            return onAdd(file);
          }
          throw new Error(errMsg);
        })
        .catch(onError);
    }
    return Promise.resolve();
  }, [validResolutions, onAdd, onError]);

  const onToggleCropper = useCallback(() => {
    setCropperOpen((isOpen) => !isOpen);
  }, []);

  const onUpdateCroppedImage = useCallback((file, cropData) => {
    const originalFileId = currentFile.original_file?.file_id || currentFile?.file_id;
    onAdd(file, originalFileId, cropData);
    setCropperOpen(false);
  }, [currentFile, onAdd]);

  const aspectRatioOptions = useMemo(() => validResolutions.map((resolution) => {
    const whGCD = getGCD(resolution.width, resolution.height);
    return {
      label: `${resolution.width / whGCD}:${resolution.height / whGCD}`,
      value: resolution.width / resolution.height,
    };
  }), [validResolutions]);

  useEffect(() => {
    setSelectedAspectRatio(aspectRatioOptions[0]?.value || NaN);
  }, [aspectRatioOptions]);

  const cropData = useMemo(() => {
    return parseJSON(currentFile?.extra_data, {});
  }, [currentFile]);

  return (
    <MKBox display="flex" flexDirection="row">
      <Button {...buttonProps}>
        {buttonText}
        <Files
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          onChange={onChange}
          onError={onError}
          accepts={acceptFileTypes}
          nultiple={maxFiles > 1}
          maxFiles={maxFiles}
          maxFileSize={maxFileSize > 0 ? maxFileSize : Infinity}
          minFileSize={minFileSize}
          {...props}
        />
      </Button>
      {imageCroppable && !!currentFile && (
        <>
          <MKBox display="flex" flexDirection="row" alignItems="center" sx={{ ml: 1 }}>
            <MKBox>
              <Button onClick={onToggleCropper} {...buttonProps}>
                <CropIcon sx={{ mr: 0.6 }} />
                Crop
              </Button>
            </MKBox>
            {!!selectedAspectRatio && (
              <FormControlLabel
                sx={{ ml: 0.2, textWrap: 'nowrap' }}
                label={`Fixed Ratio${aspectRatioOptions.length === 1 ? ` [ ${aspectRatioOptions.find(({ value }) => value === selectedAspectRatio)?.label} ]` : ''}`}
                control={(
                  <Checkbox
                    sx={{ pr: 0 }}
                    checked={fixedAspectRatio}
                    onClick={(e) => setFixedAspectRatio(e.target.checked)}
                  />
                )}
              />
            )}
            {aspectRatioOptions.length > 1 && (
              <Select
                value={selectedAspectRatio}
                options={aspectRatioOptions}
                onChange={setSelectedAspectRatio}
              />
            )}
          </MKBox>
          <Modal title="Crop Image" isOpen={cropperOpen} onClose={() => setCropperOpen(false)}>
            <MKBox p={2}>
              <ImageCropper
                url={(currentFile?.original_file || currentFile)?.file_url}
                name={currentFile.file_name}
                cropData={cropData}
                onConfirm={onUpdateCroppedImage}
                onCancel={() => setCropperOpen(false)}
                aspectRatio={fixedAspectRatio ? selectedAspectRatio : NaN}
                initialAspectRatio={selectedAspectRatio}
              />
            </MKBox>
          </Modal>
        </>
      )}
    </MKBox>
  );
};

FilePicker.propTypes = {
  onAdd: PropTypes.func,
  onError: PropTypes.func,
  buttonText: PropTypes.string,
  currentFile: PropTypes.any,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
  minFileSize: PropTypes.number,
  acceptFileTypes: PropTypes.arrayOf(PropTypes.string),
  acceptResolutions: PropTypes.arrayOf(PropTypes.string),
  imageCroppable: PropTypes.bool,
  buttonProps: PropTypes.object,
};

FilePicker.defaultProps = {
  onAdd: () => { },
  onError: () => { },
  buttonText: 'Upload',
  currentFile: null,
  maxFiles: 1,
  maxFileSize: Infinity,
  minFileSize: 0,
  acceptFileTypes: ['*/*'],
  acceptResolutions: [],
  imageCroppable: false,
  buttonProps: {},
};

export default FilePicker;
