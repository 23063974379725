import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';

const TextInput = ({ value, onChange, label, type, multiline, minRows, maxRows, maxLength, inputProps, ...props }) => {
  return (
    <MKBox sx={{ position: 'relative', width: '100%' }}>
      <MKInput
        label={label}
        value={typeof value === 'number' ? `${value}` : (value || '')}
        onChange={onChange}
        type={type}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        inputProps={{ maxLength, ...inputProps }}
        fullWidth
        {...props}
      />
      {maxLength > 0 && maxLength < Infinity && (
        <MKTypography
          variant="caption"
          color={`${value}`.length >= maxLength ? 'error' : 'secondary'}
          sx={{ position: 'absolute', bottom: 4, right: 8 }}
        >
          {`${`${value}`.length}/${maxLength}`}
        </MKTypography>
      )}
    </MKBox>
  );
};

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  inputProps: PropTypes.object,
};

TextInput.defaultProps = {
  value: '',
  onChange: () => {},
  label: null,
  type: 'text',
  multiline: false,
  inputProps: {},
};

export default TextInput;
