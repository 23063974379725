import api from 'api';

export const getPages = (params) => {
  return api.get('/pages', params, 'bearer');
};

export const getPage = (id, params) => {
  return api.get(`/pages/${id}`, params, 'bearer');
};

export const createPage = (body) => {
  return api.post('/pages', body, 'bearer');
};

export const updatePage = (id, body) => {
  return api.put(`/pages/${id}`, body, 'bearer');
};

export const deletePage = (id) => {
  return api.delete(`/pages/${id}`, 'bearer');
};

export const getPublicPages = (params) => {
  return api.get('/public/pages', params);
};

export const getPublicPage = (id, params) => {
  return api.get(`/public/pages/${id}`, params);
};

export const getLayouts = (params) => {
  return api.get('/layouts', params, 'bearer');
};

export const getLayout = (id, params) => {
  return api.get(`/layouts/${id}`, params, 'bearer');
};
