import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CollapseBox from 'components/CollapseBox';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { getPublicSection } from 'api/sections';
import { useAuth } from 'contexts/auth';

const CollapseBoxSection = ({ collapsing_section, icon_open_url, icon_close_url, editable, onPressEdit, ...props }) => {
  const [sectionNode, setSection] = useState();
  const [sectionsProps, setSectionsProps] = useState({});
  const { auth, setAuth } = useAuth();

  const fetchSectionFromApi = useCallback((id) => {
    const sectionParams = {
      'section_id[in]': [id],
      '$expand': 'section_definition/collection_definition/attributes',
    };
    return getPublicSection(id, sectionParams)
      .then(({ data }) => {
        return data;
      });
  }, []);

  const fetchRenderSectionFromApi = useCallback((id) => {
    return fetchSectionFromApi(id)
      .then((section) => {
        setSection(section);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchSectionFromApi, setAuth]);

  const renderNode = useCallback((section) => {
    if (!section) {
      return null;
    }
    const sectionProps = sectionsProps ? sectionsProps[section.section_id] : {};
    return renderSectionComponent(section, sectionProps, false, null);
  }, [sectionsProps]);

  useEffect(() => {
    if (!sectionNode) {
      return;
    }
    const { section_id } = sectionNode;
    fetchSectionProps(sectionNode, !auth.user)
      .then((sectionProps) => {
        setSectionsProps((oriSectionProps) => {
          const updatedSectionProps = { ...oriSectionProps };
          updatedSectionProps[section_id] = sectionProps;
          return updatedSectionProps;
        });
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [sectionNode, auth.user, setAuth]);

  useEffect(() => {
    if (collapsing_section) {
      fetchRenderSectionFromApi(collapsing_section);
    }
  }, [collapsing_section, fetchRenderSectionFromApi]);

  const Node = useMemo(() => renderNode(sectionNode), [sectionNode, renderNode]);

  const offset = {
    top: props.top_offset,
    left: props.left_offset,
    right: props.right_offset,
    bottom: props.bottom_offset,
  };

  const iconSize = {
    width: props.icon_width,
    height: props.icon_height,
  };

  return (
    <CollapseBox
      sectionNode={[Node]}
      iconOpenUrl={icon_open_url}
      iconCloseUrl={icon_close_url}
      offset={offset}
      iconSize={iconSize}
      {...props}
    />
  );
};

CollapseBoxSection.propTypes = {
  collapsing_section: PropTypes.string,
  icon_open_url: PropTypes.string,
  icon_close_url: PropTypes.string,
  top_offset: PropTypes.string,
  left_offset: PropTypes.string,
  right_offset: PropTypes.string,
  bottom_offset: PropTypes.string,
  icon_width: PropTypes.string,
  icon_height: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default CollapseBoxSection;
