import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { getLocaleMap } from 'utils/locales';
import { useEffect, useState } from 'react';

const ResetPasswordForm = ({
  onResetPassword,
  initialValues,
  submitButtonColor,
  submitButtonTitle,
  submitTextColor,
  backButtonColor,
  backButtonTitle,
  backTextColor,
  emailPlaceholder,
  emailLabel,
  ...props }) => {
  const [lm, setLm] = useState({});
  useEffect(() => {
    getLocaleMap([
      'labels_forgot_password_step_2',
      'labels_forgot_password_step_2_description',
      'labels_temporary_password',
      'labels_new_password',
      'errors_reenter_new_password',
      'labels_reenter_new_password_text',
      'back_button',
      'submit_button',
      'errors_required',
      'errors_invalid_email'])
      .then((response) => {
        setLm(response);
      });
  }, []);

  const getFormValidation = (values) => {
    const { temporary_password, new_password, reenter_new_password } = values;
    const errors = {};
    if (!temporary_password) {
      errors.temporary_password = lm.errors_required;
    }
    if (!new_password) {
      errors.new_password = lm.errors_required;
    }
    if (!reenter_new_password) {
      errors.reenter_new_password = lm.errors_required;
    }
    if (new_password !== reenter_new_password) {
      errors.reenter_new_password = lm.errors_reenter_new_password;
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onResetPassword}
      validate={getFormValidation}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched, setFieldValue }) => {
        return (
          <MKBox
            component="form"
            role="form"
            onSubmit={handleSubmit}
            style={{
              padding: '0px',
              fontSize: '16px',
            }}
          >
            <Grid mb="30px">
              <MKTypography color="black" fontSize="18px">
                {lm.labels_forgot_password_step_2}
              </MKTypography>
            </Grid>
            <Grid mb="30px">
              <MKTypography color="black" fontSize="16px">
                {lm.labels_forgot_password_step_2_description}
              </MKTypography>
            </Grid>
            <Grid container mb="30px" justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  {lm.labels_temporary_password}
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8}>
                <InputField
                  name="temporary_password"
                  value={values.temporary_password || ''}
                  type="password"
                  label={lm.labels_temporary_password}
                  placeholder={lm.labels_temporary_password}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.temporary_password && !!errors.temporary_password}
                />
                {touched.temporary_password && !!errors.temporary_password && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.temporary_password}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            <Grid container mb="30px" justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  {lm.labels_new_password}
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8}>
                <InputField
                  name="new_password"
                  value={values.new_password || ''}
                  type="password"
                  label={lm.labels_new_password}
                  placeholder={lm.labels_new_password}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.new_password && !!errors.new_password}
                />
                {touched.new_password && !!errors.new_password && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.new_password}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            <Grid container mb="30px" justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  {lm.labels_reenter_new_password_text}
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8}>
                <InputField
                  name="reenter_new_password"
                  value={values.reenter_new_password || ''}
                  type="password"
                  label={lm.labels_reenter_new_password_text}
                  placeholder={lm.labels_reenter_new_password_text}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.reenter_new_password && !!errors.reenter_new_password}
                />
                {touched.reenter_new_password && !!errors.reenter_new_password && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.reenter_new_password}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <Grid container mt="20vh" justifyContent="space-between" color="red">
              <Button color={backButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={backTextColor}>
                  {backButtonTitle || lm.back_button}
                </MKTypography>
              </Button>
              <Button type="submit" color={submitButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={submitTextColor}>
                  {submitButtonTitle || lm.submit_button}
                </MKTypography>
              </Button>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  onResetPassword: PropTypes.func,
  initialValues: PropTypes.object,
  submitButtonColor: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  submitTextColor: PropTypes.string,
  backButtonColor: PropTypes.string,
  backButtonTitle: PropTypes.string,
  backTextColor: PropTypes.string,
  emailLabel: PropTypes.string,
  emailPlaceholder: PropTypes.string,
};

ResetPasswordForm.defaultProps = {
  onResetPassword: () => {},
  initialValues: {},
};

export default ResetPasswordForm;
