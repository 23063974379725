import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { getPublicSections } from 'api/sections';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import MKBox from 'components/MaterialKit/MKBox';

const Banner = ({
  src,
  items,
  height,
  offsetTop,
  justifyContent,
  alignItems,
  editable,
  onPressEdit,
  ...props
}) => {
  const [subSections, setSubSections] = useState([]);
  const [subSectionsProps, setSubSectionsProps] = useState({});
  const { auth, setAuth } = useAuth();

  const fetchSectionsFromApi = useCallback((sectionIds) => {
    const sectionParams = {
      'section_id[in]': sectionIds.join(','),
      '$expand': 'section_definition/collection_definition/attributes',
    };
    return getPublicSections(sectionParams)
      .then(({ data }) => {
        setSubSections(data);
      });
  }, []);

  useEffect(() => {
    const sectionIds = (items || []).reduce((ids, item) => {
      const sections = item.sections || (item.section ? [item.section] : []);
      if (sections) {
        return [...ids, ...sections];
      }
      return ids;
    }, []);
    if (sectionIds.length > 0) {
      fetchSectionsFromApi(sectionIds, !auth.user)
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [items, fetchSectionsFromApi, auth.user, setAuth]);

  useEffect(() => {
    (subSections || []).forEach((subSection) => {
      const { section_id } = subSection;
      return fetchSectionProps(subSection, !auth.user)
        .then((subSectionProps) => {
          setSubSectionsProps((oriSubSectionsProps) => {
            const updatedSubSectionsProps = { ...oriSubSectionsProps };
            updatedSubSectionsProps[section_id] = subSectionProps;
            return updatedSubSectionsProps;
          });
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    });
  }, [subSections, auth.user, setAuth]);
  return (
    <MKBox
      position="relative"
      height={`${height}`}
      width="100%"
      justifyContent={justifyContent}
      alignItems={alignItems}
    >
      <MKBox
        position="absolute"
        width="100%"
        height={`${height}`}
        zIndex="1"
      >
        <img
          src={src}
          alt="banner"
          style={{
            width: '100%',
            height: '100%', // This will make the image take up the specified height
            objectFit: 'cover', // This property ensures that the image covers the entire box
          }}
        />
      </MKBox>
      <MKBox
        position="absolute"
        width="100%"
        top={offsetTop} // Center vertically
        transform="translate(-50%)"
        textAlign="center"
        zIndex="2"
      >
        {(items || []).map((item, i) => {
          const { sections, section, ...extraProps } = item;
          const sectionIds = sections || (section ? [section] : []);
          return (sectionIds || []).map((sectionId, j) => {
            const subSection = subSections.find((s) => s.section_id === sectionId);
            const sectionComponent = renderSectionComponent(subSection, subSectionsProps[sectionId], extraProps.editable, extraProps.onPressEdit);
            return subSection ? (
              <MKBox key={sectionId}>{sectionComponent}</MKBox>
            ) : null;
          });
        })}
      </MKBox>
    </MKBox>
  );
};

Banner.propTypes = {
  src: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  height: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  offsetTop: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default Banner;
