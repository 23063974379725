import api from 'api';

export const getMenus = (params) => {
  return api.get('/menus', params, 'bearer');
};

export const getMenu = (id, params) => {
  return api.get(`/menus/${id}`, params, 'bearer');
};

export const updateMenu = (id, body) => {
  return api.put(`/menus/${id}`, body, 'bearer');
};

export const getPublicMenus = (params) => {
  return api.get('/public/menus', params);
};

export const getPublicMenu = (id, params) => {
  return api.get(`/public/menus/${id}`, params);
};

export const getMenuItems = (params) => {
  return api.get('/menu_items', params, 'bearer');
};

export const getMenuItem = (id, params) => {
  return api.get(`/menu_items/${id}`, params, 'bearer');
};

export const updateMenuItem = (id, body) => {
  return api.put(`/menu_items/${id}`, body, 'bearer');
};

export const deleteMenuItem = (id) => {
  return api.delete(`/menu_items/${id}`, 'bearer');
};

export const createMenuItem = (body) => {
  return api.post('/menu_items', body, 'bearer');
};

export const getPublicMenuItems = (params) => {
  return api.get('/public/menu_items', params);
};

export const getPublicMenuItem = (id, params) => {
  return api.get(`/public/menu_items/${id}`, params);
};

export const getMenuItemDependencies = (params) => {
  return api.get('/menu_item_dependencies', params, 'bearer');
};

export const getMenuItemDependency = (id, params) => {
  return api.get(`/menu_item_dependencies/${id}`, params, 'bearer');
};

export const updateMenuItemDependency = (id, body) => {
  return api.put(`/menu_item_dependencies/${id}`, body, 'bearer');
};

export const deleteMenuItemDependency = (id) => {
  return api.delete(`/menu_item_dependencies/${id}`, 'bearer');
};

export const createMenuItemDependency = (body) => {
  return api.post('/menu_item_dependencies', body, 'bearer');
};

export const getPublicMenuItemDependencies = (params) => {
  return api.get('/public/menu_item_dependencies', params);
};

export const getPublicMenuItemDependency = (id, params) => {
  return api.get(`/public/menu_item_dependencies/${id}`, params);
};
