import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url, width, height, ...props }) => {
  return (
    <ReactPlayer
      url={url}
      width={width}
      height={height}
      controls
      {...props}
    />
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

VideoPlayer.defaultProps = {
  url: null,
  width: 'auto',
  height: 'auto',
};

export default VideoPlayer;
