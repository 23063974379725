import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getLocaleMap } from 'utils/locales';

const WardForm = ({ ward, isEdit, onSave, ...props }) => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['ward_name'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const initialValues = useMemo(() => ({
    name: ward?.name || '',
  }), [ward]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid container item xs={10}>
                <MKInput
                  name="name"
                  label={localeMapping.ward_name}
                  value={values.name || ''}
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={2}>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

WardForm.propTypes = {
  ward: PropTypes.shape({
    name: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

WardForm.defaultProps = {
  ward: {},
  isEdit: false,
  onSave: () => {},
};

export default WardForm;
