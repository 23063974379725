import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getHandlerParameter, updateHandlerParameter } from 'api/handler_parameters';
import EditParameterForm from './EditHandlerParameterForm';

const HandlerParameterEdit = () => {
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get('edit') === 'true';
  const handlerParameterId = searchParams.get('handler_parameter_id');
  const [handlerParameter, setHandlerParameter] = useState(null);
  const { setAuth } = useAuth();

  const fetchHandlerParameterFromApi = useCallback(() => {
    return handlerParameterId && getHandlerParameter(handlerParameterId)
      .then(({ data }) => {
        setHandlerParameter(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [handlerParameterId, setAuth]);

  const updateHandlerParameterToApi = (values, { setFieldError }) => {
    const updateBody = {
      handler_parameter_name: values.handler_parameter_name,
      parameter: values.parameter,
    };
    return updateHandlerParameter(handlerParameterId, updateBody)
      .then(({ data }) => {
        setHandlerParameter(data);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  };

  useEffect(() => {
    fetchHandlerParameterFromApi();
  }, [fetchHandlerParameterFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditParameterForm
          isEdit={isEdit}
          handlerParameter={handlerParameter}
          onSave={updateHandlerParameterToApi}
        />
      </Container>
    </MKBox>
  );
};

export default HandlerParameterEdit;
