import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { getNotifications } from 'api/alpha/notifications';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import DataTable from 'components/DataTable';
import { datetimeFormatter } from 'utils/datetime';

const NotificationsTable = () => {
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const [sortBy, setSortBy] = useState('push_notification_history_id');
  const [sortDirection, setSortDirection] = useState('asc');
  const [rowData, setRowData] = useState([]);

  const fetchNotificationsFromApi = useCallback(() => {
    if (!username) {
      return Promise.resolve();
    }
    const requestParams = {
      '$orderBy': `${sortBy} ${sortDirection}`,
      'user[eq]': username,
    };
    return getNotifications(requestParams)
      .then(({ data }) => {
        setRowData(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, sortBy, sortDirection, username]);

  const onClickSort = useCallback((field) => {
    if (field === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  }, [sortBy, sortDirection]);

  useEffect(() => {
    fetchNotificationsFromApi();
  }, [fetchNotificationsFromApi]);

  const columns = useMemo(() => [
    { field: 'push_notification_history_id', label: 'ID', sortable: true },
    { field: 'user', label: 'User', sortable: true },
    { field: 'title', label: 'Title' },
    { field: 'content', label: 'Content' },
    { field: 'receive_date', label: 'Date', sortable: true, formatter: datetimeFormatter },
  ], []);

  const rows = useMemo(() => {
    return rowData.map((row) => {
      return {
        ...row,
        receive_date: datetimeFormatter(row.receive_date, 'YYYY-MM-DD HH:mm:ss'),
      };
    });
  });

  return (
    <MKBox>
      <DataTable
        data={rows}
        columns={columns}
        idField="push_notification_history_id"
        selectable
        clickable
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
      />
    </MKBox>
  );
};

export default NotificationsTable;
