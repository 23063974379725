import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import mime from 'mime';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Image from 'components/Image';
import Modal from 'components/Modal';
import FilePicker from 'components/FilePicker';
import VideoPlayer from 'components/VideoPlayer';
import { INPUT_TYPE_IMAGE, INPUT_TYPE_VIDEO } from 'components/InputField';
import { getFile, createBigFile, updateFile } from 'api/files';
import { handleErrorResponse } from 'utils/general';
import { formatFileSize, getFileData } from 'utils/file';
import { withLoading } from 'utils/hoc';
import { useAuth } from 'contexts/auth';

const FileUploaderSection = ({
  file_id,
  input_label,
  button_text,
  button_color,
  button_variant,
  button_size,
  button_font_size,
  onChange,
  max_file_size,
  image_croppable,
  accept_file_types,
  accept_resolutions,
  disabled,
  setLoading,
  ...props
}) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { setAuth } = useAuth();

  const maxFileSizeInBytes = max_file_size * 1024 * 1024;

  const inputType = useMemo(() => {
    const fileMimeType = mime.getType(currentFile?.file_url) || '';
    return fileMimeType.split('/')[0] || '';
  }, [currentFile?.file_url]);

  const fetchFileFromApi = useCallback(() => {
    if (!file_id) {
      setCurrentFile(null);
      return Promise.resolve();
    }
    setLoading(true);
    const fileParams = {
      $expand: 'original_file',
    };
    return getFile(file_id, fileParams)
      .then(({ data }) => {
        setCurrentFile(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [file_id, setLoading, setAuth]);

  const onUploadFile = useCallback((fileObj, oriFileId, extraData) => {
    setLoading(true);
    return getFileData(fileObj, false)
      .then((fileData) => {
        return createBigFile(fileData);
      })
      .then((res) => {
        const { data } = res;
        if (!oriFileId) {
          return res;
        }
        const updateBody = {
          original_file: oriFileId,
          extra_data: JSON.stringify(extraData),
        };
        return updateFile(data.file_id, updateBody);
      })
      .then(({ data }) => {
        setCurrentFile(data);
        return onChange(data.file_id);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onChange, setLoading, setAuth]);

  const onFilePickError = useCallback((err, file) => {
    let errMsg;
    if (err.code === 1) {
      errMsg = `${file.name} (${file.type}) does not match the valid file type(s): ${(accept_file_types || []).join(', ')}`;
    } else if (err.code === 2) {
      errMsg = `${file.name} (${formatFileSize(file.size)}) exceeds the maximum file size: ${formatFileSize(maxFileSizeInBytes)}`;
    }
    handleErrorResponse(err, setAuth, errMsg);
  }, [accept_file_types, maxFileSizeInBytes, setAuth]);

  const deleteFileFromApi = useCallback(() => {
    setCurrentFile(null);
    return onChange('');
  }, [onChange]);

  useEffect(() => {
    fetchFileFromApi();
  }, [fetchFileFromApi]);

  return (
    <MKBox display="flex" flexDirection="column">
      <MKBox display="flex" alignItems="center">
        <MKBox flex={1}>
          <MKInput
            label={input_label}
            value={currentFile ? `${currentFile.file_name || ''} (${formatFileSize(currentFile.file_size)})` : ''}
            disabled
            fullWidth
          />
        </MKBox>
        <IconButton
          iconOnly
          disabled={!currentFile}
          edge="end"
          {...([INPUT_TYPE_IMAGE, INPUT_TYPE_VIDEO].includes(inputType) ? {
            onClick: () => setPreviewOpen(true),
          } : {
            href: currentFile?.file_url,
            target: '_blank',
            rel: 'noreferrer noopener',
          })}
        >
          <VisibilityIcon />
        </IconButton>
        <IconButton
          variant="text"
          color="error"
          disabled={disabled || !currentFile}
          onClick={deleteFileFromApi}
        >
          <DeleteIcon />
        </IconButton>
      </MKBox>
      <MKBox display="flex" flexDirection="column" pl={1}>
        {max_file_size > 0 && (
          <MKTypography variant="caption">
            {`Max File Size: ${formatFileSize(maxFileSizeInBytes)}`}
          </MKTypography>
        )}
        {accept_file_types?.length > 0 && (
          <MKTypography variant="caption">
            {`Accept File Types: ${(accept_file_types || []).join(', ')}`}
          </MKTypography>
        )}
        {accept_resolutions?.length > 0 && (
          <MKTypography variant="caption">
            {`Accept Resolutions (w x h): ${(accept_resolutions || []).join(', ')}`}
          </MKTypography>
        )}
      </MKBox>
      {!disabled && (
        <MKBox mt={1}>
          <FilePicker
            onAdd={onUploadFile}
            onError={onFilePickError}
            buttonText={button_text}
            currentFile={currentFile}
            maxFileSize={maxFileSizeInBytes}
            imageCroppable={inputType === INPUT_TYPE_IMAGE && image_croppable}
            acceptFileTypes={accept_file_types}
            acceptResolutions={accept_resolutions}
            buttonProps={{
              variant: button_variant,
              color: button_color,
              size: button_size,
              fontSize: button_font_size,
            }}
            {...props}
          />
        </MKBox>
      )}
      <Modal
        showHeader={false}
        isOpen={previewOpen}
        onClose={() => setPreviewOpen(false)}
        p={1}
      >
        <MKBox display="flex" justifyContent="center" alignItems="center" sx={{ position: 'relative', width: '70vw', height: '70vh' }}>
          {inputType === INPUT_TYPE_IMAGE && (
            <Image
              src={currentFile?.file_url}
              width="100%"
              height="100%"
            />
          )}
          {inputType === INPUT_TYPE_VIDEO && (
            <VideoPlayer
              url={currentFile?.file_url}
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          )}
        </MKBox>
      </Modal>
    </MKBox>
  );
};

FileUploaderSection.propTypes = {
  file_id: PropTypes.string,
  input_label: PropTypes.string,
  button_text: PropTypes.string,
  button_color: PropTypes.string,
  button_variant: PropTypes.string,
  button_size: PropTypes.string,
  button_font_size: PropTypes.string,
  onChange: PropTypes.func,
  max_file_size: PropTypes.number, // in MB
  image_croppable: PropTypes.bool,
  accept_file_types: PropTypes.arrayOf(PropTypes.string),
  accept_resolutions: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  setLoading: PropTypes.func,
};

FileUploaderSection.defaultProps = {
  onChange: () => {},
  setLoading: () => {},
};

export default withLoading(FileUploaderSection);
