import api from 'api';

export const getServiceProviders = (params) => {
  return api.get('/service_providers', params, 'bearer');
};

export const getServiceProvider = (id, params) => {
  return api.get(`/service_providers/${id}`, params, 'bearer');
};

export const updateServiceProvider = (id, body) => {
  return api.put(`/service_providers/${id}`, body, 'bearer');
};

export const createServiceProvider = (body) => {
  return api.post('/service_providers', body, 'bearer');
};

export const deleteServiceProvider = (id) => {
  return api.delete(`/service_providers/${id}`, 'bearer');
};

export const getUserServiceProviders = (params) => {
  return api.get('/user_service_providers', params, 'bearer');
};

export const createUserServiceProvider = (body) => {
  return api.post('/user_service_providers', body, 'bearer');
};

export const deleteUserServiceProvider = (id) => {
  return api.delete(`/user_service_providers/${id}`, 'bearer');
};
