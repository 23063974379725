import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import SimpleHeader from 'components/SimpleHeader';

const SimpleHeaderSection = ({ title, logo_url, bg_color, bg_image, absolute, sticky, editable, onPressEdit, ...props }) => {
  return (
    <MKBox sx={sticky ? { position: 'sticky', top: 0, zIndex: 10 } : null}>
      <SimpleHeader
        title={title}
        bgImage={bg_image}
        bgColor={bg_color}
        logo={<MKBox component="img" src={logo_url} height="2.4rem" />}
        absolute={absolute}
        {...props}
      />
    </MKBox>
  );
};

SimpleHeaderSection.propTypes = {
  title: PropTypes.string,
  logo_url: PropTypes.string,
  bg_color: PropTypes.string,
  bg_image: PropTypes.string,
  absolute: PropTypes.bool,
  sticky: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

SimpleHeaderSection.defaultProps = {
  title: '',
  logo_url: null,
  bg_color: null,
  bg_image: null,
  absolute: false,
  sticky: false,
  editable: false,
  onPressEdit: () => {},
};

export default SimpleHeaderSection;
