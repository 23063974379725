import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { getUser, updateUser, createUser, getUserRoles } from 'api/users';
import { pushAlert } from 'api/alpha/alerts';
import { getWard } from 'api/alpha/wards';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { getPatients } from 'api/alpha/patients';
import { useAuth } from 'contexts/auth';
import { Grid, Stack, Button } from '@mui/material';
import AVBreadcrumb from 'sections/AVBreadcrumb';
import { getLocaleMap } from 'utils/locales';
import NotificationsTable from './table';

const AVNotificationsMgt = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['sending_notification_to_mobile_app', 'device_identifier', 'phone_number', 'alert_type', 'send', 'guest_name', 'fall', 'message_notification_record'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const [user, setUser] = useState(null);
  const [wardData, setWardData] = useState({});
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuth();
  const location = useLocation();
  const info = location.state?.info;
  const navigate = useNavigate();
  const wardId = searchParams.get('ward_id');
  const username = searchParams.get('username');

  const getLocationData = useCallback(() => {
    if (!wardId) {
      return Promise.resolve();
    }
    return getWard(wardId, { $expand: 'center_id/service_provider_id' })
      .then(({ data }) => {
        setWardData(data);
      })
      .catch((error) => {
        handleErrorResponse(error, setAuth);
      });
  }, [setAuth, wardId]);

  useEffect(() => {
    getLocationData();
  }, [getLocationData]);

  const handleNotificationPush = () => {
    // eslint-disable-next-line no-console
    console.log('Sending notification to info', info);
    const body = {
      title: 'Ward Alert',
      content: `An emergency alert has been raised for ${info.accident.tag} at ${info.service_provider_name}/${info.center_name}/${info.ward_name}`,
    };
    console.log('Sending notification to mobile app', body);
    pushAlert(username, body)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log('Notification sent successfully', response);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending notification', error);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  if (!info) {
    return null;
  }

  return (
    <MKBox>
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py="1rem">
        <AVBreadcrumb startingNode={localeMapping.sending_notification_to_mobile_app} showBackButton />
      </MKBox>
      <Grid container item xs={6} spacing={0}>
        <Grid item xs={12}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${info.service_provider_name}/${info.center_name}/${info.ward_name}`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {localeMapping.device_identifier}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${info.accident?.camera?.camera_identifier}`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.guest_name}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${info.accident.patient?.name}`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping.phone_number}:`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${info.accident.patient?.phone}`}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {localeMapping.alert_type}
          </MKTypography>
        </Grid>
        <Grid item xs={6}>
          <MKTypography variant="body1" fontSize="16px" color="black" fontWeight="bold">
            {`${localeMapping[info.accident.tag]}`}
          </MKTypography>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} sx={{ py: 1 }}>
        <Button variant="contained" color="primary" size="small" onClick={() => handleNotificationPush()}>
          <MKTypography color="white" fontSize="14px" fontWeight="bold">
            {localeMapping.send}
          </MKTypography>
        </Button>
      </Stack>
      <AVBreadcrumb startingNode={localeMapping.message_notification_record} />
      <NotificationsTable />
    </MKBox>
  );
};

export default AVNotificationsMgt;
