import React, { useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { generateContrastingColor } from './helperFunctions';

const VRWardCard = ({ ward, handleChecked }) => {
  const navigate = useNavigate();
  const bgColor = generateContrastingColor(ward?.center_id);

  const handleReportIssues = () => {
    navigate(`/users/ward-details?ward_id=${ward.ward_id}`);
  };

  return (
    <MKBox sx={{ border: '1.5px solid', borderColor: bgColor, backgroundColor: 'gray', borderRadius: '8px' }}>
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" px={2} py={1} sx={{ borderRadius: '8px 8px 0px 0px', backgroundColor: bgColor }}>
        <MKBox display="flex" flexDirection="column" alignItems="left" color="#fff">
          <MKTypography color="inherit" fontWeight="bold" fontSize="28px">{ward.ward_name}</MKTypography>
          <MKTypography color="inherit" fontWeight="bold" fontSize="28px">{ward.center_name}</MKTypography>
        </MKBox>
        <MKBox>
          <Button
            variant="contained"
            size="medium"
            color="#2AAB47"
            fontSize="18px"
            onClick={() => handleChecked(ward)}
          >
            <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="inherit">Checked</MKTypography>
          </Button>
        </MKBox>
      </MKBox>
      <MKBox px={2} py={1} display="flex" flexDirection="column" alignItems="left" sx={{ borderRadius: '0px 0px 8px 8px', backgroundColor: '#FFEEF4' }}>
        <MKBox display="flex" flexDirection="row" justifyContent="space-between">
          <MKBox fontSize="20px">
            <MKTypography variant="body1" fontSize="inherit">{`Amount of guest: ${ward?.guests || 0}`}</MKTypography>
            <MKTypography variant="body1" fontSize="inherit">{`Alerts: ${ward?.alerts || 0}`}</MKTypography>
            <MKTypography variant="body1" fontSize="inherit">{`Emergency Alarm: ${ward?.emergency_alarms || 0}`}</MKTypography>
          </MKBox>
          <MKBox display="flex" alignItems="flex-end">
            <Button
              variant="contained"
              size="medium"
              color="#FF9D03"
              fontSize="18px"
              onClick={() => handleReportIssues()}
            >
              Report Issues
            </Button>
          </MKBox>

        </MKBox>
      </MKBox>
    </MKBox>
  );
};

VRWardCard.propTypes = {
  ward: PropTypes.object.isRequired,
  handleChecked: PropTypes.func,
};

export default VRWardCard;
